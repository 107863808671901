import { Dispatch, SetStateAction, useState } from 'react'
import MainPageWrapper from '../../global/components/MainPageWrapper'
import VansTable from '../table/VansTable'
import VansPageHeader from './page-header/VansPageHeader'
export interface IRefreshCounterProps {
  refreshCounter: number
  setRefreshCounter: Dispatch<SetStateAction<number>>
}
function VansMain() {
  const [locationFilter, setLocationFilter] = useState('')
  const [refreshCounter, setRefreshCounter] = useState<number>(0)

  return (
    <MainPageWrapper
      titleFields={
        <VansPageHeader
          locationFilters={{ setLocationFilter, locationFilter }}
          refresh={{ refreshCounter, setRefreshCounter }}
        />
      }>
      <VansTable
        locationFilters={{ locationFilter, setLocationFilter }}
        refresh={{ refreshCounter, setRefreshCounter }}
      />
    </MainPageWrapper>
  )
}

export default VansMain
