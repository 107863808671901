import { Chip } from '@mui/material'
import { useState } from 'react'
import styles from './styles.module.css'
import { ILocation } from '../../../../../../../app/entities/Location'
import { isValidEmail } from '../../../../../global/utils/formValidation'

interface IZipCodesManagerProps {
  location: Partial<ILocation>
  setLocation: Function
  isReadOnly: boolean
}

export default function TechnicianEmailManager({
  location,
  setLocation,
  isReadOnly,
}: IZipCodesManagerProps) {
  const [emailInputBackgroundColor, setEmailInputBackgroundColor] =
    useState('#f5f5f5')
  const [emailInputValue, setEmailInputValue] = useState('')

  const emails = location.emailsThatCanQueryWorkOrders
    ? location.emailsThatCanQueryWorkOrders.split(',')
    : []

  const inputChip = (
    <Chip
      key='input-chip'
      style={{
        border: '1px solid #2a7187',
        cursor: isReadOnly ? undefined : 'text',
        backgroundColor: emailInputBackgroundColor,
        boxShadow: '0 0 .25rem 0 rgba(0,0,0,.25) inset',
      }}
      label={
        <input
          placeholder='Add Email'
          style={{
            all: 'unset',
            width: '10rem',
            userSelect: 'none',
          }}
          value={emailInputValue}
          disabled={isReadOnly}
          autoComplete='off'
          // allows for pasting multiple zips at once assuming
          // it is a list of zips separated by newlines
          onChange={(e) => {
            setEmailInputBackgroundColor('#f5f5f5')
            setEmailInputValue(e.target.value)
          }}
          onKeyDown={handleKeyDown}
        />
      }
    />
  )

  const emailChips = emails.map((email) => {
    return (
      <Chip
        key={email}
        label={email}
        disabled={isReadOnly}
        style={{
          backgroundColor: '#2a7187',
          color: 'white',
          fontWeight: 'bold',
        }}
        onDelete={() => {
          const remainingEmails = emails.filter((e) => e !== email)
          setLocation({
            ...location,
            emailsThatCanQueryWorkOrders: remainingEmails.join(','),
          })
        }}
      />
    )
  })

  function handleKeyDown(e: any) {
    if (e.key === 'Enter') {
      const newEmail = e.currentTarget.value

      const isValid = isValidEmail(newEmail)
      if (!isValid) {
        setEmailInputBackgroundColor('#fcebeb')
      } else {
        setLocation({
          ...location,
          emailsThatCanQueryWorkOrders: location.emailsThatCanQueryWorkOrders
            ? `${newEmail},${location.emailsThatCanQueryWorkOrders}`
            : newEmail,
        })
        setEmailInputValue('')
      }
    }
  }

  return (
    <div className={styles.technicianEmailsManagerMain}>
      <div className={styles.technicianEmailsManagerContainer}>
        <div className={styles.technicianEmailsManagerHeader}>
          <div className={`${styles.technicianEmailsManagerTitle} font--bold`}>
            Technician Emails
          </div>
          <div className={styles.technicianEmailsManagerSubtitle}>
            When technicians log into Appenate, the email they use to log in
            must be in this list in order to see the jobs for this location.
          </div>
        </div>
        <div className={styles.technicianEmailsManagerChipsContainer}>
          {inputChip}
          {emailChips}
        </div>
      </div>
    </div>
  )
}
