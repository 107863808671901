import moment from 'moment'
import { useContext } from 'react'
import { Column } from 'react-table'
import Table from '../../../../../../global/components/table/Table'
import TableTitle from '../../../../../../global/components/table/helpers/table-title/TableTitle'
import TableTopper from '../../../../../../global/components/table/helpers/table-topper/TableTopper'
import OrderActions from './OrderActions'
import removeDoubledCommas from '../../../../../../global/utils/remove-doubled-commas'
import { OrderContext } from '../../../../../../global/context/order-context/OrderContext'

interface IOverviewSummaryTableProps {
  openOrderModal: () => void
  isReadOnly: boolean
}

function OverviewSummaryTable({
  openOrderModal,
  isReadOnly,
}: IOverviewSummaryTableProps) {
  const { isOrderInEditableStatus, orderData } = useContext(OrderContext)
  const isOrderEditable = isOrderInEditableStatus && !isReadOnly

  function formatPhoneNumber(phoneNumber: string) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '')
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }
  const columns: Column[] = [
    {
      Header: 'Order ID',
      accessor: 'workOrderId',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Service Appt',
      Cell: ({ row: { original } }) => {
        const { routeInstructions } = original as any

        if (routeInstructions.length) {
          const ri = routeInstructions[0]
          const date = moment.utc(ri.startTime).format('MM/DD/YYYY hh:mm A')
          return <div>{date}</div>
        }

        return <div>Appointment information not available.</div>
      },
    },
    {
      Header: 'Location',
      accessor: 'location.name',
    },
    {
      Header: 'Customer Information',
      Cell: ({ row: { original } }) => {
        const { customer, installationPoint } = original as any
        if (customer) {
          return (
            <div>
              <div>
                {customer.firstName ?? ''} {customer.lastName ?? ''}
              </div>
              <div>
                {installationPoint
                  ? removeDoubledCommas(installationPoint.fullAddress)
                  : ''}
              </div>
              <div>{customer.email ?? ''}</div>
              <div>{formatPhoneNumber(customer.trimmedPhoneNumber)}</div>
            </div>
          )
        } else {
          return <div>Customer information not available.</div>
        }
      },
    },
    {
      Header: 'Order Source',
      accessor: 'orderSource',
    },
    {
      Header: 'Tire Source',
      accessor: 'tireSource',
    },
    {
      Header: 'Tire Location',
      accessor: 'tireLocation',
    },
    {
      Header: 'Salesperson ID #',
      accessor: 'salesRepresentativeNumber',
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <TableTopper>
        <div
          style={{
            display: 'flex',
            gap: '1rem',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <TableTitle>Overview / Summary Info</TableTitle>
          <OrderActions
            openOrderModal={openOrderModal}
            isReadOnly={isReadOnly}
          />
        </div>
      </TableTopper>
      <Table
        columns={columns}
        data={[orderData] as any}
        isLoading={false}
        onRowClick={isOrderEditable ? openOrderModal : undefined}
        isDisplayMode={true}
      />
    </div>
  )
}

export default OverviewSummaryTable
