import BasicFilter from '../../../global/components/table/helpers/BasicFilter'
import { ACTIONS, PERMISSIONS } from '../../../global/constants/permissions'
import NoFilter from '../../../global/components/table/helpers/NoFilter'
import { IPermission } from '../../../../../app/entities/Permission'
import { checkPermission } from '../../../global/utils/user/has-permission'

const {
  CATEGORY: LOCATION_SETTINGS_CATEGORY,
  ENTRIES: LOCATION_SETTINGS_ENTRIES,
} = PERMISSIONS.LOCATION_SETTINGS
const {
  BLOCKED_OFF_TIMES,
  ADD_SERVICED_ZIP_CODES,
  REMOVE_SERVICED_ZIP_CODES,
  VIEW_SERVICED_ZIP_CODES,
  SPOKE_LOCATIONS,
  TIRE_LOCATIONS,
  WEBSITE_SCHEDULE_BUFFER,
  WORKDAY_INFO,
  NEW_ORDER_ALERTS,
} = LOCATION_SETTINGS_ENTRIES

const { CATEGORY: SCHEDULE_VIEW_CATEGORY, ENTRIES: SCHEDULE_VIEW_ENTRIES } =
  PERMISSIONS.SCHEDULE_VIEW
const { CAN_SCHEDULE } = SCHEDULE_VIEW_ENTRIES

const { CATEGORY: WORK_ORDER_VIEW_CATEGORY, ENTRIES: WORK_ORDER_VIEW_ENTRIES } =
  PERMISSIONS.WORK_ORDER_VIEW
const { INDIVIDUAL_WORK_ORDER } = WORK_ORDER_VIEW_ENTRIES

const { ALLOW, EDIT, VIEW } = ACTIONS

const NA = 'N/A'
const DENY = 'deny'

function generateValue(
  category: string,
  name: string,
  permissions: IPermission[] = [],
) {
  if (!permissions) return NA

  const hasAllow = checkPermission(permissions, category, name, [ALLOW])
  if (hasAllow) return ALLOW

  const hasEdit = checkPermission(permissions, category, name, [EDIT])
  if (hasEdit) return EDIT

  const hasView = checkPermission(permissions, category, name, [VIEW])
  if (hasView) return VIEW

  return DENY
}

function generateColumns(
  onColumnFilterUpdate: Function,
  retrieveTableData: Function,
) {
  return [
    {
      Header: 'Name',
      accessor: 'name',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ name: val }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Workday Info',
      accessor: (data: any) =>
        generateValue(
          LOCATION_SETTINGS_CATEGORY,
          WORKDAY_INFO.NAME,
          data.permissions,
        ),
      Filter: NoFilter(),
    },
    {
      Header: 'Website Schedule Buffer',
      accessor: (data: any) =>
        generateValue(
          LOCATION_SETTINGS_CATEGORY,
          WEBSITE_SCHEDULE_BUFFER.NAME,
          data.permissions,
        ),
      Filter: NoFilter(),
    },
    {
      Header: 'Add Serviced Zip Codes',
      accessor: (data: any) =>
        generateValue(
          LOCATION_SETTINGS_CATEGORY,
          ADD_SERVICED_ZIP_CODES.NAME,
          data.permissions,
        ),
      Filter: NoFilter(),
    },
    {
      Header: 'Remove Serviced Zip Codes',
      accessor: (data: any) =>
        generateValue(
          LOCATION_SETTINGS_CATEGORY,
          REMOVE_SERVICED_ZIP_CODES.NAME,
          data.permissions,
        ),
      Filter: NoFilter(),
    },
    {
      Header: 'View Serviced Zip Codes',
      accessor: (data: any) =>
        generateValue(
          LOCATION_SETTINGS_CATEGORY,
          VIEW_SERVICED_ZIP_CODES.NAME,
          data.permissions,
        ),
      Filter: NoFilter(),
    },
    {
      Header: 'Spoke Locations',
      accessor: (data: any) =>
        generateValue(
          LOCATION_SETTINGS_CATEGORY,
          SPOKE_LOCATIONS.NAME,
          data.permissions,
        ),
      Filter: NoFilter(),
    },
    {
      Header: 'Alerts',
      accessor: (data: any) =>
        generateValue(
          LOCATION_SETTINGS_CATEGORY,
          NEW_ORDER_ALERTS.NAME,
          data.permissions,
        ),
      Filter: NoFilter(),
    },
    {
      Header: 'Tire Locations',
      accessor: (data: any) =>
        generateValue(
          LOCATION_SETTINGS_CATEGORY,
          TIRE_LOCATIONS.NAME,
          data.permissions,
        ),
      Filter: NoFilter(),
    },
    {
      Header: 'Blocked Off Times',
      accessor: (data: any) =>
        generateValue(
          LOCATION_SETTINGS_CATEGORY,
          BLOCKED_OFF_TIMES.NAME,
          data.permissions,
        ),
      Filter: NoFilter(),
    },
    {
      Header: 'Can Schedule',
      accessor: (data: any) =>
        generateValue(
          SCHEDULE_VIEW_CATEGORY,
          CAN_SCHEDULE.NAME,
          data.permissions,
        ),
      Filter: NoFilter(),
    },
    {
      Header: 'Individual Work Order',
      accessor: (data: any) =>
        generateValue(
          WORK_ORDER_VIEW_CATEGORY,
          INDIVIDUAL_WORK_ORDER.NAME,
          data.permissions,
        ),
      Filter: NoFilter(),
    },
  ]
}

export default generateColumns
