import styles from "./styles.module.css"
import arrowSvg from "../../../../../assets/images/arrow.svg"
import { MouseEventHandler } from "react"

interface TableTopperProps {
	children: JSX.Element | JSX.Element[]
	isOpen?: boolean
	onToggleClick?: MouseEventHandler<HTMLDivElement> | undefined
	mainStyle?: React.CSSProperties
	containerStyle?: React.CSSProperties
}

function TableTopper({
	children,
	isOpen,
	onToggleClick,
	mainStyle,
	containerStyle,
}: TableTopperProps) {
	const shouldShowToggle = !!onToggleClick

	const showOpen = typeof isOpen === "undefined" ? true : isOpen

	return (
		<div
			className={`${styles.tableTopperMain} ${showOpen ? styles.open : ""}`}
			style={mainStyle || {}}
			onClick={onToggleClick}
		>
			<div className={styles.tableTopperContainer} style={containerStyle || {}}>
				{children}
			</div>
			<div
				className={`${styles.toggleHolder} ${isOpen ? styles.open : ""}`}
				hidden={!shouldShowToggle}
			>
				<img src={arrowSvg} alt="" />
			</div>
		</div>
	)
}

export default TableTopper