import { useContext } from 'react'
import { PERMISSIONS } from '../../../../global/constants/permissions'
import userHasPermission from '../../../../global/utils/user/has-permission'
import { UserContext } from '../../../../base/components/base-container/BaseContainer'
import { IPermission } from '../../../../../../app/entities/Permission'

const { CATEGORY, ENTRIES } = PERMISSIONS.LOCATION_SETTINGS

function determineIfUserCanEdit() {
  const { user } = useContext(UserContext)
  const permissionEntries = Object.values(ENTRIES)
  const canEditSomeField = permissionEntries.some(({ NAME }) =>
    userHasPermission(user).edit(CATEGORY, NAME),
  )

  return canEditSomeField
}

export default determineIfUserCanEdit
