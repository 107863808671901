import { useState } from 'react'
import MainPageWrapper from '../../../global/components/MainPageWrapper'
import AllReports from '../reports/AllReports'
import moment from 'moment'
import LocationDropDown from '../../../global/components/location-drop-down/LocationDropDown'
import { TextField } from '@mui/material'

function ReportsMain() {

  const [fromDate, setFromDate] = useState(moment().subtract(8, 'days').format('YYYY-MM-DD'))
  const [toDate, setToDate] = useState(moment().subtract(1, 'days').format('YYYY-MM-DD'))
  const [location, setLocation] = useState('')

  const titleFields = (
    <>
      <LocationDropDown
        onLocationChange={(location: string) => setLocation(location)}
      />
      <TextField
        onChange={(e) => setFromDate(e.target.value)}
        value={fromDate || ''}
        type='Date'
        label='From'
      />
      <TextField
        onChange={(e) => setToDate(e.target.value)}
        value={toDate || ''}
        type='Date'
        label='To'
      />
    </>
  )

  return (
    <MainPageWrapper
      titleFields={titleFields}
      titleStyle={{justifyContent: 'flex-start'}}
    >
      <AllReports 
        fromDate={fromDate}
        toDate={toDate}
        location={location}
      />
    </MainPageWrapper>
  )
}

export default ReportsMain
