import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Modal from '@mui/material/Modal'
import { MouseEventHandler, useContext, useState } from 'react'
import { DaySetting } from '../../../../../../../app/entities/DaySetting'
import { ILocation } from '../../../../../../../app/entities/Location'
import { ITabulationArea } from '../../../../../../../app/entities/TabulationArea'
import { IWeekCalendar } from '../../../../../../../app/entities/WeekCalendar'
import { UserContext } from '../../../../../base/components/base-container/BaseContainer'
import PrimaryButton from '../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../global/components/buttons/secondary-button/SecondaryButton'
import { ToastNotificationContext } from '../../../../../global/context/toast-context/ToastNotificationContext'
import { fetchData } from '../../../../../global/utils/fetch'
import {
  createLocation,
  updateLocation,
  updateLocationDaySettings,
  updateLocationTabulationAreas,
  updateWeekCalendar,
} from '../../api'
import LocationEdit from './LocationEdit'
import styles from './styles.module.css'
import calculateTimeSlotsForLocation from '../../../../../global/utils/calculateTimeSlotsForLocation'
import determineIfRightColumnIsVisible from '../../helper-functions/determine-if-right-column-is-visible'
import determineIfUserCanEdit from '../../helper-functions/determine-if-user-can-edit'
import getUserPermissions from '../../../../../global/utils/user/get'
import { PERMISSIONS } from '../../../../../global/constants/permissions'
import hasPermission from '../../../../../global/utils/user/has-permission'

interface ILocationModalProps {
  isOpen: boolean
  handleClosed: MouseEventHandler
  isNew: boolean
  locationObjectId?: string
}

const DEFAULT_LOCATION_DATA = {
  name: '',
  addressStreet: '',
  addressCity: '',
  stateAbbr: '',
  zipCode: '',
  workDayFrom: '08:00',
  workDayTo: '18:00',
  lunchFrom: '12:00',
  lunchTo: '13:00',
  productionMode: true,
  active: true,
  emailsThatCanQueryWorkOrders: '',
  plannedServiceTime: 45,
  plannedTravelTime: 15,
}

export function LocationModal({
  isOpen,
  handleClosed,
  isNew,
  locationObjectId,
}: ILocationModalProps) {
  const { user, refetchUser } = useContext(UserContext)
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const [isLoading, setIsLoading] = useState(false)
  const [location, setLocation] = useState<Partial<ILocation>>({
    ...DEFAULT_LOCATION_DATA,
  })

  const [weekCalendar, setWeekCalendar] = useState<Partial<IWeekCalendar>>({
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: false,
  })

  const [tabulationAreas, setTabulationAreas] = useState<ITabulationArea[]>([])
  const [daySettings, setDaySettings] = useState<Partial<DaySetting>[]>([])

  const handleCloseModal = (e: any) => {
    setLocation({ ...DEFAULT_LOCATION_DATA })

    setWeekCalendar({
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: false,
    })

    setTabulationAreas([])
    setDaySettings([])
    handleClosed(e)
  }

  function toggleActivateDeactivateLocation() {
    setLocation((prevState) => ({
      ...prevState,
      active: !prevState.active,
    }))
  }

  const handleSaveLocation = async (e: any) => {
    try {
      setIsLoading(true)
      if (isNew) {
        await fetchData<ILocation>(createLocation(location, weekCalendar))
        setToastStatus({
          ...toastStatus,
          isOpen: true,
          message: `Successfully created location!`,
          severity: 'success',
        })
      } else {
        // must save location by itself or else the call will overwrite the tabulation areas and day settings
        await fetchData<ILocation>(updateLocation(location))

        const updates = [
          updateWeekCalendar(weekCalendar),
          updateLocationTabulationAreas(
            locationObjectId || '',
            tabulationAreas,
          ),
          updateLocationDaySettings(locationObjectId || '', daySettings),
        ] as any

        await Promise.all(updates.map(fetchData))
      }

      // refetching the user will update the locations dropdown
      await refetchUser()
      handleCloseModal(e)
    } catch (error) {
      // toggleToastAlertError('Error creating location, see console for details.')
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: `There has been an error ${
          isNew ? 'creating' : 'updating'
        } location.`,
        severity: 'error',
      })

      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const { slotStarts } = calculateTimeSlotsForLocation(location)

  const saveLocationBtnDisabledLogic =
    !location.name ||
    !location.addressCity ||
    !location.addressStreet ||
    !location.stateAbbr ||
    !location.zipCode ||
    !location.workDayFrom ||
    !location.workDayTo ||
    !location.lunchFrom ||
    !location.lunchTo ||
    !slotStarts.length // if there are no slots, the time values for the work day are invalid

  const isDeactivateButtonDisabled = !hasPermission(user).edit(
    PERMISSIONS.LOCATION_SETTINGS.CATEGORY,
    PERMISSIONS.LOCATION_SETTINGS.ENTRIES.DEACTIVATE_BUTTON.NAME,
  )

  const isSaveInvisible = !determineIfUserCanEdit()
  const negativeButtonName = isSaveInvisible ? 'Close' : 'Cancel'

  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          className={`${styles.locationModal}
          ${
            // If none of the divs on the right side of the modal are visible,
            // we want to have the width fit the content
            user && determineIfRightColumnIsVisible(user)
              ? styles.maxWidth
              : styles.widthFitContent
          }
        `}
        >
          <>
            <div className={styles.locationModalHeader}>
              <div className={`${styles.locationModalPageTitle} font--bold`}>
                {isNew ? 'Create Location' : 'Edit Location'}
              </div>
              <div className={styles.locationModalHeaderButtons}>
                <SecondaryButton
                  buttonName={negativeButtonName}
                  onClick={handleCloseModal}
                />
                {!isSaveInvisible && (
                  <SecondaryButton
                    buttonName={location.active ? 'Deactivate' : 'Activate'}
                    onClick={toggleActivateDeactivateLocation}
                    disabled={isDeactivateButtonDisabled}
                  />
                )}
                {!isSaveInvisible && (
                  <PrimaryButton
                    buttonName={isNew ? 'Save Location' : 'Update Location'}
                    disabled={isLoading || saveLocationBtnDisabledLogic}
                    onClick={handleSaveLocation}
                  />
                )}
              </div>
            </div>
            {isLoading ? (
              <div className={styles.progressContainer}>
                <CircularProgress />
              </div>
            ) : (
              <LocationEdit
                isNew={isNew}
                locationObjectId={locationObjectId}
                location={location}
                setLocation={setLocation}
                tabulationAreas={tabulationAreas}
                setTabulationAreas={setTabulationAreas}
                weekCalendar={weekCalendar}
                setWeekCalendar={setWeekCalendar}
                daySettings={daySettings}
                setDaySettings={setDaySettings}
              />
            )}
          </>
        </Box>
      </Modal>
    </div>
  )
}
