import React, { useState, useEffect, ChangeEvent, useContext } from 'react'
import { UserContext } from '../../../base/components/base-container/BaseContainer'
import TextField from '@mui/material/TextField/TextField'
import styles from './styles.module.css'
import MenuItem from '@mui/material/MenuItem/MenuItem'

interface SpokeLocationDropDownProps {
  onSpokeLocationChange?: (spokeLocationId: string) => void
  disabled?: boolean
  locationId?: string | null 
  defaultValue?: string // The currently selected spoke location, for editing users
}

export default function SpokeLocationDropDown({ onSpokeLocationChange, disabled, locationId, defaultValue }: SpokeLocationDropDownProps) {
  const [value, setValue] = useState('')
  const { user } = useContext(UserContext)

  useEffect(() => {
    if (defaultValue) {
      // Use the selectedSpokeLocation as the default value if it's provided
      setValue(defaultValue)
    }
  }, [user?.locations, locationId, defaultValue])

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as string
    setValue(newValue)
    if (onSpokeLocationChange) {
      onSpokeLocationChange(newValue)
    }
  }
  const spokeLocations = user?.locations?.find((location) => location.objectId === locationId)?.spokeLocations?.split(',') || []

  return (
    <TextField
      className={styles.dropDownSelect}
      label='Spoke Location'
      select
      name='spokeLocationId'
      disabled={disabled}
      onChange={handleChange}
      value={value}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 400,
              width: 250,
            },
          },
        },
      }}>
      {locationId && spokeLocations.length > 0 ? (
        spokeLocations.map((spokeLocation) => (
          <MenuItem
            key={spokeLocation}
            value={spokeLocation}>
            {spokeLocation}
          </MenuItem>
        ))
      ) : (
        <MenuItem
          disabled
          value=''>
          No Available Spoke Locations
        </MenuItem>
      )}
    </TextField>
  )
}
