const STATUSES = {
  cancelled: 'cancelled',
  cancelled_onsite: 'cancelled_onsite',
  completed: 'completed',
  en_route: 'en_route',
  on_site: 'on_site',
  scheduled: 'scheduled',
  started: 'started',
  unconfirmed: 'unconfirmed',
  quote: 'quote',
}

const FINISHABLE_STATUSES = [STATUSES.unconfirmed, STATUSES.quote]

const NON_EDITABLE_STATUSES = [
  STATUSES.cancelled,
  STATUSES.cancelled_onsite,
  STATUSES.completed,
]

const SCHEDULED_STATUSES = [STATUSES.scheduled]

export {
  FINISHABLE_STATUSES,
  NON_EDITABLE_STATUSES,
  STATUSES,
  SCHEDULED_STATUSES,
}
