import MainPageWrapper from '../../../global/components/MainPageWrapper'
import WorkOrdersTable from '../table/WorkOrdersTable'
import { OrderContextProvider } from '../../../global/context/order-context/OrderContext'
import LocationDropDown from '../../../global/components/location-drop-down/LocationDropDown'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../base/components/base-container/BaseContainer'
export interface ILocationFilters {
  locationFilter: string
  setLocationFilter: Function
}
function WorkOrdersMain() {
  const [locationFilter, setLocationFilter] = useState('')

  const { user } = useContext(UserContext)

  useEffect(() => {
    if (user?.locations?.length === 1) {
      setLocationFilter(user.locations[0].objectId)
    }
  }, [user?.locations?.length])

  return (
    <MainPageWrapper
      titleStyle={{ justifyContent: 'flex-start' }}
      titleFields={
        <LocationDropDown
          onLocationChange={(val: string) => {
            setLocationFilter(val)
          }}
          defaultValue={locationFilter}
        />
      }
    >
      <OrderContextProvider>
        <WorkOrdersTable locationFilter={locationFilter} />
      </OrderContextProvider>
    </MainPageWrapper>
  )
}

export default WorkOrdersMain
