import { ILocation } from '../../../../../../../app/entities/Location'
import { IAddressValidation } from '../../../../../../../app/entities/AddressValidationRecords'
import { PostalAddress } from '../../../../../../../app/types/google-maps'

export function getAddressValidation(postalAddress: PostalAddress) {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/scheduling/address-validation`,
    body: postalAddress,
  }
}

export function getIsZipServiced(
  zipCode: string,
  location: ILocation | undefined,
) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/zips/${zipCode}/availability?locationObjectId=${location?.objectId}`,
  }
}
