export function getBrands() {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/vehicles/brands`,
  }
}
export function getBrandYears(brand: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/vehicles/years?brand=${brand}`,
  }
}
export function getModels(brand: string, year: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/vehicles/models?brand=${brand}&year=${year}`,
  }
}
export const getVariants = (brand: string, year: string, model: string) => {
  const encodedModel = encodeURIComponent(model)
  return {
    method: 'GET',
    url: `${
      process.env.REACT_APP_ORIGIN
    }/vehicles/variants?brand=${encodeURIComponent(
      brand,
    )}&year=${encodeURIComponent(year)}&model=${encodedModel}`,
  }
}
export function updateCustomer(body: any) {
  return {
    method: 'PUT',
    body: body,
    url: `${process.env.REACT_APP_ORIGIN}/customers`,
  }
}
export function createCustomerVehicle(body: any, customerId: string) {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/customer_vehicles/${customerId}`,
    body: body,
  }
}
export function setCustomerVehicle(orderId: string, vehicleObjectId: string) {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/vehicles/bulk`,
    body: [vehicleObjectId],
  }
}
export function createInstallationPoint(body: any) {
  return {
    method: 'POST',
    body: body,
    url: `${process.env.REACT_APP_ORIGIN}/installation_point`,
  }
}
export function setInstallationPoint(installationPoint: any, orderId: string) {
  return {
    method: 'POST',
    body: installationPoint,
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/installation-point`,
  }
}
export function getStateByZip(zipCode: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/zips/${zipCode}/states`,
  }
}
export function updateUnvalidatedAddressOrderNote(
  uvalidatedAddressNote: string,
  existingNote: string,
  orderId: string,
) {
  const combinedNote = `Unvalidated Address: ${uvalidatedAddressNote}`

  return {
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/note`,
    method: 'PATCH',
    body: combinedNote,
  }
}
