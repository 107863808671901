// Update the app/constants/permissions.ts file when this file is updated
const ACTIONS = {
  VIEW: 'view',
  EDIT: 'edit',
  ALLOW: 'allow',
}

enum CATEGORIES {
  ADMIN = 'ADMIN',
  DASHBOARDS = 'DASHBOARDS',
  HOLIDAYS = 'HOLIDAYS',
  LOCATION_SETTINGS = 'LOCATION_SETTINGS',
  NAV_BAR = 'NAV_BAR',
  REPORTS = 'REPORTS',
  SCHEDULE_VIEW = 'SCHEDULE_VIEW',
  VANS = 'VANS',
  WORK_ORDER_VIEW = 'WORK_ORDER_VIEW',
}

type IPermissions = {
  [key in CATEGORIES]: {
    CATEGORY: string
    ENTRIES: {
      [key: string]: {
        NAME: string
        ALLOWED_ACTIONS: string[]
      }
    }
  }
}

const PERMISSIONS: IPermissions = {
  ADMIN: {
    CATEGORY: CATEGORIES.ADMIN,
    ENTRIES: {
      VIEW_ALL_LOCATIONS: {
        NAME: 'View All Locations',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
    },
  },
  DASHBOARDS: {
    CATEGORY: CATEGORIES.DASHBOARDS,
    ENTRIES: {
      CAN_DOWNLOAD_CSV: {
        NAME: 'Can Download CSV',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      VIEW_DEMAND_GENERATION: {
        NAME: 'View Demand Generation',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
    },
  },
  HOLIDAYS: {
    CATEGORY: CATEGORIES.HOLIDAYS,
    ENTRIES: {
      CREATE_HOLIDAY: {
        NAME: 'View Create Button',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
    },
  },
  LOCATION_SETTINGS: {
    CATEGORY: CATEGORIES.LOCATION_SETTINGS,
    ENTRIES: {
      WORKDAY_INFO: {
        NAME: 'Workday Info',
        ALLOWED_ACTIONS: [ACTIONS.VIEW, ACTIONS.EDIT],
      },
      LOCATION_INFO: {
        NAME: 'Location Info',
        ALLOWED_ACTIONS: [ACTIONS.VIEW, ACTIONS.EDIT],
      },
      INSTALLER_ID: {
        NAME: 'Installer Id',
        ALLOWED_ACTIONS: [ACTIONS.VIEW, ACTIONS.EDIT],
      },
      WEBSITE_SCHEDULE_BUFFER: {
        NAME: 'Website Schedule Buffer',
        ALLOWED_ACTIONS: [ACTIONS.VIEW, ACTIONS.EDIT],
      },
      ADD_SERVICED_ZIP_CODES: {
        NAME: 'Add Serviced Zip Codes',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      REMOVE_SERVICED_ZIP_CODES: {
        NAME: 'Remove Serviced Zip Codes',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      VIEW_SERVICED_ZIP_CODES: {
        NAME: 'View Serviced Zip Codes',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      TECHNICIAN_EMAILS: {
        NAME: 'Technician Emails',
        ALLOWED_ACTIONS: [ACTIONS.VIEW, ACTIONS.EDIT],
      },
      NEW_ORDER_ALERTS: {
        NAME: 'New Order Alerts',
        ALLOWED_ACTIONS: [ACTIONS.VIEW, ACTIONS.EDIT],
      },
      SPOKE_LOCATIONS: {
        NAME: 'Spoke Locations',
        ALLOWED_ACTIONS: [ACTIONS.VIEW, ACTIONS.EDIT],
      },
      TIRE_LOCATIONS: {
        NAME: 'Tire Locations',
        ALLOWED_ACTIONS: [ACTIONS.VIEW, ACTIONS.EDIT],
      },
      BLOCKED_OFF_TIMES: {
        NAME: 'Blocked Off Times',
        ALLOWED_ACTIONS: [ACTIONS.VIEW, ACTIONS.EDIT],
      },
      DEACTIVATE_BUTTON: {
        NAME: 'Deactivate Button',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
    },
  },
  NAV_BAR: {
    CATEGORY: CATEGORIES.NAV_BAR,
    ENTRIES: {
      VIEW_DASHBOARDS: {
        NAME: 'View Dashboards',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      VIEW_HOLIDAYS: {
        NAME: 'View Holidays',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      VIEW_USER_MANAGEMENT: {
        NAME: 'View User Management',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      VIEW_REPORTS: {
        NAME: 'View Reports',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      VIEW_VANS: {
        NAME: 'View Vans',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
    },
  },
  REPORTS: {
    CATEGORY: CATEGORIES.REPORTS,
    ENTRIES: {
      DOWNLOAD_WBR_REPORT: {
        NAME: 'Download WBR Report',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
    },
  },
  SCHEDULE_VIEW: {
    CATEGORY: CATEGORIES.SCHEDULE_VIEW,
    ENTRIES: {
      ADD_LOCATION_BUTTON: {
        NAME: 'Add Location Button',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      CAN_SCHEDULE: {
        NAME: 'Can Schedule',
        ALLOWED_ACTIONS: [ACTIONS.VIEW, ACTIONS.EDIT],
      },
    },
  },
  VANS: {
    CATEGORY: CATEGORIES.VANS,
    ENTRIES: {
      EDIT_MAKE: {
        NAME: 'Edit Make',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      EDIT_MODEL: {
        NAME: 'Edit Model',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      EDIT_VAN_DETAILS: {
        NAME: 'Edit Van Details',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      EDIT_VIN: {
        NAME: 'Edit Vin',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      EDIT_YEAR: {
        NAME: 'Edit Year',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
      NEW_VAN_BUTTON: {
        NAME: 'New Van Button',
        ALLOWED_ACTIONS: [ACTIONS.ALLOW],
      },
    },
  },
  WORK_ORDER_VIEW: {
    CATEGORY: CATEGORIES.WORK_ORDER_VIEW,
    ENTRIES: {
      INDIVIDUAL_WORK_ORDER: {
        NAME: 'Individual Work Order',
        ALLOWED_ACTIONS: [ACTIONS.VIEW, ACTIONS.EDIT],
      },
    },
  },
}

export { CATEGORIES, ACTIONS, PERMISSIONS }
