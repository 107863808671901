import React, { ReactNode } from 'react'
import styles from '../styles.module.css'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import CircularProgress from '@mui/material/CircularProgress'
import { IVehicleInformationSelectProps } from '../VehicleInformationForm'
import useFetch from '../../../../../../../global/hooks/useFetchHook'
import { getModels } from '../../api'

const selectProps = {
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: 500,
        width: 250,
      },
    },
  },
}

export default function ModelInformationSelect({
  onChange,
  customerVehicle,
  label
}: IVehicleInformationSelectProps) {

  let menuItems = [] as ReactNode

  let { data, isLoading } = useFetch<string[]>(getModels(customerVehicle.brand?.name || '', customerVehicle.year || ''))

  if (data) {
    menuItems = data.map((item: any) => {
      return (
        <MenuItem
          key={item}
          value={item}>
          {item}
        </MenuItem>
      )
    })
  }

  if (isLoading) {
    return <CircularProgress size={20} />
  }

  return (
    <TextField
      className={styles.textfieldStyles}
      label={label}
      value={customerVehicle.model}
      select
      SelectProps={selectProps}
      onChange={(e) => onChange(e.target.value )}>
      {menuItems}
    </TextField>
  )
}
