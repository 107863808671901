import React, { useContext } from 'react'
import styles from '../styles/styles.module.css'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import useFetch from '../../../../../../../global/hooks/useFetchHook'
import { getInstallationImages } from './api'
import { OrderContext } from '../../../../../../../global/context/order-context/OrderContext'
import { sortImages } from './utils'
import { CircularProgress } from '@mui/material'
interface IProps {
  handleClose: Function
}
export interface InstallationImage {
  url: string
  type: string
}
export default function ViewInstallationImages(props: IProps) {
  const { orderData } = useContext(OrderContext)
  const orderVehicleObjectId = orderData.orderVehicles[0].objectId
  const { data: installImages } = orderVehicleObjectId
    ? useFetch<InstallationImage[]>(getInstallationImages(orderVehicleObjectId))
    : { data: [] }

  const renderImages = () => {
    if (!installImages) return <CircularProgress />

    const sortedImages = sortImages(installImages)

    return sortedImages.map((image) => (
      <div key={image.url} className={styles.imageContainer}>
        <p className={styles.imageLabel}>{image.type}</p>
        <img src={image.url} alt={image.type} className={styles.installImage} />
      </div>
    ))
  }

  return (
    <div className={styles.installImagesMainContainer}>
      <div className={styles.imagesFlexContainer}>{renderImages()}</div>
      <div className={styles.buttonContainer}>
        <SecondaryButton
          buttonName='Close'
          onClick={() => props.handleClose()}
        />
      </div>
    </div>
  )
}
