import styles from './styles.module.css'
import SecondaryButton from '../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import { OrderContext } from '../../../../../../global/context/order-context/OrderContext'
import { useContext } from 'react'

export interface ITrOrderNumbersProps {
  trOrderNum: string
  addTrOrderNum: string
}

interface IOrderActionsProps {
  openOrderModal: () => void
  isReadOnly: boolean
}

export default function OrderActions({
  openOrderModal,
  isReadOnly,
}: IOrderActionsProps) {
  const { isOrderInEditableStatus, isOrderInFinishableStatus, orderData } =
    useContext(OrderContext)
  const buttonName = isOrderInFinishableStatus ? 'Finish Order' : 'Edit Order'

  return (
    <div className={styles.orderActionsContainer}>
      <div className={styles.additionalActions}>
        <SecondaryButton
          onClick={openOrderModal}
          buttonName={buttonName}
          disabled={!isOrderInEditableStatus || isReadOnly}
        />
      </div>
    </div>
  )
}
