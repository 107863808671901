import { PERMISSIONS } from '../../../../global/constants/permissions'
import userHasPermission from '../../../../global/utils/user/has-permission'
import { IUser } from '../../../../../../app/entities/User'

const { CATEGORY, ENTRIES } = PERMISSIONS.LOCATION_SETTINGS
function determineIfRightColumnIsVisible(user: IUser) {
  const permissionNamesForRightColumn = [
    ENTRIES.BLOCKED_OFF_TIMES,
    ENTRIES.SPOKE_LOCATIONS,
    ENTRIES.ADD_SERVICED_ZIP_CODES,
    ENTRIES.REMOVE_SERVICED_ZIP_CODES,
    ENTRIES.VIEW_SERVICED_ZIP_CODES,
    ENTRIES.TECHNICIAN_EMAILS,
    ENTRIES.TIRE_LOCATIONS,
    ENTRIES.WORKDAY_INFO,
  ]

  const canViewSomeField = permissionNamesForRightColumn.some(({ NAME }) =>
    userHasPermission(user).view(CATEGORY, NAME),
  )
  const canEditSomeField = permissionNamesForRightColumn.some(({ NAME }) =>
    userHasPermission(user).edit(CATEGORY, NAME),
  )
  return canViewSomeField || canEditSomeField
}

export default determineIfRightColumnIsVisible
