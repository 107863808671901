import { fetchData } from './fetch'

function updateFaviconIfCountExceedsZero(count: number) {
  const favicon = document.getElementById('favicon') as HTMLLinkElement
  const useNotificationFavicon = favicon && count > 0
  if (useNotificationFavicon) {
    favicon.href = '/notification-favicon.png'
  } else {
    favicon.href = '/favicon.png'
  }
}

export function updateTabBasedUponCountOfOrdersForLocation(
  locationObjectId: string,
  count: number,
) {
  async function getCountOfOrders() {
    const countOfQuoteOrdersForLocation = await fetchData<number>({
      method: 'GET',
      url: `${process.env.REACT_APP_ORIGIN}/orders/getCountOfOrdersByLocation?selectedLocationId=${locationObjectId}`,
    })
    const countDifference = countOfQuoteOrdersForLocation - count
    if (countDifference > 0) {
      document.title = `(${countDifference}) DTMI Portal`
    } else {
      document.title = 'DTMI Portal'
    }
    updateFaviconIfCountExceedsZero(countDifference)
  }

  getCountOfOrders()
}

export function resetActiveTab(intervalId: NodeJS.Timeout | undefined) {
  if (intervalId) {
    clearInterval(intervalId) // Clean up on unmount
    intervalId = undefined
  }
  document.title = 'DTMI Portal'
  const favicon = document.getElementById('favicon') as HTMLLinkElement
  if (favicon) {
    favicon.href = '/favicon.png'
  }
}
