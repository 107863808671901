import { ReactElement, useEffect } from 'react'
import styles from '../styles.module.css'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import CircularProgress from '@mui/material/CircularProgress'
import { IVehicleInformationSelectProps } from '../VehicleInformationForm'
import useFetch from '../../../../../../../global/hooks/useFetchHook'
import { getVariants } from '../../api'

const selectProps = {
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: 500,
        width: 250,
      },
    },
  },
}

function AdditionalInformationSelect(props: IVehicleInformationSelectProps) {
  const { customerVehicle, onChange, label } = props
  let menuItems = [] as ReactElement[]

  let { data, isLoading, error } = useFetch<string[]>(
    getVariants(
      customerVehicle.brand?.name || '',
      customerVehicle.year || '',
      customerVehicle.model || '',
    ),
  )

  useEffect(() => {
    if (data) {
      const onlyOneVariant = menuItems.length === 1
      if (onlyOneVariant) {
        // if there is only one variant, set it as the default
        props.onChange(menuItems[0].props.value)
      }
    }
  }, [data])

  if (isLoading) {
    return <CircularProgress size={20} />
  }
  if (data) {
    if (data.length > 0) {
      menuItems = data.map((item: any) => {
        return (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        )
      })
    } else {
      menuItems = [
        <MenuItem key={'Base'} value={'Base'}>
          {'Base'}
        </MenuItem>,
      ]
    }
  } else if (error) {
    menuItems = [
      <MenuItem value={'Base'}>Error - No variants available</MenuItem>,
    ]
  }

  if (isLoading) {
    return <CircularProgress size={20} />
  }

  return (
    <TextField
      className={styles.textfieldStyles}
      label={label}
      value={customerVehicle.variant}
      select
      SelectProps={selectProps}
      onChange={(e) => onChange(e.target.value)}
    >
      {menuItems}
    </TextField>
  )
}

export default AdditionalInformationSelect
