import { IFetchDataProps } from '../../global/utils/fetch'
import { IGetOrderDataByIdResponse } from '../../global/context/order-context/OrderContext'
import { TiresCombination } from '../../../../app/entities/TiresCombination'
import { CustomerVehicle } from '../../../../app/entities/CustomerVehicle'
import { InstallationPoint } from '../../../../app/entities/InstallationPoint'

export function getLocationById(locationId: string): IFetchDataProps<any> {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/locations/${locationId}`,
  }
}

export function postOrder(
  order: IGetOrderDataByIdResponse,
  disposalCount: number,
  isRework: boolean,
): IFetchDataProps<any> {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/orders`,
    body: {
      order: {
        ...order,
        objectId: undefined,
        created: undefined,
        updated: undefined,
        workOrderId: undefined,
      },
    },
    params: {
      isRework: isRework ? 'true' : 'false',
      disposalCount: disposalCount.toString(),
    },
  }
}

export function putAppointmentTime(
  orderObjectId: string,
  selectedTimes: number[],
): IFetchDataProps<any> {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/route-instruction/from-time`,
    body: {
      selectedTimes,
    },
  }
}
export function putCustomer(body: any) {
  return {
    method: 'PUT',
    body: body,
    url: `${process.env.REACT_APP_ORIGIN}/customers`,
  }
}
export function putCustomerVehicle(body: CustomerVehicle | undefined) {
  return {
    method: 'PUT',
    body: body,
    url: `${process.env.REACT_APP_ORIGIN}/customer_vehicles`,
  }
}
export function putDisposalCount(
  orderObjectId: string,
  disposalCount: number,
): IFetchDataProps<any> {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/tire-disposal/count`,
    body: {
      tireDisposalCount: disposalCount,
    },
  }
}
export function putInstallationPoint(body: InstallationPoint | undefined) {
  return {
    method: 'PUT',
    body,
    url: `${process.env.REACT_APP_ORIGIN}/installation_point`,
  }
}
export function putOrder(order: any) {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/orders`,
    body: order,
  }
}
export function putOrderIsRework(orderObjectId: string, isRework: boolean) {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/is-rework/${isRework}`,
  }
}
export function putOrderStatus(orderObjectId: string, status: string) {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/status/${status}`,
  }
}
export function putTiresCombination(body: TiresCombination | undefined) {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/tires_combination/`,
    body,
  }
}
