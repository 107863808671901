import styles from './styles.module.css'
import AdditionalInformationSelect from './vehicle-info-selects/AdditionalInformationSelect'
import MakeInformationSelect from './vehicle-info-selects/MakeInformationSelect'
import ModelInformationSelect from './vehicle-info-selects/ModelInformationSelect'
import YearInformationSelect from './vehicle-info-selects/YearInformationSelect'
import {
  CustomerVehicle,
  ICustomerVehicle,
} from '../../../../../../../../app/entities/CustomerVehicle'
import { IBrand } from '../../../../../../../../app/entities/Brand'
import { useContext } from 'react'
import { OrderVehicle } from '../../../../../../../../app/entities/OrderVehicle'
import { OrderContext } from '../../../../../../global/context/order-context/OrderContext'

export interface IVehicleInformationSelectProps {
  customerVehicle: Partial<ICustomerVehicle>
  label: string
  onChange: Function
}

export default function VehicleInformationForm() {
  const { orderData, setCustomerVehicle } = useContext(OrderContext)
  const { orderVehicles } = orderData
  const customerVehicle = orderVehicles[0]?.customerVehicle

  function onVehicleInformationChange(update: Record<string, any>) {
    const key = Object.keys(update)[0]

    if (key === 'brand') {
      setCustomerVehicle({
        ...update,
        year: '',
        model: '',
        variant: '',
      } as CustomerVehicle)
    } else if (key === 'year') {
      setCustomerVehicle({
        ...update,
        model: '',
        variant: '',
      } as CustomerVehicle)
    } else if (key === 'model') {
      setCustomerVehicle({
        ...update,
        variant: '',
      } as CustomerVehicle)
    } else {
      setCustomerVehicle({
        ...update,
      } as CustomerVehicle)
    }
  }

  return (
    <div className={styles.contactFormContainer}>
      <div className={`${styles.contactTitle} font--bold`}>
        Vehicle Information
      </div>
      <MakeInformationSelect
        label='Make'
        customerVehicle={customerVehicle || {}}
        onChange={(brand: Partial<IBrand>) =>
          onVehicleInformationChange({ brand })
        }
      />
      {customerVehicle?.brand?.objectId && (
        <YearInformationSelect
          label='Year'
          customerVehicle={customerVehicle}
          onChange={(year: string) =>
            setCustomerVehicle({
              year,
            } as CustomerVehicle)
          }
        />
      )}
      {customerVehicle?.year && (
        <ModelInformationSelect
          label='Model'
          customerVehicle={customerVehicle}
          onChange={(model: string) =>
            setCustomerVehicle({
              model,
            } as CustomerVehicle)
          }
        />
      )}
      {customerVehicle?.model && (
        <AdditionalInformationSelect
          label='Additional Information'
          customerVehicle={customerVehicle}
          onChange={(variant: string) =>
            setCustomerVehicle({
              variant,
            } as CustomerVehicle)
          }
        />
      )}
    </div>
  )
}
