// Update the app/eworkflows/users/hasPermission.ts file when this file is updated
import { IUser } from '../../../../../app/entities/User'
import { IPermission } from '../../../../../app/entities/Permission'
import { ACTIONS } from '../../constants/permissions'
import getUserPermissions from './get'

export function checkPermission(
  permissions: IPermission[],
  permissionCategory: string,
  permissionName: string,
  actions: string[],
): boolean {
  const hasPermission = permissions.some((permission) => {
    return (
      permission.category === permissionCategory &&
      permission.name === permissionName &&
      actions.includes(permission.action)
    )
  })
  return hasPermission
}

function hasPermission(user: IUser | null) {
  const userPermissions = user ? getUserPermissions(user) : []

  return {
    allow: (category: string, name: string) =>
      checkPermission(userPermissions, category, name, [ACTIONS.ALLOW]),
    edit: (category: string, name: string) =>
      checkPermission(userPermissions, category, name, [ACTIONS.EDIT]),
    view: (category: string, name: string) =>
      checkPermission(userPermissions, category, name, [
        ACTIONS.EDIT,
        ACTIONS.VIEW,
      ]),
  }
}

export default hasPermission
