import styles from './styles.module.css'
import LocationDropDown from '../../../../../global/components/location-drop-down/LocationDropDown'
import TextField from '@mui/material/TextField'
import PrimaryButton from '../../../../../global/components/buttons/primary-button/PrimaryButton'
import LocationAddEditButtonsHolder from '../../../location-add-edit-buttons-holder/LocationAddEditButtonsHolder'
interface IScheduleFilterContainerProps {
  setView: Function
  scheduleFilter: {
    date: string
    locationId: string
  }
  setScheduleFilter: Function
  onOpenLocationModal: Function
}
export default function ScheduleFilterContainer({
  setView,
  scheduleFilter,
  setScheduleFilter,
  onOpenLocationModal,
}: IScheduleFilterContainerProps) {
  const handleLocationChange = (str: string) => {
    localStorage.setItem('selectedLocationObjectId', str)
    setScheduleFilter({ ...scheduleFilter, locationId: str })
  }

  const handleChange = (e: any) => {
    const { value } = e.target
    localStorage.setItem('selectedCalendarDate', value)
    setScheduleFilter({ ...scheduleFilter, date: value })
  }

  return (
    <div className={styles.navContainer}>
      <div className={styles.inputContainer}>
        <div className={styles.locationInputsHolder}>
          <LocationDropDown
            onLocationChange={(val: string) => {
              handleLocationChange(val)
            }}
            defaultValue={scheduleFilter.locationId}
          />
          <LocationAddEditButtonsHolder
            onOpenLocationModal={onOpenLocationModal}
            selectedLocationObjectId={scheduleFilter.locationId}
          />
        </div>
        <TextField
          label='Select a Date'
          type='date'
          value={scheduleFilter.date}
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
          style={{ width: 'min-content', justifySelf: 'center' }}
        />
        <PrimaryButton
          buttonName='Month View'
          onClick={() => setView('month')}
          style={{ width: 'min-content', justifySelf: 'end'}}
        />
      </div>
    </div>
  )
}
