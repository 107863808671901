import styles from './styles.module.css'
import { IRoute } from '../../../../../../../app/entities/Route'
import { ILocation } from '../../../../../../../app/entities/Location'
import { IOrder, Order } from '../../../../../../../app/entities/Order'
import statuses from '../../../../../global/constants/statuses.json'
import { DaySetting } from '../../../../../../../app/entities/DaySetting'
import calculateTimeSlotsForLocation, {
  isSlotOverlapped,
} from '../../../../../global/utils/calculateTimeSlotsForLocation'
import moment from 'moment'
import { useContext } from 'react'
import { OrderContext } from '../../../../../global/context/order-context/OrderContext'
interface IDayCalendarRowProps {
  route: IRoute | null
  location: ILocation | null
  daySettings: DaySetting[]
  date: string
  triggerRerender: () => void
}

export default function DayCalendarRow({
  route,
  location,
  daySettings,
}: IDayCalendarRowProps) {
  if (!location) return null

  const { queryOrderData } = useContext(OrderContext)

  const { routeInstructions = [], objectId } = route || {}

  const { slotStarts, slotsOverlappingLunch } =
    calculateTimeSlotsForLocation(location)

  const jobDuration = location.plannedServiceTime + location.plannedTravelTime

  const slots = slotStarts.map((slotStart, index) => {
    const key = `route-${objectId}-${index}`

    const slotStartStr = moment.utc(slotStart).format('HH:mm')

    const slotEndStr = moment
      .utc(slotStart)
      .add(jobDuration, 'minutes')
      .format('HH:mm')

    const slotStartTimeWithMeridiemStr = moment.utc(slotStart).format('h:mm A')

    const ordersInSlot: IOrder[] = routeInstructions
      .filter((instruction) => {
        const { startTime, endTime } = instruction
        const [routeInstructionStartTimeStr, routeInstructionEndTimeStr] = [
          startTime,
          endTime,
        ].map((time) => moment.utc(time).format('HH:mm'))

        const doesOrderOverlapSlot = isSlotOverlapped(
          slotStartStr,
          slotEndStr,
          routeInstructionStartTimeStr,
          routeInstructionEndTimeStr,
        )

        return doesOrderOverlapSlot
      })
      .sort((a, b) => {
        const aStartTime = moment.utc(a.startTime).format('HH:mm')
        const bStartTime = moment.utc(b.startTime).format('HH:mm')
        return aStartTime.localeCompare(bStartTime)
      })
      .map((instruction) => instruction.order)
      .filter(
        (order) => order && order.customer && order.installationPoint,
      ) as IOrder[]

    if (ordersInSlot.length) {
      return (
        <div className={`${styles.slot}`} key={key}>
          <div className={styles.slotTime}>{slotStartTimeWithMeridiemStr}</div>
          <div className={styles.slotOrdersContainer}>
            {ordersInSlot.map((order) => {
              const { customer, installationPoint, tireLocation } = order

              const { drivingDetails } = installationPoint || {}
              const { distance, duration } = drivingDetails || {}

              const status = order.status || ''

              const backgroundColor = (statuses as any)[status]?.backgroundColor
              const border = `3px solid ${
                (statuses as any)[status]?.statusColor
              }`

              return (
                <div
                  className={styles.slotOrder}
                  style={{
                    backgroundColor,
                    border,
                  }}
                  onClick={() => {
                    queryOrderData(order.objectId)
                  }}
                >
                  <div className={styles.nameAndTireLocationContainer}>
                    <div className={styles.slotOrderName}>
                      {customer?.firstName} {customer?.lastName}
                    </div>
                    <div className={styles.tireLocation}>
                      Tire Location:{' '}
                      <span style={{ fontWeight: 'bold' }}>{tireLocation}</span>
                    </div>
                  </div>
                  <div className={styles.slotOrderDetails}>
                    <div className={styles.slotOrderDetailsQuantity}>
                      Qty {order.orderVehicles[0].tiresCombination?.count}
                    </div>
                    <div className={styles.slotOrderDetailsDistance}>
                      {distance && distance.text}
                    </div>
                    <div className={styles.slotOrderDetailsDuration}>
                      {duration && duration.text}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )
    }

    const isLunch = slotsOverlappingLunch.includes(slotStart)

    const blockedByDaySetting = daySettings.find((daySetting) => {
      const { startTime, endTime } = daySetting

      return isSlotOverlapped(slotStartStr, slotEndStr, startTime, endTime)
    })

    if (isLunch || blockedByDaySetting) {
      return (
        <div
          className={`${styles.slot} ${styles.unavailable} ${
            blockedByDaySetting ? styles.blocked : styles.lunch
          }`}
          key={key}
        >
          <div className={styles.slotTime}>{slotStartTimeWithMeridiemStr}</div>
          {
            <div className={styles.slotBlockedReason}>
              Blocked: {blockedByDaySetting?.reason ?? 'Lunch'}
            </div>
          }
        </div>
      )
    }

    return (
      <div className={`${styles.slot} ${styles.empty}`} key={key}>
        <div className={styles.slotTime}>{slotStartTimeWithMeridiemStr}</div>
      </div>
    )
  })

  return (
    <div className={styles.routeContainer}>
      {/* <div className={styles.routeName}>Van</div> */}
      {slots}
    </div>
  )
}
