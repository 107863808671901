import { Chip } from '@mui/material'
import { useState } from 'react'
import styles from './styles.module.css'
import { ILocation } from '../../../../../../../app/entities/Location'

interface IZipCodesManagerProps {
  location: Partial<ILocation>
  setLocation: Function
  isReadOnly: boolean
}

export default function TireLocationsManager({
  location,
  setLocation,
  isReadOnly,
}: IZipCodesManagerProps) {
  const [locationInputBackgroundColor, setLocationInputBackgroundColor] =
    useState('#f5f5f5')
  const [locationInputValue, setLocationInputValue] = useState('')

  const locations = location.tireLocations
    ? location.tireLocations.split(',')
    : []

  const inputChip = (
    <Chip
      key='input-chip'
      style={{
        border: '1px solid #2a7187',
        cursor: isReadOnly ? undefined : 'text',
        backgroundColor: locationInputBackgroundColor,
        boxShadow: '0 0 .25rem 0 rgba(0,0,0,.25) inset',
      }}
      label={
        <input
          placeholder='Add Location'
          style={{
            all: 'unset',
            width: '10rem',
            userSelect: 'none',
          }}
          value={locationInputValue}
          disabled={isReadOnly}
          autoComplete='off'
          // allows for pasting multiple zips at once assuming
          // it is a list of zips separated by newlines
          onChange={(e) => {
            setLocationInputBackgroundColor('#f5f5f5')
            setLocationInputValue(e.target.value)
          }}
          onKeyDown={handleKeyDown}
        />
      }
    />
  )

  const emailChips = locations.map((email) => {
    return (
      <Chip
        key={email}
        label={email}
        disabled={isReadOnly}
        style={{
          backgroundColor: '#2a7187',
          color: 'white',
          fontWeight: 'bold',
        }}
        onDelete={() => {
          const remainingLocations = locations.filter((e) => e !== email)
          setLocation({
            ...location,
            tireLocations: remainingLocations.join(','),
          })
        }}
      />
    )
  })

  function handleKeyDown(e: any) {
    if (e.key === 'Enter') {
      const newLocation = e.currentTarget.value
      setLocation({
        ...location,
        tireLocations: location.tireLocations
          ? `${newLocation},${location.tireLocations}`
          : newLocation,
      })
      setLocationInputValue('')
    }
  }

  return (
    <div className={styles.technicianEmailsManagerMain}>
      <div className={styles.technicianEmailsManagerContainer}>
        <div className={styles.technicianEmailsManagerHeader}>
          <div className={`${styles.technicianEmailsManagerTitle} font--bold`}>
            Tire Locations
          </div>
          {/* <div className={styles.technicianEmailsManagerSubtitle}>
            When technicians log into Appenate, the email they use to log in must be in this list in order to see the jobs for this location.
          </div> */}
        </div>
        <div className={styles.technicianEmailsManagerChipsContainer}>
          {inputChip}
          {emailChips}
        </div>
      </div>
    </div>
  )
}
