import { useContext, useState } from 'react'
import MainPageWrapper from '../../global/components/MainPageWrapper'
import PrimaryButton from '../../global/components/buttons/primary-button/PrimaryButton'
import DemandGenerationTable from '../components/demand-generation-table/DemandGenerationTable'
import WeeksDropdown from '../components/dropdown/WeeksDropDown'
import { fetchData } from '../../global/utils/fetch'
import { getDemandGenerationTableData } from '../components/demand-generation-table/api'
import styles from './styles.module.css'
import { UserContext } from '../../base/components/base-container/BaseContainer'
import { PERMISSIONS } from '../../global/constants/permissions'
import userHasPermission from '../../global/utils/user/has-permission'

function DashboardsMain() {
  const { user } = useContext(UserContext)
  const [weeks, setWeeks] = useState<number>(4)

  const downloadCsv = async () => {
    const csv = await fetchData<any>(getDemandGenerationTableData(weeks, true))

    const blob = new Blob([csv.csv], { type: 'text/csv;charset=utf-8;' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <MainPageWrapper
      titleFields={
        <div style={{ marginRight: 'auto', marginLeft: '1rem' }}>
          <WeeksDropdown setWeeks={setWeeks} weeks={weeks} />
        </div>
      }
    >
      <div>
        {userHasPermission(user).allow(
          PERMISSIONS.DASHBOARDS.CATEGORY,
          PERMISSIONS.DASHBOARDS.ENTRIES.VIEW_DEMAND_GENERATION.NAME,
        ) && (
          <PrimaryButton
            buttonName='Demand Generation'
            onClick={() => console.log(1)}
          />
        )}
        <div className={styles.link}>
          {userHasPermission(user).allow(
            PERMISSIONS.DASHBOARDS.CATEGORY,
            PERMISSIONS.DASHBOARDS.ENTRIES.CAN_DOWNLOAD_CSV.NAME,
          ) && (
            <button className={styles.navLink} onClick={downloadCsv}>
              Download CSV
            </button>
          )}
        </div>
      </div>
      {userHasPermission(user).allow(
        PERMISSIONS.DASHBOARDS.CATEGORY,
        PERMISSIONS.DASHBOARDS.ENTRIES.VIEW_DEMAND_GENERATION.NAME,
      ) && <DemandGenerationTable weeks={weeks} />}
    </MainPageWrapper>
  )
}

export default DashboardsMain
