import { ReactNode, useMemo } from 'react'
import styles from './styles.module.css'
import linksData from '../tsx/links'
import { useLocation } from 'react-router-dom'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'

interface MainPageWrapperProps {
  children: ReactNode
  title?: string
  titleFields?: ReactJSXElement
  titleStyle?: React.CSSProperties
}

function MainPageWrapper({
  children,
  title,
  titleFields,
  titleStyle 
}: MainPageWrapperProps) {

  const links = useMemo(() => linksData, [])
  const location = useLocation()

  const activeLink = links.find((link) => link.to === location.pathname)

  return (
    <div className={styles.mainPageWrapperMain}>
      <div 
        className={`${styles.mainPageWrapperTitle} font--bold`}
        style={titleStyle || {}}
      >
        {title || (activeLink && activeLink.title) || ""}
        {titleFields}
      </div>
      <div className={styles.mainPageWrapperContainer}>{children}</div>
    </div>
  )
}

export default MainPageWrapper