import { useContext, useEffect, useState } from 'react'
import { Row } from 'react-table'
import Table from '../../../global/components/table/Table'
import { handleColumnFilterUpdate } from '../../../global/components/table/helpers/multiColumnFilters'
import retrieveData from '../../../global/components/table/helpers/retrieveData'
import TableTitle from '../../../global/components/table/helpers/table-title/TableTitle'
import TableTopper from '../../../global/components/table/helpers/table-topper/TableTopper'
import generateColumns from './generate-columns'
import RoleModalMain from '../../role-modal/RoleModalMain'
import { UserContext } from '../../../base/components/base-container/BaseContainer'

interface IRolesTableProps {
  refetchIndex: number
  setRefetchIndex: Function
}

function RolesTable({ refetchIndex }: IRolesTableProps) {
  const [roleModalOpen, setRoleModalOpen] = useState(false)
  const [selectedRoleName, setSelectedRoleName] = useState('')
  const [tableState, setTableState] = useState({
    data: [],
    isLoading: false,
    offSet: 0,
    pageSize: 100,
    count: 0,
  })
  const [columnFilters, setColumnFilters] = useState({})

  const { user } = useContext(UserContext)

  const endpoint = `${process.env.REACT_APP_ORIGIN}/roles?`
  function onColumnFilterUpdate(filtersUpdate: object) {
    handleColumnFilterUpdate(filtersUpdate, columnFilters, setColumnFilters)
  }
  function retrieveTableData() {
    retrieveData(setTableState, {
      endpoint,
      pageSize: tableState.pageSize,
      offSet: tableState.offSet,
      columnFilters,
      getPermissions: true,
    })
  }
  useEffect(() => {
    retrieveTableData()
  }, [refetchIndex, tableState.pageSize, tableState.offSet])

  function refetchData() {
    retrieveTableData()
  }

  return (
    <div style={{ width: '100%' }}>
      <RoleModalMain
        isOpen={roleModalOpen}
        roleName={selectedRoleName}
        refetchData={refetchData}
        handleClosed={() => {
          setSelectedRoleName('')
          setRoleModalOpen(false)
        }}
      />
      <TableTopper>
        <TableTitle>Roles</TableTitle>
      </TableTopper>
      <Table
        columns={generateColumns(onColumnFilterUpdate, retrieveTableData)}
        data={tableState.data ?? []}
        onRowClick={(row: Row) => {
          const { original } = row as any
          setSelectedRoleName(original.name)
          setRoleModalOpen(true)
        }}
        isLoading={tableState.isLoading}
        isPaginated
        pagination={{ setTableState, tableState }}
      />
    </div>
  )
}

export default RolesTable
