const TITLES = {
  SCHEDULE: 'Schedule',
  WORK_ORDERS: 'Work Orders',
  VANS: 'Vans',
  HOLIDAYS: 'Holidays',
  REPORTS: 'Reports',
  DASHBOARDS: 'Dashboards',
  USER_MANAGEMENT: 'User Management',
}

export { TITLES }
