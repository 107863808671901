import { CircularProgress } from '@mui/material'
import { OrderContextProvider } from '../../../global/context/order-context/OrderContext'
import styles from './styles.module.css'
import { useContext, useState } from 'react'
import { fetchData } from '../../../global/utils/fetch'
import { UserContext } from '../../../base/components/base-container/BaseContainer'
import { PERMISSIONS } from '../../../global/constants/permissions'
import userHasPermission from '../../../global/utils/user/has-permission'

interface IAllReportsProps {
  fromDate: string
  toDate: string
  location: string
}

interface IReportLink {
  name: string
  url: string
}

export default function AllReports({
  fromDate,
  toDate,
  location,
}: IAllReportsProps) {
  const { user } = useContext(UserContext)
  const [reportsState, setReportsState] = useState<
    Record<
      string,
      {
        status: 'loading' | 'ready' | 'error'
        downloadLink: string
      }
    >
  >({})

  async function onReportButtonClick(report: IReportLink) {
    if (!fromDate || !toDate)
      return alert('Choose "From" and "To" dates at the top of the page.')

    reportsState[report.name] = {
      status: 'loading',
      downloadLink: '',
    }

    setReportsState({ ...reportsState })

    const query = new URLSearchParams({
      fromDate,
      toDate,
    })

    if (location) query.append('location', location)

    const response = await fetchData<{ csv: string }>({
      method: 'GET',
      url: `${process.env.REACT_APP_ORIGIN}${report.url}?${query.toString()}`,
    })

    let downloadLink = ''

    if (response && response.csv) {
      const csvData = new Blob([response.csv], {
        type: 'text/csv;charset=utf-8;',
      })
      downloadLink = window.URL.createObjectURL(csvData)

      reportsState[report.name] = {
        status: 'ready',
        downloadLink,
      }
    } else {
      reportsState[report.name] = {
        status: 'error',
        downloadLink,
      }
    }

    setReportsState({ ...reportsState })
  }

  const links = (
    [
      userHasPermission(user).allow(
        PERMISSIONS.REPORTS.CATEGORY,
        PERMISSIONS.REPORTS.ENTRIES.DOWNLOAD_WBR_REPORT.NAME,
      )
        ? {
            name: 'WBR Report',
            url: '/reports/view/wbr',
          }
        : undefined,
    ].filter(Boolean) as { name: string; url: string }[]
  ).map((report) => {
    const state = reportsState[report.name]

    let rightSide = null

    if (!state) {
    } else if (state.status === 'loading') {
      rightSide = <CircularProgress style={{ display: 'block' }} />
    } else if (state.status === 'ready') {
      rightSide = (
        <a
          href={state.downloadLink}
          download
          className={styles.downloadLink}
          role='button'
        >
          Download
        </a>
      )
    } else if (state.status === 'error') {
      rightSide = <div className={styles.error}>Error</div>
    }

    return (
      <div key={report.name} className={styles.report}>
        <div
          className={styles.reportButton}
          onClick={() => onReportButtonClick(report)}
        >
          {report.name}
        </div>
        <div className={styles.downloadArea}>{rightSide}</div>
      </div>
    )
  })

  return (
    <OrderContextProvider>
      <div className={styles.containerWrapper}>
        <div className={styles.container}>
          <div className={styles.reportsHolder}>{links}</div>
        </div>
      </div>
    </OrderContextProvider>
  )
}
