import { InstallationImage } from "./ViewInstallationImages"

export const sortImages = (images: InstallationImage[]) => {
  const order = ['vin', 'front', 'rear', 'additional']

  return images.sort((a, b) => {
    const aPrefix = a.type.split('_')[0] // 'pre' or 'post'
    const bPrefix = b.type.split('_')[0]
    const aSuffix = a.type.split('_')[1] // 'vin', 'front', etc.
    const bSuffix = b.type.split('_')[1]

    if (aPrefix !== bPrefix) {
      return aPrefix === 'pre' ? -1 : 1
    }

    const aOrder = order.indexOf(aSuffix)
    const bOrder = order.indexOf(bSuffix)
    return aOrder - bOrder
  })
}