import { useContext, useEffect, useState } from 'react'
import WorkOrderNotes from '../notes/WorkOrderNotes'
import LineItemsTable from '../tables/line-items/LineItemsTable'
import OverviewSummaryTable from '../tables/overview-summary/OverviewSummaryTable'
import WorkOrderHistoryEntriesTable from '../tables/work-order-history-entries/WorkOrderHistoryEntriesTable'
import WorkOrderVehiclesTable from '../tables/work-order-vehicles/WorkOrderVehiclesTable'
import AdditionalActions from '../work-order-utility/AdditionalActions'
import styles from './styles.module.css'
import {
  SCHEDULER_MODES,
  SCHEDULER_STEPS,
} from '../../../../../global/constants/scheduler'
import { UserContext } from '../../../../../base/components/base-container/BaseContainer'
import { PERMISSIONS } from '../../../../../global/constants/permissions'
import hasPermission from '../../../../../global/utils/user/has-permission'
import ScheduleModal from '../../../../../schedule/components/schedule-modal/components/container-modal/ScheduleModal'
import { OrderContext } from '../../../../../global/context/order-context/OrderContext'
import { useNavigationEffect } from '../tables/overview-summary/hooks/useNavigationEffect'

const { CATEGORY, ENTRIES } = PERMISSIONS.WORK_ORDER_VIEW

const { FINISH, EDIT } = SCHEDULER_MODES
const DEFAULT_MODE = EDIT

export default function ModalContent() {
  const { user } = useContext(UserContext)
  const isReadOnly = !hasPermission(user).edit(
    CATEGORY,
    ENTRIES.INDIVIDUAL_WORK_ORDER.NAME,
  )

  const { isOrderInFinishableStatus, orderData: orderData } =
    useContext(OrderContext)

  const [chosenAddress, setChosenAddress] =
    useState<google.maps.GeocoderResult | null>(null)
  const [scheduleModalOpen, setScheduleModalOpen] = useState<boolean>(false)
  const [mode, setMode] = useState<string>(DEFAULT_MODE)
  const [chosenStep, setChosenStep] = useState<string>('')

  function openScheduleModal(step?: string) {
    if (isReadOnly) return

    if (step) setChosenStep(step)
    setScheduleModalOpen(true)
  }

  function closeScheduleModal() {
    setScheduleModalOpen(false)
    setMode(DEFAULT_MODE)
    setChosenStep('')
  }

  function finishTrOrderHandler() {
    setMode(FINISH)
    setScheduleModalOpen(true)
  }

  //custom hook to open finish flow when redirected here from cloning order
  useNavigationEffect(orderData, finishTrOrderHandler)

  return (
    <>
      <div className={styles.pageTitle}>Work Order Details</div>
      <div className={styles.woDetailsContent}>
        <ScheduleModal
          chosenAddress={chosenAddress}
          chosenStep={chosenStep}
          handleClosed={closeScheduleModal}
          isOpen={scheduleModalOpen}
          mode={mode}
          orderData={orderData}
          setChosenAddress={setChosenAddress}
        />
        <div className={styles.tablesWrapper}>
          <OverviewSummaryTable
            openOrderModal={() => {
              if (isOrderInFinishableStatus) {
                setMode(FINISH)
                openScheduleModal()
              } else openScheduleModal(SCHEDULER_STEPS.CUSTOMER_INFO)
            }}
            isReadOnly={isReadOnly}
          />
          <WorkOrderVehiclesTable
            isReadOnly={isReadOnly}
            openOrderModal={() => {
              openScheduleModal(SCHEDULER_STEPS.TIRE_SIZE_AND_QUANTITY)
            }}
          />
          <LineItemsTable
            isReadOnly={isReadOnly}
            openOrderModal={() => {
              openScheduleModal(SCHEDULER_STEPS.TIRE_SIZE_AND_QUANTITY)
            }}
          />
          <WorkOrderHistoryEntriesTable />
        </div>
        <div className={styles.sideFlexWrapper}>
          <div className={styles.sideNotesContainer}>
            <WorkOrderNotes isReadOnly={isReadOnly} />
          </div>
          <div className={styles.sideAdditionalActionsContainer}>
            <AdditionalActions isReadOnly={isReadOnly} />
          </div>
        </div>
      </div>
    </>
  )
}
