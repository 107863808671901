import { Chip } from '@mui/material'
import { useContext, useState } from 'react'
import styles from './styles.module.css'
import { ILocation } from '../../../../../../../app/entities/Location'
import { fetchData } from '../../../../../global/utils/fetch'
import { getIsSpokeLocationValid } from '../../api'
import { ToastNotificationContext } from '../../../../../global/context/toast-context/ToastNotificationContext'

interface ISpokeLocationManagerProps {
  location: Partial<ILocation>
  setLocation: Function
  isReadOnly: boolean
}

export default function SpokeLocationManager({
  location,
  setLocation,
  isReadOnly,
}: ISpokeLocationManagerProps) {
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const [locationInputBackgroundColor, setLocationInputBackgroundColor] =
    useState('#f5f5f5')
  const [locationInputValue, setLocationInputValue] = useState('')

  const spokeLocationNames = location.spokeLocations
    ? location.spokeLocations.split(',')
    : []

  const inputChip = (
    <Chip
      key='input-chip'
      style={{
        border: '1px solid #2a7187',
        cursor: isReadOnly ? undefined : 'text',
        backgroundColor: locationInputBackgroundColor,
        boxShadow: '0 0 .25rem 0 rgba(0,0,0,.25) inset',
      }}
      label={
        <input
          placeholder='Add Location'
          style={{
            all: 'unset',
            width: '10rem',
            userSelect: 'none',
          }}
          value={locationInputValue}
          disabled={isReadOnly}
          autoComplete='off'
          // allows for pasting multiple zips at once assuming
          // it is a list of zips separated by newlines
          onChange={(e) => {
            setLocationInputBackgroundColor('#f5f5f5')
            setLocationInputValue(e.target.value.toUpperCase())
          }}
          onKeyDown={handleKeyDown}
        />
      }
    />
  )

  function shouldDisableChip(spokeLocationName: string) {
    const isChipSelf = spokeLocationName === location.name

    return isReadOnly || isChipSelf
  }
  const spokeLocationNameChips = spokeLocationNames.map((spokeLocationName) => {
    return (
      <Chip
        key={spokeLocationName}
        label={spokeLocationName}
        disabled={shouldDisableChip(spokeLocationName)}
        style={{
          backgroundColor: '#2a7187',
          color: 'white',
          fontWeight: 'bold',
        }}
        onDelete={() => {
          const remainingLocations = spokeLocationNames.filter(
            (e) => e !== spokeLocationName,
          )
          setLocation({
            ...location,
            spokeLocations: remainingLocations.join(','),
          })
        }}
      />
    )
  })

  async function handleKeyDown(e: any) {
    if (e.key === 'Enter') {
      const newSpokeLocationName = String(e.currentTarget.value)
        .trim()
        .toUpperCase()
      const isSpokeLocationValid = await fetchData<boolean>(
        getIsSpokeLocationValid(newSpokeLocationName),
      )
      if (!isSpokeLocationValid) {
        setToastStatus({
          ...toastStatus,
          isOpen: true,
          message: [
            `Invalid spoke location: "${newSpokeLocationName}".`,
            'The spoke location must not appear in the list of Locations.',
          ].join(' '),
          severity: 'error',
        })
        return
      }

      const newSpokeLocationNames = location.spokeLocations
        ? location.spokeLocations.split(',')
        : [newSpokeLocationName]
      newSpokeLocationNames.push(newSpokeLocationName)
      newSpokeLocationNames.sort()
      setLocation({
        ...location,
        spokeLocations: newSpokeLocationNames.join(','),
      })
      setLocationInputValue('')
    }
  }

  return (
    <div className={styles.technicianEmailsManagerMain}>
      <div className={styles.technicianEmailsManagerContainer}>
        <div className={styles.technicianEmailsManagerHeader}>
          <div className={`${styles.technicianEmailsManagerTitle} font--bold`}>
            Spoke Locations
          </div>
        </div>
        <div className={styles.technicianEmailsManagerChipsContainer}>
          {inputChip}
          {spokeLocationNameChips}
        </div>
      </div>
    </div>
  )
}
