export const setVehicleTiresCombination = (formData: any) => {
	const body = {
		objectId: formData.objectId,
		frontTire: formData.tires.frontTire,
		rearTire: formData.tires.rearTire,
		frontCount: formData.frontCount,
		rearCount: formData.rearCount,
	}
	return {
		method: "PUT",
		url: `${process.env.REACT_APP_ORIGIN}/tires_combination`,
		body: body,
	}
}
export const updateVehicleMileage = (orderVehicleId: string, mileage: number) => {
	const body = {
		orderVehicleId,
		mileage,
	}
	return {
		method: "POST",
		url: `${process.env.REACT_APP_ORIGIN}/tech_app/addMileage`,
		body: body,
	}
}
