import React, { MouseEventHandler } from 'react'
import styles from './styles.module.css'

interface PrimaryButtonProps {
  buttonName: string
  onClick: MouseEventHandler
  disabled?: boolean
  style?: React.CSSProperties
}

export default function PrimaryButton(props: PrimaryButtonProps) {
  const { buttonName, disabled, onClick, style } = props

  return (
    <button
      style={style}
      onClick={onClick}
      disabled={disabled}
      className={styles.primaryButton}
    >
      <span className={styles.primaryButtonText}>{buttonName}</span>
    </button>
  )
}
