import PrimaryButton from '../../global/components/buttons/primary-button/PrimaryButton'
import { fetchData } from '../../global/utils/fetch'

export default function JmulletTools() {
  async function transitionRecords() {
    try {
      const res = await fetchData({
        url: `${process.env.REACT_APP_ORIGIN}/jmullet-tools/transition-availability-records-from-van-to-location`,
        method: 'POST',
      })

      alert(JSON.stringify(res))
    } catch (error: any) {
      console.error('Error transitioning records:', error)
    }
  }

  return (
    <PrimaryButton
      onClick={transitionRecords}
      buttonName='Transition Availability Records from Van to Location'
    />
  )
}
