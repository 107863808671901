export const getTireSize = (vehicle: string) => {
  const encoded = encodeURIComponent(vehicle)
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/vehicles/tire-sizes?vehicle=${encoded}`,
  }
}

export const createTireCombination = (body: any) => {
  return {
    method: 'POST',
    body: body,
    url: `${process.env.REACT_APP_ORIGIN}/tires_combination`,
  }
}
export const updateTireCombination = (body: any) => {
  return {
    method: 'PUT',
    body: body,
    url: `${process.env.REACT_APP_ORIGIN}/tires_combination`,
  }
}

export const setTireCombination = (
  body: any,
  orderId: string,
  orderVehicleId: string,
) => {
  return {
    method: 'POST',
    body: body,
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/vehicles/${orderVehicleId}/tires`,
  }
}
