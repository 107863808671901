import { useEffect, useState } from 'react'
import { Column, Row } from 'react-table'
import { IHoliday } from '../../../../../app/entities/Holiday'
import Table from '../../../global/components/table/Table'
import TableTitle from '../../../global/components/table/helpers/table-title/TableTitle'
import TableTopper from '../../../global/components/table/helpers/table-topper/TableTopper'
import { fetchData } from '../../../global/utils/fetch'
import { getHolidays } from '../../api'
interface IHolidaysTableProps {
  setOpen: Function
  refresh: any
}
interface ITableStateDataProps {
  data: IHoliday[]
  isLoading: boolean
}
function HolidaysTable(props: IHolidaysTableProps) {
  const [tableState, setTableState] = useState<ITableStateDataProps>({
    data: [],
    isLoading: false,
  })

  useEffect(() => {
    const fetchTableData = async () => {
      setTableState((prevState) => ({
        ...prevState,
        isLoading: true,
      }))

      const data: IHoliday = await fetchData<IHoliday>(getHolidays())
      setTableState((prevState: any) => ({
        ...prevState,
        data: data,
        isLoading: false,
      }))
    }
    fetchTableData()
  }, [props.refresh])

  const columns: Column[] = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Day',
      accessor: 'dayGenerated',
    },
    {
      Header: 'Occurs yearly',
      accessor: (data: any) => {
        const { occursYearly } = data
        return occursYearly ? 'Yes' : 'No'
      },
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <TableTopper>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <TableTitle>Holiday</TableTitle>
        </div>
      </TableTopper>
      <Table
        columns={columns}
        data={(tableState.data as any) ?? []}
        isLoading={tableState.isLoading}
        isDisplayMode
        onRowClick={(row: Row) => {
          const original: any = row.original
          props.setOpen({ isOpen: true, holidayData: { ...original } })
        }}
      />
    </div>
  )
}

export default HolidaysTable
