import CircularProgress from '@mui/material/CircularProgress'
import { IOrder } from '../../../../../../app/entities/Order'
import HeaderContainer from '../header-container/HeaderContainer'
import DayCalendarRow from './rows/DayCalendarRow'
import styles from './styles.module.css'
import { Route } from '../../../../../../app/entities/Route'
import { DaySetting } from '../../../../../../app/entities/DaySetting'
import moment from 'moment'
import { ILocation } from '../../../../../../app/entities/Location'

export interface IScheduleFilterProps {
  date: string
  locationId: string
}

//The endpoint for scheduling/schedule returns a bastardized version of IRouteArea, created these two to match the return
export interface IExtendedOrder extends IOrder {
  startTime: string
  endTime: string
  status: string
}

interface IDayCalendarContainerProps {
  setView: Function
  scheduleFilter: {
    date: string
    locationId: string
  }
  setScheduleFilter: Function
  routes: Route[]
  daySettings: DaySetting[]
  location: ILocation | null
  refetchCalendarEvents: Function
  isDaySettingsLoading: boolean
  refetchDaySettings: Function
  isCalendarEventsLoading: boolean
  onOpenLocationModal: Function
}

export default function DayCalendar({
  setView,
  scheduleFilter,
  setScheduleFilter,
  routes,
  daySettings,
  location,
  refetchCalendarEvents,
  isDaySettingsLoading,
  refetchDaySettings,
  isCalendarEventsLoading,
  onOpenLocationModal,
}: IDayCalendarContainerProps) {
  const handleRerender = () => {
    // This will trigger a re-render of DayCalendarContainer
    // called from modal when user updates tech.
    refetchCalendarEvents()
    refetchDaySettings()
  }

  const daySettingsForDay = (daySettings || ([] as DaySetting[])).filter(
    (daySetting) =>
      moment.utc(daySetting.day).format('yyyy-MM-DD') === scheduleFilter.date,
  )

  const renderedRoute = (
    <DayCalendarRow
      route={routes ? routes[0] : null}
      location={location}
      daySettings={daySettingsForDay}
      date={scheduleFilter.date}
      triggerRerender={handleRerender}
    />
  )

  return (
    <div className={styles.tableContainer}>
      <div className={styles.headerContainer}>
        <HeaderContainer
          scheduleFilter={scheduleFilter}
          setScheduleFilter={setScheduleFilter}
          setView={setView}
          onOpenLocationModal={onOpenLocationModal}
        />
        {/* <DayCalendarHeader /> */}
      </div>
      <div className={styles.routesContainer}>
        {isCalendarEventsLoading || isDaySettingsLoading ? (
          <div className={styles.progressContainer}>
            <CircularProgress />
          </div>
        ) : (
          <div>{renderedRoute}</div>
        )}
      </div>
    </div>
  )
}
