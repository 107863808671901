import { useContext, useState } from 'react'
import styles from './styles.module.css'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import SecondaryButton from '../../global/components/buttons/secondary-button/SecondaryButton'
import PrimaryButton from '../../global/components/buttons/primary-button/PrimaryButton'
import PrimaryCheckbox from '../../global/components/checkbox/Checkbox'
import { ToastNotificationContext } from '../../global/context/toast-context/ToastNotificationContext'
import { UserContext } from '../../base/components/base-container/BaseContainer'
import { IRolePartial } from '../../../../app/entities/Role'
import RoleCreate from './Create/RoleCreate'
import RoleEdit from './RoleEdit'
import { fetchData } from '../../global/utils/fetch'
import { createRole, deleteRole, updateRole } from '../api'
import DeleteConfirmationModal from './DeleteConfirmation'

interface IRoleModalMainProps {
  isOpen: boolean
  refetchData: Function
  handleClosed: Function
  roleName?: string
}

function RoleModalMain({
  isOpen,
  refetchData,
  handleClosed,
  roleName,
}: IRoleModalMainProps) {
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [roleData, setRoleData] = useState<IRolePartial>({
    objectId: '',
    name: '',
    permissions: [],
  })
  const isRoleBeingUpdated = Boolean(roleName)

  function handleCloseModal() {
    setRoleData({
      objectId: '',
      name: '',
      permissions: [],
    })
    setIsLoading(false)
    setIsDeleteModalOpen(false)
    handleClosed()
    refetchData()
  }
  async function handleCreateRole() {
    const body = { ...roleData }
    delete body.objectId
    try {
      setIsLoading(true)
      await fetchData(createRole(body))
      handleCloseModal()
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Role successfully created!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'There has been an error attempting to create the Role.',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }
  async function handleUpdateRole() {
    try {
      setIsLoading(true)
      await fetchData(updateRole(roleData))
      handleCloseModal()
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Role successfully updated!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'There has been an error updating the Role.',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }
  async function handleDeleteRole() {
    setIsDeleteModalOpen(true)
  }
  async function deleteModalClosed(response: boolean | React.MouseEvent) {
    setIsDeleteModalOpen(false)

    const wasRoleDeleted = response === true
    if (wasRoleDeleted) {
      handleCloseModal()
    }
  }

  return (
    <div>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        handleClosed={deleteModalClosed}
        roleData={roleData}
      />
      <Modal
        open={isOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box className={styles.roleModal}>
          <div className={`${styles.roleModalTitle} font--bold`}>
            {isRoleBeingUpdated ? 'Update Role' : 'Create Role'}
          </div>
          <div className={styles.contentContainer}>
            {isRoleBeingUpdated ? (
              <RoleEdit
                roleName={roleName ?? ''}
                roleData={roleData}
                setRoleData={setRoleData}
              />
            ) : (
              <RoleCreate roleData={roleData} setRoleData={setRoleData} />
            )}
          </div>
          <div className={styles.footerButtons}>
            <div style={{ display: 'flex', gap: '8px' }}>
              <SecondaryButton
                buttonName='Cancel'
                onClick={() => handleCloseModal()}
              />
            </div>
            <div>
              {roleName ? (
                <div className={styles.positiveButtonContainer}>
                  <PrimaryButton
                    buttonName='Delete'
                    disabled={isLoading}
                    onClick={handleDeleteRole}
                  />
                  <PrimaryButton
                    buttonName='Save'
                    disabled={isLoading}
                    onClick={handleUpdateRole}
                  />
                </div>
              ) : (
                <PrimaryButton
                  buttonName='Create'
                  disabled={isLoading}
                  onClick={handleCreateRole}
                />
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default RoleModalMain
