import styles from './styles.module.css'
import {
  Select,
  SelectListObject,
} from '../../../../../../global/components/select/Select'
import { diameter, width, aspectRatio } from './static-values'
import { ITiresCombination } from '../../../../../../../../app/entities/TiresCombination'
import { ITire } from '../../../../../../../../app/entities/Tire'
import { useContext } from 'react'
import { OrderContext } from '../../../../../../global/context/order-context/OrderContext'

function mapArrayToSelect(arr: Array<string>) {
  let mapped: SelectListObject[] = []

  arr.forEach((item: string) => {
    const obj = {
      name: item,
      objectId: item,
    }

    mapped.push(obj)
  })

  return mapped
}

export default function CustomTireSize() {
  const { orderData, setTiresCombination } = useContext(OrderContext)
  const { orderVehicles } = orderData
  const { tiresCombination } = orderVehicles[0]
  function onChange(e: PointerEvent) {
    const { name, value } = e.target as HTMLInputElement
    const [tire, field] = name.split('.')

    setTiresCombination({
      ...tiresCombination,
      [tire]: {
        ...(tiresCombination
          ? (tiresCombination[tire as keyof ITiresCombination] as ITire)
          : {}),
        [field]: value,
      },
    } as ITiresCombination)
  }

  const selectWidth = '141px'
  return (
    <div className={styles.customTireSizeContainer}>
      <div className={styles.specifyCustomSizeTitle}>Specify Custom Size</div>
      <div className={styles.frontRearContainer}>
        <div className={styles.dropdownContainer}>
          <div className={styles.dropdownContainerLabel}>Front</div>
          <div className={styles.selectContainer}>
            <Select
              onChange={onChange}
              name='frontTire.width'
              width={selectWidth}
              label='Width'
              data={mapArrayToSelect(width)}
              defaultValue={tiresCombination?.frontTire?.width || ''}
              size='small'
            />
            <Select
              onChange={onChange}
              name='frontTire.aspectRatio'
              width={selectWidth}
              label='Aspect Ratio'
              data={mapArrayToSelect(aspectRatio)}
              defaultValue={tiresCombination?.frontTire?.aspectRatio || ''}
              size='small'
            />
            <Select
              onChange={onChange}
              name='frontTire.diameter'
              width={selectWidth}
              label='Diameter'
              data={mapArrayToSelect(diameter)}
              defaultValue={tiresCombination?.frontTire?.diameter || ''}
              size='small'
            />
          </div>
        </div>
        <div className={styles.dropdownContainer}>
          <div className={styles.dropdownContainerLabel}>Rear</div>
          <div className={styles.selectContainer}>
            <Select
              onChange={onChange}
              name='rearTire.width'
              width={selectWidth}
              label='Width'
              data={mapArrayToSelect(width)}
              defaultValue={tiresCombination?.rearTire?.width || ''}
              size='small'
            />
            <Select
              onChange={onChange}
              name='rearTire.aspectRatio'
              width={selectWidth}
              label='Aspect Ratio'
              data={mapArrayToSelect(aspectRatio)}
              defaultValue={tiresCombination?.rearTire?.aspectRatio || ''}
              size='small'
            />
            <Select
              onChange={onChange}
              name='rearTire.diameter'
              width={selectWidth}
              label='Diameter'
              data={mapArrayToSelect(diameter)}
              defaultValue={tiresCombination?.rearTire?.diameter || ''}
              size='small'
            />
          </div>
        </div>
      </div>
    </div>
  )
}
