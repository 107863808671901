import Checkbox from '@mui/material/Checkbox'
import React, { useContext, useState } from 'react'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import styles from '../styles/styles.module.css'
import { fetchData } from '../../../../../../../global/utils/fetch'
import { cloneOrder } from './api'
import { useNavigate, useParams } from 'react-router-dom'
import { OrderContext } from '../../../../../../../global/context/order-context/OrderContext'
import { ToastNotificationContext } from '../../../../../../../global/context/toast-context/ToastNotificationContext'
import CircularProgress from '@mui/material/CircularProgress'

interface IProps {
  handleClose: Function
}

function CloneOrder(props: IProps) {
  const navigate = useNavigate()
  const { id: idFromParams } = useParams()
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { queryOrderData, orderData } = useContext(OrderContext)
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)

  const orderId = idFromParams || orderData?.objectId

  async function handleCloneOrder() {
    try {
      if (orderId) {
        setIsLoading(true)
        const newlyClonedOrderId = await fetchData<string>(cloneOrder(orderId))
        setToastStatus({
          ...toastStatus,
          isOpen: true,
          message:
            'The order has successfully been cloned; you have been redirected to the new order.',
          severity: 'success',
        })

        if (newlyClonedOrderId) {
          //navigates users to new order, and passes state value finish = true as a flag to open the finish flow modal
          navigate(`/work-orders/${newlyClonedOrderId}`, {
            state: { finish: 'true' },
          })
          await queryOrderData(newlyClonedOrderId)
        } else {
          console.error('Newly cloned order ID is undefined or null')
        }

        setIsLoading(false)
      }
    } catch (error) {
      console.error(error)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'An error occurred during the cloning of the order.',
        severity: 'error',
      })
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.inputsContainer}>
      {isLoading && (
        <div
          style={{
            width: '100&',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div className={styles.checkBoxContainer}>
        <Checkbox onChange={() => setIsChecked(!isChecked)} />
        <div>Yes I want to clone this order</div>
      </div>
      <div className={styles.buttonContainer}>
        <SecondaryButton
          buttonName='Cancel'
          onClick={() => props.handleClose()}
        />
        <PrimaryButton
          buttonName='Clone Order'
          disabled={!isChecked || isLoading}
          onClick={handleCloneOrder}
        />
      </div>
    </div>
  )
}

export default CloneOrder
