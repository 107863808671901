import { useContext, useEffect, useState } from 'react'
import { ILocation } from '../../../../../app/entities/Location'
import PrimaryButton from '../../../global/components/buttons/primary-button/PrimaryButton'
import styles from './styles.module.css'
import GoogleAddressSearchBar from '../../../global/components/google-address-search/GoogleAddressSearchBar'
import { UserContext } from '../../../base/components/base-container/BaseContainer'
import { PERMISSIONS } from '../../../global/constants/permissions'
import userHasPermission from '../../../global/utils/user/has-permission'
import { SCHEDULER_MODES } from '../../../global/constants/scheduler'
import { logAddressValidationData } from '../schedule-modal/utils/log-address-validation-record'
import { OrderContext } from '../../../global/context/order-context/OrderContext'
import ScheduleModal from '../schedule-modal/components/container-modal/ScheduleModal'
import { InstallationPoint } from '../../../../../app/entities/InstallationPoint'
import createInstallationPointFromGeocode from '../../../global/utils/installation-point/create/from-geocode'
import { updateLocationTabulationAreas } from '../../../locations/components/location-modal/api'
import { fetchData } from '../../../global/utils/fetch'

const { SCHEDULE_VIEW } = PERMISSIONS

interface INewAppointmentProps {
  refetchCalendarEvents: Function
  location: ILocation | undefined
}

export default function NewAppointment({
  refetchCalendarEvents,
  location: userSelectedLocation,
}: INewAppointmentProps) {
  const { user } = useContext(UserContext)
  const canUserSchedule = userHasPermission(user).allow(
    SCHEDULE_VIEW.CATEGORY,
    SCHEDULE_VIEW.ENTRIES.CAN_SCHEDULE.NAME,
  )
  // const [tabulationAreas, setTabulationAreas] = useState<ITabulationArea[]>([])
  const { orderData, setInstallationPoint } = useContext(OrderContext)
  const userSelectedLocationObjectId = userSelectedLocation?.objectId ?? ''
  const [open, setOpen] = useState(false)
  const [isZipCodeServiced, setIsZipCodeServiced] = useState<
    boolean | undefined
  >(undefined)
  const [appointButtonName, setAppointmentButtonName] = useState<string>(
    'Start typing to select an address',
  )
  const [useUnvalidatedAddress, setUseUnvalidatedAddress] =
    useState<boolean>(false)
  const [chosenAddress, setChosenAddress] =
    useState<google.maps.GeocoderResult | null>(null)

  // State to handle when the "Click here" link is clicked
  const [forceScheduleUnservicedZipCode, setForceScheduleUnservicedZipCode] =
    useState<boolean>(false)
  const [zipCodeisUnserviced, setZipCodeisUnserviced] = useState<boolean>(false)

  async function handleOpen() {
    const newInstallationPoint = chosenAddress
      ? await createInstallationPointFromGeocode(
          chosenAddress,
          userSelectedLocationObjectId,
        )
      : {}
    if (!newInstallationPoint) {
      alert(
        [
          'No address was selected! Please refresh and try again. If the',
          'problem persists, please contact support.',
        ].join(''),
      )
      return
    }
    setInstallationPoint(newInstallationPoint as InstallationPoint)

    if (!useUnvalidatedAddress) {
      logAddressValidationData(
        orderData.installationPoint ?? {},
        userSelectedLocation,
      )
    }

    setOpen(true)
  }

  function handleClosed() {
    setOpen(false)
    refetchCalendarEvents()
  }

  function conditionallySetButtonName() {
    setZipCodeisUnserviced(false)
    if (!chosenAddress) {
      setAppointmentButtonName('Start typing to select an address')
    } else if (isZipCodeServiced === undefined) {
      setAppointmentButtonName('Checking zip code...')
    } else if (!isZipCodeServiced && !forceScheduleUnservicedZipCode) {
      setAppointmentButtonName('Zip code not serviced')
      setZipCodeisUnserviced(true)
    } else if (!canUserSchedule) {
      setAppointmentButtonName('User not allowed to schedule')
    } else {
      setAppointmentButtonName('Start Appointment')
    }
  }

  useEffect(() => {
    if (useUnvalidatedAddress) {
      handleOpen()
    }
  }, [useUnvalidatedAddress])

  useEffect(() => {
    conditionallySetButtonName()
  }, [
    isZipCodeServiced,
    chosenAddress,
    canUserSchedule,
    forceScheduleUnservicedZipCode,
    isZipCodeServiced,
  ])

  function isScheduleButtonDisabled() {
    const notValidAddress =
      !chosenAddress ||
      (!isZipCodeServiced && !forceScheduleUnservicedZipCode) ||
      !canUserSchedule
    return notValidAddress
  }

  function handleForceSchedule() {
    setZipCodeisUnserviced(true)
    setForceScheduleUnservicedZipCode(true)
    const zipCode = unservicedButSearchForZipcode
    if (userSelectedLocation?.tabulationAreas) {
      const updatedTabulationAreas = [
        ...userSelectedLocation.tabulationAreas,
        { zipCode },
      ]
      fetchData(
        updateLocationTabulationAreas(
          userSelectedLocation.objectId || '',
          updatedTabulationAreas,
        ),
      )
    }
    setZipCodeisUnserviced(false)
    handleOpen()
  }

  const unservicedButSearchForZipcode = chosenAddress?.address_components
    .filter((comp) => comp.types[0] === 'postal_code')
    .map((comp) => comp.long_name)[0]

  return (
    <div className={styles.makeAppContainer}>
      <div className={styles.addressSearchBar}>
        <GoogleAddressSearchBar
          isZipCodeServiced={isZipCodeServiced}
          setIsZipCodeServiced={setIsZipCodeServiced}
          activeLocation={userSelectedLocation}
          setChosenAddress={setChosenAddress}
          setUseUnvalidatedAddress={setUseUnvalidatedAddress}
        />
        <div>
          <PrimaryButton
            buttonName={appointButtonName}
            disabled={isScheduleButtonDisabled()}
            onClick={handleOpen}
            style={{ width: '22rem' }}
          />
          {zipCodeisUnserviced && (
            <div className={styles.stillWantToSchedule}>
              Still want to schedule in {unservicedButSearchForZipcode}?{' '}
              <a href='#' onClick={handleForceSchedule}>
                Click here
              </a>
            </div>
          )}
        </div>
      </div>
      <ScheduleModal
        isOpen={open}
        handleClosed={handleClosed}
        mode={SCHEDULER_MODES.SCHEDULER}
        chosenAddress={chosenAddress}
        chosenLocationId={userSelectedLocationObjectId}
        initialUseUnvalidatedAddress={useUnvalidatedAddress}
        setChosenAddress={setChosenAddress}
      />
    </div>
  )
}
