import { IOrderVehicle } from '../../../../app/entities/OrderVehicle'

//work order modal context api call
export function getOrderDataById(orderId: string | undefined) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}`,
  }
}
//order vehicles table
export function getOrderVehiclesById(orderId: string | undefined) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/order_vehicles/byOrderObjectId?orderObjectId=${orderId}`,
  }
}
//line items table
export function getOrderServicesById(orderId: string | undefined) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/order-services`,
  }
}
//discounts table
export function getDiscountsAttachedToOrderById(orderId: string | undefined) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/discounts/byOrderId?orderObjectId=${orderId}`,
  }
}
//tech table
export function getTechAssignedToWorkOrder(orderId: string | undefined) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/technicians/assignedToWorkOrder?orderObjectId=${orderId}`,
  }
}
//work order history table
export function getOrderHistoryById(orderId: string | undefined) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/orders/orderHistory?orderObjectId=${orderId}`,
  }
}
//cancel order sub modal
export function updateOrderStatusToCanceledStatus(
  orderObjectId: string,
  cancelledStatus: 'cancelled' | 'cancelled_onsite',
) {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/status/${cancelledStatus}`,
  }
}

export function updateOrderVehicle(orderVehicle: IOrderVehicle) {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/order_vehicles`,
    body: orderVehicle,
  }
}
//cancel order sub modal
//edit order note
export function overwriteOrderNote(orderObjectId: string, note: string) {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderObjectId}/note`,
    body: note,
  }
}
//send customer CC update

export function sendCustomerPaymentMethodUpdate(orderId: string) {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/payment-method-update-invite`,
    body: {
      orderId,
      origin: window.location.origin,
    },
  }
}

export function getLocations() {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/locations/location`,
  }
}
//adjust invoice modal
export function adjustCalculateCost(orderId: string | undefined, body: any) {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/adjust/calculate-cost`,
    body,
  }
}
export function adjustOrderServices(
  orderId: string | undefined,
  formData: any,
) {
  const body = {
    amount: formData.amount,
    invoiceType: formData.adjustType,
    note: formData.note,
  }
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/adjust`,
    body,
  }
}
export function adjustTip(orderId: string | undefined, formData: any) {
  const body = {
    amount: formData.amount,
    invoiceType: formData.adjustType,
    note: formData.note,
  }
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/adjust/tip`,
    body,
  }
}

export function getPaymentAllocationAmount(invoiceObjectId: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/invoice/${invoiceObjectId}/paymentAllocations`,
  }
}
