import { Chip } from '@mui/material'
import { useContext, useState } from 'react'
import { ITabulationArea } from '../../../../../../../app/entities/TabulationArea'
import styles from './styles.module.css'
import { UserContext } from '../../../../../base/components/base-container/BaseContainer'
import hasPermission from '../../../../../global/utils/user/has-permission'
import { PERMISSIONS } from '../../../../../global/constants/permissions'

const { CATEGORY } = PERMISSIONS.LOCATION_SETTINGS
const {
  ADD_SERVICED_ZIP_CODES,
  REMOVE_SERVICED_ZIP_CODES,
  VIEW_SERVICED_ZIP_CODES,
} = PERMISSIONS.LOCATION_SETTINGS.ENTRIES

interface IZipCodesManagerProps {
  tabulationAreas: Partial<ITabulationArea>[]
  setTabulationAreas: Function
}

export default function ZipCodesManager({
  tabulationAreas,
  setTabulationAreas,
}: IZipCodesManagerProps) {
  const { user } = useContext(UserContext)
  const [zipCodeInputBackgroundColor, setZipCodeInputBackgroundColor] =
    useState('#f5f5f5')
  const [zipCodeInputValue, setZipCodeInputValue] = useState('')

  const zips = tabulationAreas
    ? tabulationAreas.map((tabulationArea) => tabulationArea.zipCode).sort()
    : []

  const canAddNewZipCodes = hasPermission(user).allow(
    CATEGORY,
    ADD_SERVICED_ZIP_CODES.NAME,
  )
  const canRemoveExistingZipCodes = hasPermission(user).allow(
    CATEGORY,
    REMOVE_SERVICED_ZIP_CODES.NAME,
  )
  const canViewExistingZipCodes = hasPermission(user).allow(
    CATEGORY,
    VIEW_SERVICED_ZIP_CODES.NAME,
  )

  const inputChip = canAddNewZipCodes ? (
    <Chip
      key='input-chip'
      style={{
        border: '1px solid #2a7187',
        cursor: canAddNewZipCodes ? 'text' : undefined,
        backgroundColor: zipCodeInputBackgroundColor,
        boxShadow: '0 0 .25rem 0 rgba(0,0,0,.25) inset',
      }}
      label={
        <input
          placeholder='Add Zip'
          style={{
            all: 'unset',
            width: '3.5rem',
            userSelect: 'none',
          }}
          value={zipCodeInputValue}
          disabled={!canAddNewZipCodes}
          autoComplete='off'
          onPaste={(e) => {
            if (!canAddNewZipCodes) return
            const zipsString = e.clipboardData.getData('text/plain')

            const goodZips = zipsString
              .split('\n')
              .map((zip) => zip.trim())
              .filter((zip) => zip.length === 5)
              .filter(
                (zip) =>
                  !tabulationAreas.find(
                    (tabulationArea) => tabulationArea.zipCode === zip,
                  ),
              )

            setTabulationAreas([
              ...tabulationAreas,
              ...goodZips.map((zip) => ({ zipCode: zip })),
            ])

            setTimeout(() => {
              setZipCodeInputValue('')
            }, 1)
          }}
          onChange={(e) => {
            setZipCodeInputBackgroundColor('#f5f5f5')
            setZipCodeInputValue(e.target.value)
          }}
          onKeyDown={handleKeyDown}
        />
      }
    />
  ) : null

  const zipChips = zips.map((zip, idx) => (
    <Chip
      key={zip + '-' + idx}
      label={zip}
      style={{
        backgroundColor: '#2a7187',
        color: 'white',
        fontWeight: 'bold',
      }}
      disabled={!canRemoveExistingZipCodes}
      onDelete={() => {
        if (!canRemoveExistingZipCodes) return
        setTabulationAreas(
          tabulationAreas.filter(
            (tabulationArea) => tabulationArea.zipCode !== zip,
          ),
        )
      }}
    />
  ))

  function handleKeyDown(e: any) {
    if (e.key === 'Enter') {
      const newZip = e.currentTarget.value
      if (newZip && newZip.length === 5) {
        if (
          tabulationAreas.find(
            (tabulationArea) => tabulationArea.zipCode === newZip,
          )
        ) {
          setZipCodeInputBackgroundColor('#fcebeb')
        } else {
          setTabulationAreas([
            ...tabulationAreas,
            {
              zipCode: newZip,
            },
          ])
          setZipCodeInputValue('')
        }
      }
    }
  }

  return (
    <>
      {canViewExistingZipCodes && (
        <div className={styles.zipCodesManagerMain}>
          <div className={styles.zipCodesManagerContainer}>
            <div className={styles.zipCodesManagerHeader}>
              <div className={`${styles.zipCodesManagerTitle} font--bold`}>
                Serviced Zip Codes
              </div>
              <div className={styles.zipCodesManagerSubtitle}>
                To input zip codes quickly, copy and paste zips from an Excel or
                Google Sheet.
              </div>
            </div>
            <div className={styles.zipCodesManagerChipsContainer}>
              {inputChip}
              {zipChips}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
