import moment from 'moment'
import { ILocation } from '../../../../app/entities/Location'

/*
  EQUIVALENT FUNCTION EXISTS ON THE BACKEND AT app/eworkflows/location/calculate-time-slots.ts

  Any changes to this function should be made in the backend equivalent
*/

function convertZeroTo24(str: string) {
  return str === '00:00' ? '24:00' : str
}

export function isSlotOverlapped(
  slotStartStr: string,
  slotEndStr: string,
  incomingStartStr: string,
  incomingEndStr: string,
) {
  // we need to convert 00:00 to 24:00 to make the comparison work
  // where only an "end" time is 00:00 since end being 24:00 is not possible
  ;[slotEndStr, incomingEndStr] = [slotEndStr, incomingEndStr].map(
    convertZeroTo24,
  )

  const slotIsBeforeIncoming =
    slotStartStr <= incomingStartStr && slotEndStr <= incomingStartStr
  const slotIsAfterIncoming =
    slotStartStr >= incomingEndStr && slotEndStr >= incomingEndStr

  return !slotIsBeforeIncoming && !slotIsAfterIncoming
}

const EMPTY_SLOTS_RESULT = {
  slotStarts: [],
  slotsOverlappingLunch: [],
}

function calculateTimeSlotsForLocation(location: Partial<ILocation>) {
  const {
    workDayFrom,
    workDayTo,
    lunchFrom,
    lunchTo,
    plannedServiceTime = 0,
    plannedTravelTime = 0,
  } = location

  const noPlannedTimes = plannedServiceTime === 0 && plannedTravelTime === 0

  if (!workDayFrom || !workDayTo || !lunchFrom || !lunchTo || noPlannedTimes) {
    return { ...EMPTY_SLOTS_RESULT }
  }

  const [
    [workDayFromHours, workDayFromMinutes],
    [workDayToHours, workDayToMinutes],
  ] = [workDayFrom, workDayTo].map((time) => time.split(':').map(Number))

  const workDayDurationInMinutes =
    (workDayToHours - workDayFromHours) * 60 +
    workDayToMinutes -
    workDayFromMinutes

  if (workDayDurationInMinutes <= 0) {
    return { ...EMPTY_SLOTS_RESULT }
  }

  let plannedJobTime = plannedServiceTime + plannedTravelTime

  const slotsCount = Math.floor(workDayDurationInMinutes / plannedJobTime)

  const slotStarts = Array(slotsCount)
    .fill(null)
    .map((_, i) => {
      const slotStart = moment
        .utc()
        .set({
          hours: workDayFromHours,
          minutes: workDayFromMinutes,
          seconds: 0,
          milliseconds: 0,
        })
        .toDate()
      slotStart.setMinutes(slotStart.getMinutes() + i * plannedJobTime)
      return slotStart
    })

  const slotsOverlappingLunch = slotStarts.filter((slotStart) => {
    const slotStartStr = moment.utc(slotStart).format('HH:mm')
    const slotEnd = new Date(slotStart)
    slotEnd.setMinutes(slotEnd.getMinutes() + plannedJobTime)
    const slotEndStr = moment.utc(slotEnd).format('HH:mm')

    return isSlotOverlapped(slotStartStr, slotEndStr, lunchFrom, lunchTo)
  })

  return {
    slotStarts,
    slotsOverlappingLunch,
  }
}

export default calculateTimeSlotsForLocation
