import { TaxRuleCodes } from './tax-rule'

const SERVICE_NAMES = {
  TIRE_INSTALL: 'Tire Install',
  REWORK: 'Rework',
  TIRE_DISPOSAL: 'Tire Disposal',
}

const TIRE_INSTALL_TIRE_RACK_NAMES = {
  WHEELS_ONLY: 'WheelsOnly',
  TIRE_MOUNTING: 'TireMounting',
}

const SERVICE_TAX_CODES = {
  [SERVICE_NAMES.TIRE_INSTALL]: TaxRuleCodes.TIRE_DISPOSAL,
  // [ServiceNames.TIRE_DISPOSAL]: TaxRuleCodes.TIRE_DISPOSAL,
  // [ServiceNames.TIRE_PURCHASE]: TaxRuleCodes.OMIT,
}

export {
  SERVICE_NAMES,
  SERVICE_TAX_CODES,
  TIRE_INSTALL_TIRE_RACK_NAMES as TIRE_INSTALL_TIRE_RACK_NAMES,
}
