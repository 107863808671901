import { IGetOrderDataByIdResponse } from './OrderContext'

export const initialOrderValues: IGetOrderDataByIdResponse = {
  additionalTireRackOrderNumber: null,
  bookings: [],
  cancellationNote: '',
  cancelled_onsite_flag: 0,
  cases: [],
  created: 0,
  createdFrom: null,
  discounts: [],
  ecomId: null,
  invoices: [],
  memberId: null,
  note: null,
  notificationsLog: undefined,
  objectId: '',
  orderServices: [],
  orderSource: null,
  orderVehicles: [],
  originZipCode: null,
  rating: 0,
  routeInstructions: [],
  salesRepresentativeNumber: '',
  scheduleLeadTime: 0,
  secondaryTechnician: false,
  services: [],
  source: null,
  status: null,
  step: null,
  surveyUrl: null,
  technician: null,
  tireArrivalStatus: '',
  tireLocation: '',
  tireRackOrder: null,
  tireRackOrders: [],
  tireSource: '',
  updated: 0,
  scheduledMessages: [],
  syncedWithDataWarehouse: undefined,
  orderHistory: [],
  workOrderId: 0,
}
