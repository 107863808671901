import styles from './styles.module.css'

interface CustomSizeButtonProps {
  id: number | undefined
  onClick: Function
  selectedTireSizeButtonIndex: number | undefined
}

export default function CustomSizeButton({
  id,
  onClick,
  selectedTireSizeButtonIndex,
}: CustomSizeButtonProps) {
  let borderStyles = {}

  if (id === selectedTireSizeButtonIndex) {
    borderStyles = styles.tireSizeButtonRadioBorder1
  } else {
    borderStyles = styles.tireSizeButtonRadioBorder2
  }

  return (
    <div onClick={() => onClick(id)} className={styles.tireSizeButton}>
      <div className={`${styles.tireSizeButtonRadio} ${borderStyles}`}></div>
      <div className={styles.customTireSizeButtonTitle}>
        Enter a custom tire size
      </div>
    </div>
  )
}
