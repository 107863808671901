import styles from './styles.module.css'
import { fetchData } from '../../../../../global/utils/fetch'
import { getAddressValidation, getIsZipServiced } from './api'
import { PostalAddress } from '../../../../../../../app/types/google-maps'
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Box, Modal, TextField } from '@mui/material'
import {
  IInstallationPointPartial,
  InstallationPoint,
} from '../../../../../../../app/entities/InstallationPoint'
import {
  CONFIRMATION_LEVEL_KEYS,
  GEOCODE_PLACE_TYPES,
  initialConfirmationLevel,
} from '../../../../../global/constants/google-maps-address'
import { STATUSES } from './text-field-statuses'
import SecondaryButton from '../../../../../global/components/buttons/secondary-button/SecondaryButton'
import PrimaryButton from '../../../../../global/components/buttons/primary-button/PrimaryButton'
import { ILocation } from '../../../../../../../app/entities/Location'
import {
  googleMapsAddressValidation,
  IConfirmationLevel,
} from '../../../../../global/types/google-maps-address'
import { logAddressValidationData } from '../../utils/log-address-validation-record'
import { OrderContext } from '../../../../../global/context/order-context/OrderContext'
import createInstallationPointFromAddressValidation from '../../../../../global/utils/installation-point/create/from-address-validation'

interface IUnvalidatedAddressProps {
  typedAddress: string
  isOpen: boolean
  setIsThisOpen: Dispatch<SetStateAction<boolean>>
  isZipCodeServiced: boolean | undefined
  setIsZipCodeServiced: Function
  setUseUnvalidatedAddress: Dispatch<SetStateAction<boolean>>
}

const unvalidatedAddressNotePrefix = 'Unvalidated Address:'

export function UnvalidatedAddress({
  typedAddress,
  isOpen,
  setIsThisOpen,
  isZipCodeServiced,
  setIsZipCodeServiced,
  setUseUnvalidatedAddress,
}: IUnvalidatedAddressProps) {
  const { orderData, setInstallationPoint, setOrderNotes } =
    useContext(OrderContext)
  const { installationPoint, location, note } = orderData

  const [unvalidatedAddressNote, setUnvalidatedAddressNote] =
    useState<string>('')

  const [buttonName, setButtonName] = useState<string>(
    'Use Unvalidated Address',
  )
  const [warningText, setWarningText] = useState<string>(
    'Please carefully fill out the fields below.',
  )
  const [addressFilled, setAddressFilled] = useState<boolean>(false)
  const [confirmationLevel, setConfirmationLevel] =
    useState<IConfirmationLevel>(initialConfirmationLevel)

  useEffect(() => {
    setConditionalDataFromConfirmationLevel(
      confirmationLevel,
      setWarningText,
      setButtonName,
      setAddressFilled,
      isZipCodeServiced,
    )
    setUseUnvalidatedAddress(false)
    validateAddressWithGoogle(typedAddress)
  }, [])

  function setUnvalidatedInstallationPoint(
    installationPoint: IInstallationPointPartial,
  ) {
    setInstallationPoint(installationPoint as InstallationPoint, true)
  }

  async function validateAddressWithGoogle(typedAddress: string) {
    const postalAddress: PostalAddress = {
      regionCode: 'US',
      addressLines: [typedAddress],
    }
    const result = (await fetchData(
      getAddressValidation(postalAddress),
    )) as googleMapsAddressValidation.result
    const { verdict, address } = result
    const { inputGranularity, addressComplete } = verdict
    const addressIsPremise =
      inputGranularity.toLowerCase().includes(GEOCODE_PLACE_TYPES.premise) &&
      addressComplete === true
    if (addressIsPremise) {
      const installationPointWithConfirmation =
        createInstallationPointFromAddressValidation(address.addressComponents)
      const {
        installationPoint: newInstallationPoint,
        confirmationLevel: newConfirmationLevel,
      } = installationPointWithConfirmation
      setUnvalidatedInstallationPoint(newInstallationPoint)
      setConfirmationLevel(newConfirmationLevel)
    } else {
      setConfirmationLevel(initialConfirmationLevel)
    }
  }

  function updateInstallationPoint(e: ChangeEvent<HTMLInputElement>) {
    let { name, value } = e.target
    setUnvalidatedInstallationPoint({
      ...orderData.installationPoint,
      [name]: value,
    })
  }

  function handleClose() {
    setIsThisOpen(false)
    setUseUnvalidatedAddress(false)
  }

  function handleStart() {
    const unvalidatedNoteToAppend = `${unvalidatedAddressNotePrefix} ${unvalidatedAddressNote}\n\n`

    const notes = Boolean(orderData.note)
      ? `${note}\n\n${unvalidatedNoteToAppend}`
      : unvalidatedNoteToAppend
    setOrderNotes(notes)

    setIsThisOpen(false)
    setUseUnvalidatedAddress(true)

    logAddressValidationData(
      installationPoint ?? {},
      location,
      confirmationLevel,
    )
  }

  async function checkIsZipServiced(
    zipCode: string,
    location: ILocation | undefined,
  ) {
    const isServiced = await fetchData<any>(getIsZipServiced(zipCode, location))
    setIsZipCodeServiced(isServiced.serviced)
  }

  const buttonDisabled =
    !isZipCodeServiced || unvalidatedAddressNote.length === 0 || !addressFilled

  useEffect(() => {
    const zipCodeIsValid =
      Boolean(installationPoint?.zipCode) &&
      (installationPoint?.zipCode?.length ?? 0) >= 5
    if (zipCodeIsValid) {
      checkIsZipServiced(installationPoint?.zipCode ?? '', location)
    }
    setConditionalDataFromConfirmationLevel(
      confirmationLevel,
      setWarningText,
      setButtonName,
      setAddressFilled,
      isZipCodeServiced,
    )
  }, [installationPoint, isZipCodeServiced, buttonDisabled])

  function handleRevalidateAddress() {
    validateAddressWithGoogle(
      [
        installationPoint?.address,
        installationPoint?.aptOrSuite,
        `${installationPoint?.city},`,
        installationPoint?.state,
        installationPoint?.zipCode,
      ]
        .filter((str) => str !== ',' || Boolean(str))
        .join(' '),
    )
  }

  function setConditionalDataFromConfirmationLevel(
    confirmationLevel: IConfirmationLevel,
    setWarningText: Function,
    setButtonName: Function,
    setAddressFilled: Function,
    zipCodeIsServiced: boolean | undefined,
  ) {
    //This occurs when the address is in the Google DB
    const hasOnlyConfirmedFields = Object.values(confirmationLevel).every(
      (level) => level === CONFIRMATION_LEVEL_KEYS.CONFIRMED,
    )
    //This occurs when it sees a street address like 111196 Castle Drive with a valid Plymouth IN - it thinks it is possible but unreconizable
    const hasPlausibleFields = Object.values(confirmationLevel).some(
      (level) => level === CONFIRMATION_LEVEL_KEYS.UNCONFIRMED_BUT_PLAUSIBLE,
    )
    //This occurs when it sees a city like dfjasdlkfjPlymouth but has enough other valid material for it to be skeptical and not get a recognizable address
    const hasSuspiciousFields = Object.values(confirmationLevel).some(
      (level) => level === CONFIRMATION_LEVEL_KEYS.UNCONFIRMED_AND_SUSPICIOUS,
    )
    //This occurs when it all the inputs are gobbily-goop. It will return suspicious if some things make sense and unspecified if none of it does
    const hasUnspecifiedFields = Object.values(confirmationLevel).every(
      (level) =>
        level === CONFIRMATION_LEVEL_KEYS.CONFIRMATION_LEVEL_UNSPECIFIED,
    )

    if (hasPlausibleFields && !hasSuspiciousFields) {
      setWarningText(
        `Oops! The address you are looking for is not recognized. Consider revising the highlighted fields. It looks like the address could be real, but we cannot find it in Google's database. You may, however, proceed with this address.`,
      )
      setAddressFilled(true)
      if (zipCodeIsServiced === true && unvalidatedAddressNote.length > 0) {
        setButtonName('Use Unvalidated Address')
      } else if (zipCodeIsServiced === false) {
        setButtonName('Zip Code is not serviceable')
      } else if (unvalidatedAddressNote.length === 0) {
        setButtonName(`Address Note req'd`)
      }
    } else if (hasPlausibleFields && hasSuspiciousFields) {
      setAddressFilled(false)
      setWarningText(
        `Oops! The address you are looking for is not recognized. It has some parts that don't look right at all. You will need to address those to continue. Other parts seem possible, but we cannot find them in the Google database.`,
      )
      setButtonName('Revise and Check Address')
    } else if (hasSuspiciousFields) {
      setAddressFilled(false)
      setWarningText(
        `Oops! The address you are looking for is not recognized. It has some parts that don't look right at all. You will need to address those to continue.`,
      )
      setButtonName('Revise and Check Address')
    } else if (hasOnlyConfirmedFields) {
      setAddressFilled(true)
      setWarningText(`It looks like this address is good!`)
      setUnvalidatedAddressNote('Address was confirmed')
      if (zipCodeIsServiced === true) {
        setButtonName('Use Confirmed Address')
      } else if (zipCodeIsServiced === false) {
        setButtonName('Zip Code is not serviceable')
      }
    } else if (hasUnspecifiedFields) {
      setAddressFilled(false)
      setWarningText(
        `We do not understand the address you typed. Please fill out the fields and try again.`,
      )
      setButtonName('Revise and Check Address')
    }
  }

  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box className={styles.addressModal}>
          <div className={`${styles.scheduleModalPageTitle} font--bold`}>
            Unvalidated Address
          </div>
          <div>{warningText}</div>
          <div className={styles.contactFormContainer}>
            <div className={styles.addressFieldContainer}>
              <TextField
                className={styles.textfieldStyles}
                autoComplete='off'
                value={installationPoint?.address}
                type='text'
                name='address'
                label='Address'
                sx={{
                  borderRadius: 1,
                  backgroundColor:
                    STATUSES[confirmationLevel.address].backgroundColor,
                }}
                onChange={updateInstallationPoint}
                InputLabelProps={{
                  shrink: Boolean(installationPoint?.address), // Automatically shrink the label when value is not empty
                }}
              />
              <TextField
                className={styles.textfieldStyles}
                autoComplete='off'
                value={installationPoint?.aptOrSuite}
                type='text'
                label='Apt # or Suite'
                name='aptOrSuite'
                onChange={updateInstallationPoint}
                InputLabelProps={{
                  shrink: Boolean(installationPoint?.aptOrSuite), // Automatically shrink the label when value is not empty
                }}
              />
              <TextField
                className={styles.textfieldStyles}
                autoComplete='off'
                value={installationPoint?.city}
                type='text'
                name='city'
                label='City'
                sx={{
                  borderRadius: 1,
                  backgroundColor:
                    STATUSES[confirmationLevel.city].backgroundColor,
                }}
                onChange={updateInstallationPoint}
                InputLabelProps={{
                  shrink: Boolean(installationPoint?.city), // Automatically shrink the label when value is not empty
                }}
              />
              <div className={styles.zipStateContainer}>
                <TextField
                  value={installationPoint?.state}
                  autoComplete='off'
                  type='text'
                  name='state'
                  label='State'
                  sx={{
                    borderRadius: 1,
                    backgroundColor:
                      STATUSES[confirmationLevel.state].backgroundColor,
                  }}
                  onChange={updateInstallationPoint}
                  InputLabelProps={{
                    shrink: Boolean(installationPoint?.state), // Automatically shrink the label when value is not empty
                  }}
                />
                <TextField
                  type='tel'
                  autoComplete='off'
                  value={installationPoint?.zipCode}
                  name='zipCode'
                  label='Zip'
                  sx={{
                    width: 113,
                    borderRadius: 1,
                    backgroundColor:
                      STATUSES[confirmationLevel.zipCode].backgroundColor,
                  }}
                  onChange={updateInstallationPoint}
                  InputLabelProps={{
                    shrink: Boolean(installationPoint?.zipCode), // Automatically shrink the label when value is not empty
                  }}
                />
              </div>
            </div>
            <div>
              <TextField
                sx={{ width: 300, height: '100%' }}
                InputLabelProps={{ shrink: true }}
                multiline
                rows={8}
                label={`Address Note`}
                placeholder='Add clarifying comment: Address is near the intersection of Main St and 1st Ave'
                value={unvalidatedAddressNote}
                onChange={(e) => {
                  setUnvalidatedAddressNote(e.target.value)
                }}
              />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <PrimaryButton buttonName='Cancel' onClick={handleClose} />
            <div className={styles.flexGroupButtons}>
              <>
                <SecondaryButton
                  buttonName='Check Address'
                  onClick={handleRevalidateAddress}
                />
                <PrimaryButton
                  disabled={buttonDisabled}
                  buttonName={buttonName}
                  onClick={handleStart}
                />
              </>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
