import GoogleAddressSearchBar from '../../../../../global/components/google-address-search/GoogleAddressSearchBar'
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import styles from './styles.module.css'
import { OrderContext } from '../../../../../global/context/order-context/OrderContext'
import SecondaryButton from '../../../../../global/components/buttons/secondary-button/SecondaryButton'
import PrimaryButton from '../../../../../global/components/buttons/primary-button/PrimaryButton'
import createInstallationPointFromChosenAddress from '../../utils/create-installation-point-from-chosen-address'
import { InstallationPoint } from '../../../../../../../app/entities/InstallationPoint'

export interface IEditAddressProps {
  chosenAddress: google.maps.GeocoderResult | null | undefined
  isZipCodeServiced: boolean | undefined
  setChosenAddress: Dispatch<SetStateAction<google.maps.GeocoderResult | null>>
  setIsZipCodeServiced: Dispatch<SetStateAction<boolean | undefined>>
  setUseUnvalidatedAddress: Dispatch<SetStateAction<boolean>>

  // only used for existing orders
  handleClose?: MouseEventHandler

  // only used in the FINISH flow (when EditAddress is not being
  // used in a modal)
  handleNextClicked?: MouseEventHandler
}

const PRIMARY_BUTTON_LABELS = {
  START: 'Start typing to select an address',
  CHECKING: 'Checking zip code...',
  NOT_SERVICED: 'Zip code not serviced',
  UPDATE: 'Update Address',
}

function EditAddress({
  chosenAddress,
  isZipCodeServiced,
  setChosenAddress,
  setIsZipCodeServiced,
  setUseUnvalidatedAddress,

  handleClose,
}: IEditAddressProps): JSX.Element {
  const { orderData, setOrderData } = useContext(OrderContext)
  const { location: orderLocation } = orderData

  const [notValidAddress, setNotValidAddress] =
    useState<boolean>(getNotValidAddress)
  const [primaryButtonName, setPrimaryButtonName] =
    useState<string>(getPrimaryButtonName)
  const [iFrameScrUrl, setIFrameScrUrl] = useState<string>('')

  useEffect(() => {
    if (chosenAddress) {
      setIFrameScrUrl(
        `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=place_id:${chosenAddress.place_id}`,
      )
    } else {
      setIFrameScrUrl('')
    }
  }, [chosenAddress])

  function getNotValidAddress() {
    return !chosenAddress || !isZipCodeServiced
  }
  useEffect(() => {
    setNotValidAddress(getNotValidAddress())
  }, [chosenAddress, isZipCodeServiced])

  function getPrimaryButtonName() {
    if (!chosenAddress) return PRIMARY_BUTTON_LABELS.START
    if (isZipCodeServiced === undefined) return PRIMARY_BUTTON_LABELS.CHECKING
    if (!isZipCodeServiced) return PRIMARY_BUTTON_LABELS.NOT_SERVICED
    return PRIMARY_BUTTON_LABELS.UPDATE
  }
  useEffect(() => {
    setPrimaryButtonName(getPrimaryButtonName())
  }, [chosenAddress, isZipCodeServiced])

  async function handleEditAddress() {
    try {
      const installationPointValuesFromGoogle =
        await createInstallationPointFromChosenAddress(
          chosenAddress,
          orderLocation,
        )

      setOrderData({
        installationPoint: {
          ...installationPointValuesFromGoogle,
        } as InstallationPoint,
      })
    } catch (error: any) {
      alert(
        'Something is wrong with the address. Please try a variation of the address instead.',
      )
      throw new Error(error)
    } finally {
      if (handleClose) {
        handleClose({} as any)
      }
    }
    return
  }

  return (
    <div className={styles.editAddressContainer}>
      <GoogleAddressSearchBar
        isZipCodeServiced={isZipCodeServiced}
        setIsZipCodeServiced={setIsZipCodeServiced}
        activeLocation={orderLocation}
        setUseUnvalidatedAddress={setUseUnvalidatedAddress}
        setChosenAddress={setChosenAddress}
      />
      <div className={styles.chosenAddressIFrame}>
        <iframe
          width='500'
          height='300'
          style={{ border: 0 }}
          referrerPolicy='no-referrer-when-downgrade'
          src={iFrameScrUrl}
        />
      </div>

      {handleClose && (
        <div className={styles.buttonContainer}>
          <SecondaryButton buttonName='Cancel' onClick={handleClose} />
          <PrimaryButton
            buttonName={primaryButtonName}
            disabled={notValidAddress}
            onClick={handleEditAddress}
          />
        </div>
      )}
    </div>
  )
}

export default EditAddress
