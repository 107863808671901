import { ChangeEvent, useContext, useState } from 'react'
import TextField from '@mui/material/TextField'
import styles from './styles.module.css'
import {
  isTenDigitPhoneNumber,
  isValidEmail,
} from '../../../../../../global/utils/formValidation'
import {
  Customer,
  ICustomer,
} from '../../../../../../../../app/entities/Customer'
import { OrderContext } from '../../../../../../global/context/order-context/OrderContext'

export default function ContactForm() {
  const { orderData, setOrderData } = useContext(OrderContext)
  const { customer } = orderData
  const [error, setError] = useState({
    email: false,
    phoneNumber: false,
  })

  function updateCustomerForm(e: ChangeEvent<HTMLInputElement>) {
    let { name, value } = e.target

    const updatedCustomer = { ...orderData.customer, [name]: value }

    if (name === 'email') {
      setError((prevState) => ({
        ...prevState,
        email: !isValidEmail(value),
      }))
    } else if (name === 'phoneNumber') {
      const formattedValue = value.replace(/\D/g, '').substring(0, 10)
      updatedCustomer.phoneNumber = formattedValue

      setError((prevState) => ({
        ...prevState,
        phoneNumber: !isTenDigitPhoneNumber(formattedValue),
      }))
    }

    setOrderData({ customer: updatedCustomer as Customer })
  }

  return (
    <div className={styles.contactFormContainer}>
      <div className={`${styles.contactTitle} font--bold`}>Contact</div>
      <TextField
        className={styles.textfieldStyles}
        type='text'
        label='First Name'
        name='firstName'
        value={customer?.firstName ?? ''}
        onChange={updateCustomerForm}
      />
      <TextField
        className={styles.textfieldStyles}
        type='text'
        label='Last Name'
        name='lastName'
        value={customer?.lastName ?? ''}
        onChange={updateCustomerForm}
      />
      <TextField
        className={styles.textfieldStyles}
        type='tel'
        label='Phone'
        name='phoneNumber'
        onChange={updateCustomerForm}
        value={customer?.phoneNumber ?? ''}
        error={error.phoneNumber}
        helperText={
          error.phoneNumber ? 'Please enter a valid phone number' : ''
        }
      />
      <TextField
        className={styles.textfieldStyles}
        type='email'
        label='Email'
        name='email'
        value={customer?.email ?? ''}
        onChange={updateCustomerForm}
        error={error.email}
        helperText={error.email ? 'Please enter a valid email address' : ''}
      />
    </div>
  )
}
