import { useEffect } from 'react'
import useFetch from '../../global/hooks/useFetchHook'
import { getRoleByName } from '../api'
import CircularProgress from '@mui/material/CircularProgress'
import { IRole } from '../../../../app/entities/Role'
import RoleCreate from './Create/RoleCreate'

interface IRoleEditProps {
  roleName: string
  roleData: Partial<IRole>
  setRoleData: Function
}

function RoleEdit({ roleName, roleData, setRoleData }: IRoleEditProps) {
  const { data: role, isLoading } = useFetch<IRole>(getRoleByName(roleName))

  useEffect(() => {
    if (role) {
      setRoleData(role)
    }
  }, [role])

  if (isLoading && !role) {
    return <CircularProgress />
  }
  return <RoleCreate roleData={roleData} setRoleData={setRoleData} />
}

export default RoleEdit
