import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import styles from './styles.module.css'
import Portal from '@mui/material/Portal'
interface IToastNotification {
  severity: 'error' | 'info' | 'success' | 'warning'
  open: boolean
  handleClose: any
  message: string
}
export interface IAlertStatusProps {
  isOpen: boolean
  message: string
  severity: 'error' | 'info' | 'success' | 'warning'
}

export default function ToastNotification(props: IToastNotification) {
  const { severity, open, handleClose, message } = props

  return (
    <Portal>
      <div style={{ width: '100%' }}>
        <Snackbar
          style={{ top: '40px' }}
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert
            severity={severity}
            variant="filled"
            onClose={handleClose}
            className={styles.alert}>
            {message}
          </Alert>
        </Snackbar>
      </div>
    </Portal>
  )
}
