import React, { createContext, useState } from 'react'
import { IAlertStatusProps } from '../../components/toast-alert/ToastNotification'

interface IToastContextProps {
  handleClose: Function
  setToastStatus: Function
  toastStatus: IAlertStatusProps
}
interface IProviderProps {
  children: React.ReactNode
}
export const ToastNotificationContext = createContext<IToastContextProps>(
  {} as IToastContextProps,
)

export const ToastNotificationContextProvider = ({
  children,
}: IProviderProps) => {
  const [toastStatus, setToastStatus] = useState<IAlertStatusProps>({
    message: '',
    severity: 'success',
    isOpen: false,
  })
  function handleClose() {
    setToastStatus({ message: '', severity: 'success', isOpen: false })
  }

  return (
    <ToastNotificationContext.Provider
      value={{
        handleClose,
        toastStatus,
        setToastStatus,
      }}
    >
      {children}
    </ToastNotificationContext.Provider>
  )
}
