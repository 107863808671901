import { IInstallationPointPartial } from '../../../../../app/entities/InstallationPoint'
import removeDoubledCommas from '../remove-doubled-commas'

// Used to get the full address of an installation point when it hasn't been
// saved to the database yet
function calculateFullAddress(
  installationPoint?: IInstallationPointPartial,
): string {
  const { address, aptOrSuite, city, state, zipCode } = installationPoint ?? {}

  let streetAddress = ''
  const hasJustAddress = address && !aptOrSuite
  const hasAddressAndAptOrSuite = address && aptOrSuite
  if (hasJustAddress) {
    streetAddress = `${address},`
  } else if (hasAddressAndAptOrSuite) {
    streetAddress = `${address} ${aptOrSuite},`
  }

  return removeDoubledCommas(
    [
      streetAddress,
      city ? ` ${city},` : '',
      ` ${state}`,
      zipCode ? ` ${zipCode}` : '',
    ].join(''),
  ).trim()
}

export default calculateFullAddress
