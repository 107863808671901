import { Link, useLocation, useNavigate } from 'react-router-dom'
import styles from './styles.module.css'
import { useEffect } from 'react'
import PrimaryButton from '../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../global/components/buttons/secondary-button/SecondaryButton'

const lastUserManagementPathKey = 'lastUserManagementPath'
const BASE_PATH = '/user-management'
const PATHS = {
  USERS: {
    NAME: 'Users',
    PATH: `${BASE_PATH}/users`,
  },
  ROLES: {
    NAME: 'Roles',
    PATH: `${BASE_PATH}/roles`,
  },
}
const DEFAULT_PATH = PATHS.USERS.PATH

function QueueChooser() {
  const navigate = useNavigate()

  const links = [
    {
      name: PATHS.USERS.NAME,
      path: PATHS.USERS.PATH,
    },
    {
      name: PATHS.ROLES.NAME,
      path: PATHS.ROLES.PATH,
    },
  ]

  const activePath = useLocation().pathname

  const noActivePath = activePath === BASE_PATH

  useEffect(() => {
    if (noActivePath) {
      const lastQueuePath = localStorage.getItem(lastUserManagementPathKey)

      navigate(lastQueuePath || DEFAULT_PATH)
    } else {
      const pathToSave =
        links.find((link) => link.path === activePath)?.path || DEFAULT_PATH

      localStorage.setItem(lastUserManagementPathKey, pathToSave)
    }
  }, [noActivePath, navigate])

  return (
    <div className={styles.buttonsHolder}>
      {links.map((link, idx) =>
        link.path === activePath ? (
          <Link className={styles.link} to={link.path} key={idx}>
            <PrimaryButton
              buttonName={link.name}
              onClick={() => {}}
              style={{ width: 'max-content' }}
            />
          </Link>
        ) : (
          <Link className={styles.link} to={link.path} key={idx}>
            <SecondaryButton
              buttonName={link.name}
              onClick={() => {}}
              style={{ width: 'max-content' }}
            />
          </Link>
        ),
      )}
    </div>
  )
}

export default QueueChooser
