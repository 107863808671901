import MainPageWrapper from '../../global/components/MainPageWrapper'
import TransitionAvailabilityRecordsFromVanToLocation from './TransitionAvailabilityRecordsFromVanToLocation'

import styles from './styles.module.css'

export default function JmulletTools() {
  return (
    <MainPageWrapper>
      <h1>jmullet tools</h1>
      <div className={styles.toolsHolder}>
        <TransitionAvailabilityRecordsFromVanToLocation />
      </div>
    </MainPageWrapper>
  )
}
