import { useContext } from 'react'
import { ITiresCombinationWithPartialTire } from '../../../../../global/constants/scheduler'
import styles from './styles.module.css'
import { OrderContext } from '../../../../../global/context/order-context/OrderContext'
import { OrderService } from '../../../../../../../app/entities/OrderService'
import { SERVICE_NAMES } from '../../../../../global/constants/service'

interface IOrderSummaryProps {
  isRework: boolean
  overrideTireDisposalCount?: number
}

const { TIRE_DISPOSAL } = SERVICE_NAMES

export default function OrderSummary({
  isRework,
  overrideTireDisposalCount,
}: IOrderSummaryProps) {
  const { orderData, tiresCombinationData: tiresCombination } =
    useContext(OrderContext)

  const tireDisposalCount =
    orderData?.orderVehicles[0]?.orderServices?.find(
      (orderService: OrderService) => orderService.label === TIRE_DISPOSAL,
    )?.quantity ?? overrideTireDisposalCount

  const tire = formatTireSize(tiresCombination ?? {})

  function formatTireSize(item: Partial<ITiresCombinationWithPartialTire>) {
    const { frontTire, rearTire } = item
    return {
      frontSize: `${frontTire.width} | ${frontTire.aspectRatio} R${frontTire.diameter}`,
      rearSize: `${rearTire.width} | ${rearTire.aspectRatio} R${rearTire.diameter}`,
    }
  }

  return (
    <div className={styles.orderSummaryContainer}>
      <div className={styles.orderSummaryLabel}>Order Summary</div>
      <div className={styles.breakdownsContainer}>
        <div className={styles.serviceAndTireSummariesContainer}>
          <div className={styles.serviceSummaryContainer}>
            <div className={styles.serviceSummaryLabel}>Service Summary</div>
            <div className={styles.tireInstallationLabel}>
              {isRework ? 'Rework' : 'Tire Installation'}
            </div>
          </div>
          <div className={styles.tireSummaryContainer}>
            <div className={styles.tireSummaryLabel}>Tire Summary</div>
            <div className={styles.servicesContainer}>
              <div className={styles.line}></div>
              <div className={styles.serviceContainer}>
                <div className={styles.tireSizeLabel}>Front Tire: </div>
                <div className={styles.tireSizeLabel}>{tire.frontSize}</div>
                <div className={styles.tireSizeLabel}>
                  {tiresCombination?.frontCount}
                </div>
              </div>
              <div className={styles.line}></div>
              <div className={styles.serviceContainer}>
                <div className={styles.tireSizeLabel}>Rear Tire: </div>
                <div className={styles.tireSizeLabel}>{tire.rearSize}</div>
                <div className={styles.tireSizeLabel}>
                  {tiresCombination?.rearCount}
                </div>
              </div>
              {tireDisposalCount ? (
                <>
                  <div className={styles.line}></div>
                  <div className={styles.serviceContainer}>
                    <div className={styles.tireSizeLabel}>Tire Disposal: </div>
                    <div className={styles.tireSizeLabel}></div>
                    <div className={styles.tireSizeLabel}>
                      {tireDisposalCount}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
