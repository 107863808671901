import { useEffect } from 'react'
import useFetch from '../../global/hooks/useFetchHook'
import { getSingleUserData } from '../api'
import CircularProgress from '@mui/material/CircularProgress'
import UserCreate from './UserCreate'
import { IUser } from '../../../../app/entities/User'
interface IUserEditProps {
  email: string
  userData: Partial<IUser>
  setUserData: Function
  formError: boolean
  setFormError: Function
}
export default function UserEdit({
  email,
  userData,
  setUserData,
  formError,
  setFormError,
}: IUserEditProps) {
  const { data: user, isLoading } = useFetch<IUser>(getSingleUserData(email))

  useEffect(() => {
    if (user) {
      setUserData(user)
    }
  }, [user])

  if (isLoading && !user) {
    return <CircularProgress />
  }
  return (
    <UserCreate
      userData={userData}
      setUserData={setUserData}
      formError={formError}
      setFormError={setFormError}
    />
  )
}
