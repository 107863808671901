import 'react-big-calendar/lib/css/react-big-calendar.css'
import {
  Calendar,
  DayPropGetter,
  EventPropGetter,
  luxonLocalizer,
} from 'react-big-calendar'
import { DateTime } from 'luxon'
import CalendarEvent from './custom/CalendarEvent'
import CustomToolbar from './custom/CustomToolbar'
import { convertMsDateToString } from '../../../global/utils/dates'
import statuses from '../../../global/constants/statuses.json'

const localizer = luxonLocalizer(DateTime)

export interface IEvent {
  title: {
    primary: string
    secondary: string
  }
  start: Date
  end: Date
  resource: string
  onClick?: () => void
}
interface IAppointmentCalendarProps {
  setView: Function
  scheduleFilter: {
    date: string
    locationId: string
  }
  setScheduleFilter: Function
  calendarEvents: IEvent[]
  refetchCalendarEvents: Function
  refetchDaySettings: Function
  isCalendarEventsLoading: boolean
  onOpenLocationModal: Function
}
const AppointmentCalendar = ({
  setView,
  scheduleFilter,
  setScheduleFilter,
  calendarEvents,
  refetchCalendarEvents,
  refetchDaySettings,
  isCalendarEventsLoading,
  onOpenLocationModal,
}: IAppointmentCalendarProps) => {
  const onDrillDown = (date: Date) => {
    setView('day')
    setScheduleFilter({
      ...scheduleFilter,
      date: convertMsDateToString(date.getTime()),
    })
    localStorage.setItem(
      'selectedCalendarDate',
      convertMsDateToString(date.getTime()),
    )
  }

  const eventPropGetter: EventPropGetter<IEvent> = (
    event,
    start,
    end,
    isSelected,
  ) => {
    const status = (statuses as any)[event.resource] || { statusColor: '#FFF' }

    const backgroundColor = status.backgroundColor
    const border = `1px solid ${status.statusColor}`
    const fontColor = status.fontColor || '#000'

    return {
      className: event.resource,
      style: {
        backgroundColor,
        border,
        color: fontColor,
      },
    }
  }

  const dayPropGetter: DayPropGetter = (date) => {
    if (new Date().toDateString() === date.toDateString()) {
      return { style: { backgroundColor: '#ffe785' } }
    }
    return {}
  }

  const onNavigate = (date: Date) => {
    setScheduleFilter({
      ...scheduleFilter,
      date: convertMsDateToString(date.getTime()),
    })
    localStorage.setItem(
      'selectedCalendarDate',
      convertMsDateToString(date.getTime()),
    )
    refetchCalendarEvents()
    refetchDaySettings()
  }

  return (
    <Calendar
      localizer={localizer}
      events={calendarEvents}
      startAccessor='start'
      endAccessor='end'
      defaultDate={new Date(scheduleFilter.date || new Date())}
      style={{
        height: 800,
        backgroundColor: isCalendarEventsLoading ? '#f5f5f5' : 'white',
      }}
      views={{
        day: false,
        week: false,
        month: true,
      }}
      components={{
        dateCellWrapper: (props) => {
          return (
            <div
              onClick={() => {
                onDrillDown(props.value)
              }}
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                border: '1px solid #e0e0e0',
                borderLeft: 'none',
                borderTop: 'none',
              }}
            >
              {props.children}
            </div>
          )
        },
        event: CalendarEvent,
        toolbar: (toolbarProps) => {
          return (
            <CustomToolbar
              {...toolbarProps}
              scheduleFilter={scheduleFilter}
              setScheduleFilter={setScheduleFilter}
              onOpenLocationModal={onOpenLocationModal}
            />
          )
        },
      }}
      onDrillDown={onDrillDown}
      dayPropGetter={dayPropGetter}
      eventPropGetter={eventPropGetter}
      onNavigate={onNavigate}
    />
  )
}

export default AppointmentCalendar
