import { useState, useContext } from 'react'
import WODetailsModal from '../sub-modals/WODetailsSubModal'
import styles from './styles.module.css'
import { OrderContext } from '../../../../../global/context/order-context/OrderContext'

interface IAdditionalActionsProps {
  isReadOnly: boolean
}

export default function AdditionalActions({
  isReadOnly,
}: IAdditionalActionsProps) {
  const { isOrderInEditableStatus, orderData } = useContext(OrderContext)

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalName, setModalName] = useState<string>('')

  const areActionsDisabled = isReadOnly || !isOrderInEditableStatus

  return (
    <div>
      <div className={styles.heading}>Additional Actions</div>
      <WODetailsModal
        isOpen={modalOpen}
        handleClosed={() => {
          setModalOpen(false)
          setModalName('')
        }}
        actionName={modalName}
      />
      <div className={styles.container}>
        <button
          className={styles.additionalActions}
          onClick={(e) =>
            ((e: any) => {
              setModalName(e.target.innerText)
              setModalOpen(true)
            })(e)
          }
          disabled={areActionsDisabled}
        >
          Complete Work Order
        </button>
        <button
          className={styles.additionalActions}
          disabled={areActionsDisabled}
          onClick={(e) =>
            ((e: any) => {
              setModalName(e.target.innerText)
              setModalOpen(true)
            })(e)
          }
        >
          Cancel Order
        </button>
        <button
          className={styles.additionalActions}
          onClick={(e) =>
            ((e: any) => {
              setModalName(e.target.innerText)
              setModalOpen(true)
            })(e)
          }
          disabled={isReadOnly}
        >
          Clone Order
        </button>
        <button
          className={styles.additionalActions}
          onClick={(e) =>
            ((e: any) => {
              setModalName(e.target.innerText)
              setModalOpen(true)
            })(e)
          }
          disabled={isReadOnly}
        >
          Installation Images
        </button>
      </div>
    </div>
  )
}
