import { InstallationPoint } from '../../../../../../app/entities/InstallationPoint'
import {
  GEOCODE_ZIP_CODE,
  GEOCODE_STATE,
  GEOCODE_CITY,
  GEOCODE_STREET_ADDRESS,
  GEOCODE_STREET_NUMBER,
  GEOCODE_STREET_NAME,
  GEOCODE_NON_STANDARD_ADDRESS,
  CONFIRMATION_LEVEL_KEYS,
} from '../../../constants/google-maps-address'
import {
  googleMapsAddressValidation,
  IConfirmationLevel,
} from '../../../types/google-maps-address'

interface InstallationPointWithConfirmation {
  installationPoint: Partial<InstallationPoint>
  confirmationLevel: IConfirmationLevel
}

function findAddressComponentMatchingFirstTypeAddressValidation(
  addressComponent: googleMapsAddressValidation.AddressValidationAddressComponent[],
  geocodePlaceTypesArray: string[],
): googleMapsAddressValidation.AddressValidationAddressComponent | undefined {
  for (const type of geocodePlaceTypesArray) {
    for (const component of addressComponent) {
      if (component.componentType === type) {
        return component
      }
    }
  }
}

function createInstallationPointFromAddressValidation(
  addressComponent: googleMapsAddressValidation.AddressValidationAddressComponent[],
): InstallationPointWithConfirmation {
  const zipCodeComponent =
    findAddressComponentMatchingFirstTypeAddressValidation(
      addressComponent,
      GEOCODE_ZIP_CODE,
    )
  const stateComponent = findAddressComponentMatchingFirstTypeAddressValidation(
    addressComponent,
    GEOCODE_STATE,
  )
  const cityComponent = findAddressComponentMatchingFirstTypeAddressValidation(
    addressComponent,
    GEOCODE_CITY,
  )
  let streetAddressComponent =
    findAddressComponentMatchingFirstTypeAddressValidation(
      addressComponent,
      GEOCODE_STREET_ADDRESS,
    )
  const streetNumberComponent =
    findAddressComponentMatchingFirstTypeAddressValidation(
      addressComponent,
      GEOCODE_STREET_NUMBER,
    )
  const streetNameComponent =
    findAddressComponentMatchingFirstTypeAddressValidation(
      addressComponent,
      GEOCODE_STREET_NAME,
    )
  const nonStandardAddressComponent =
    findAddressComponentMatchingFirstTypeAddressValidation(
      addressComponent,
      GEOCODE_NON_STANDARD_ADDRESS,
    )

  let streetAddress: string = streetAddressComponent?.componentName?.text ?? ''
  const streetNumber: string = streetNumberComponent?.componentName?.text ?? ''
  const streetName: string = streetNameComponent?.componentName?.text ?? ''
  const nonStandardAddress: string =
    nonStandardAddressComponent?.componentName?.text ?? ''
  const zipCode: string = zipCodeComponent?.componentName?.text ?? ''
  const state: string = stateComponent?.componentName?.text ?? ''
  const city: string = cityComponent?.componentName?.text ?? ''

  const confirmationLevel = {
    address:
      streetAddressComponent?.confirmationLevel ||
      CONFIRMATION_LEVEL_KEYS.CONFIRMATION_LEVEL_UNSPECIFIED,
    city:
      cityComponent?.confirmationLevel ||
      CONFIRMATION_LEVEL_KEYS.CONFIRMATION_LEVEL_UNSPECIFIED,

    state:
      stateComponent?.confirmationLevel ||
      CONFIRMATION_LEVEL_KEYS.CONFIRMATION_LEVEL_UNSPECIFIED,

    zipCode:
      zipCodeComponent?.confirmationLevel ||
      CONFIRMATION_LEVEL_KEYS.CONFIRMATION_LEVEL_UNSPECIFIED,
  }

  if (streetAddress.length === 0) {
    if (streetNumber.length === 0 || streetName.length === 0) {
      //google maps can still find nonStandard addresses, but we would like to not use them if we can help it
      streetAddress = nonStandardAddress
      confirmationLevel.address =
        nonStandardAddressComponent?.confirmationLevel ??
        CONFIRMATION_LEVEL_KEYS.CONFIRMATION_LEVEL_UNSPECIFIED
    }
    streetAddress = `${streetNumber} ${streetName}`
    confirmationLevel.address =
      streetNumberComponent?.confirmationLevel ??
      CONFIRMATION_LEVEL_KEYS.CONFIRMATION_LEVEL_UNSPECIFIED
  }

  const installationPoint: Partial<InstallationPoint> = {
    zipCode: zipCode,
    state: state,
    city: city,
    address: streetAddress,
  }
  return { installationPoint, confirmationLevel }
}

export default createInstallationPointFromAddressValidation
