import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { ITrOrderNumbersProps } from '../tables/overview-summary/OrderActions'
import CancelOrder from './modal-content/cancel-order/CancelOrder'
import ChangeAddress from './modal-content/change-address/ChangeAddress'
import CompleteWorkOrder from './modal-content/completed-work-order/CompleteWorkOrder'
import EditVehicleModal from './modal-content/edit-vehicle/EditVehicleModal'
import UpdateBtToken from './modal-content/update-braintree-token/UpdateBtToken'
import styles from './styles.module.css'
import CloneOrder from './modal-content/clone-order/CloneOrder'
import ViewInstallationImages from './modal-content/view-installation-images/ViewInstallationImages'
import { useEffect } from 'react'

interface IWODetailModalProps {
  isOpen: boolean
  handleClosed: Function
  actionName: string
  date?: string
  triggerRerender?: () => void
}
export default function WODetailsSubModal(props: IWODetailModalProps) {
  const { actionName, handleClosed, isOpen } = props
  function whichModalContent(actionName: string) {
    switch (actionName) {
      case 'Complete Work Order':
        return <CompleteWorkOrder handleClose={handleClosed} />
      case 'Change Address':
        return <ChangeAddress handleClose={handleClosed} />
      case 'Update Braintree Token':
        return <UpdateBtToken handleClose={handleClosed} />
      case 'Cancel Order':
        return <CancelOrder handleClose={handleClosed} />
      case 'Clone Order':
        return <CloneOrder handleClose={handleClosed} />
      case 'Installation Images':
        return <ViewInstallationImages handleClose={handleClosed} />
      case 'Edit Order: Vehicle':
        return (
          <EditVehicleModal
            handleClose={handleClosed}
            triggerRerender={props.triggerRerender}
          />
        )
    }
  }

  return (
    <div>
      <Modal open={isOpen} onClose={() => handleClosed()}>
        <Box className={styles.scheduleModal}>
          <div className={`${styles.scheduleModalPageTitle} font--bold`}>
            {actionName === 'Assign'
              ? 'Assign TR Order Number'
              : actionName === 'Save'
              ? 'Assign Additional TR Number'
              : actionName}
          </div>
          {whichModalContent(actionName)}
        </Box>
      </Modal>
    </div>
  )
}
