import { useContext, useEffect, useState } from 'react'
import { Column } from 'react-table'
import { IOrderHistory } from '../../../../../../../../app/entities/OrderHistory'
import Table from '../../../../../../global/components/table/Table'
import TableTitle from '../../../../../../global/components/table/helpers/table-title/TableTitle'
import TableTopper from '../../../../../../global/components/table/helpers/table-topper/TableTopper'
import { fetchData } from '../../../../../../global/utils/fetch'
import { getOrderHistoryById } from '../../../../api'
import { OrderContext } from '../../../../../../global/context/order-context/OrderContext'
interface IWorkOrderHistoryTableStateProps {
  data: IOrderHistory[]
  isLoading: boolean
}

interface IWorkOrderHistoryTableProps {}

export default function WorkOrderHistoryEntriesTable({}: IWorkOrderHistoryTableProps) {
  const { orderData } = useContext(OrderContext)
  const orderObjectId = orderData.objectId

  const [tableState, setTableState] =
    useState<IWorkOrderHistoryTableStateProps>({
      data: [],
      isLoading: false,
    })

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const fetchTableData = async () => {
      setTableState((prevState) => ({
        ...prevState,
        isLoading: true,
      }))
      const data = await fetchData<{
        items: IOrderHistory[]
        count: number
      }>(getOrderHistoryById(orderObjectId))
      setTableState((prevState) => ({
        ...prevState,
        data: data.items,
        isLoading: false,
      }))
    }

    if (isOpen) {
      fetchTableData()
    }
  }, [isOpen, orderObjectId])

  const columns: Column[] = [
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'When',
      accessor: 'createdGeneratedEst',
    },
  ]

  const sortedWorkOrderHistory = tableState.data.sort(
    (a, b) => b.created - a.created,
  )

  return (
    <div style={{ width: '100%' }}>
      <TableTopper isOpen={isOpen} onToggleClick={() => setIsOpen(!isOpen)}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <TableTitle>Work Order History Entries </TableTitle>
        </div>
      </TableTopper>
      <Table
        columns={columns}
        data={sortedWorkOrderHistory as any}
        isLoading={tableState.isLoading}
        isOpen={isOpen}
        isDisplayMode={true}
        disableSortBy={true}
      />
    </div>
  )
}
