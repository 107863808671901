/*
  EQUIVALENT FUNCTION EXISTS ON THE FRONTEND AT app/eworkflows/location/calculate-work-day-minutes.ts

  Any changes to this function should be made in the frontend equivalent
*/

function calculateWorkDayMinutes(
  workDayFrom: string,
  workDayTo: string,
): number {
  const [
    [workDayFromHours, workDayFromMinutes],
    [workDayToHours, workDayToMinutes],
  ] = [workDayFrom, workDayTo].map((time) => time.split(':').map(Number))

  const workDayDurationInMinutes =
    (workDayToHours - workDayFromHours) * 60 +
    workDayToMinutes -
    workDayFromMinutes

  return workDayDurationInMinutes
}

export default calculateWorkDayMinutes
