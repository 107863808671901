import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { IGetOrderDataByIdResponse } from '../../../../../../../global/context/order-context/OrderContext'
//on component load, it checks whether the finish state flag is present in the routers context, if so it will open the finish flow schedule modal. This logic is specifically for when user clones order and redirects to newlyCloned order
export function useNavigationEffect(
  data: IGetOrderDataByIdResponse,
  finishTrOrderHandler: Function,
) {
  const location = useLocation()
  const navigate = useNavigate()
  const finish = location.state?.finish

  useEffect(() => {
    if (data && finish) {
      finishTrOrderHandler()
      navigate(location.pathname, { state: null })
    }
  }, [location, data])
}
