import { useContext } from 'react'
import { UserContext } from '../../../base/components/base-container/BaseContainer'
import styles from './styles.module.css'
import determineIfUserCanEdit from '../../../locations/components/location-modal/helper-functions/determine-if-user-can-edit'
import { PERMISSIONS } from '../../../global/constants/permissions'
import userHasPermission from '../../../global/utils/user/has-permission'

interface LocationAddEditButtonsHolderProps {
  onOpenLocationModal: Function
  selectedLocationObjectId?: string
}

const { SCHEDULE_VIEW } = PERMISSIONS

export default function LocationAddEditButtonsHolder({
  onOpenLocationModal,
  selectedLocationObjectId,
}: LocationAddEditButtonsHolderProps) {
  const { user } = useContext(UserContext)
  const { locations } = user || {}
  const chosenLocation = locations?.find(
    (location) => location.objectId === selectedLocationObjectId,
  )
  const canSeeAddButton = userHasPermission(user).allow(
    SCHEDULE_VIEW.CATEGORY,
    SCHEDULE_VIEW.ENTRIES.ADD_LOCATION_BUTTON.NAME,
  )
  const editOrViewButton = determineIfUserCanEdit() ? 'Edit' : 'View'

  return (
    <div className={styles.locationButtonsHolder}>
      <button
        className={`${styles.locationAddButton} ${styles.locationButton}`}
        onClick={() => onOpenLocationModal()}
        style={{ display: canSeeAddButton ? 'flex' : 'none' }}
      >
        Create Location
      </button>
      <button
        className={`${styles.locationEditButton} ${styles.locationButton}`}
        onClick={() => onOpenLocationModal(selectedLocationObjectId)}
        style={{ display: 'flex' }}
      >
        {editOrViewButton} {chosenLocation?.name || 'Location'}
      </button>
    </div>
  )
}
