import { IBrand } from '../../../../app/entities/Brand'
import { ICustomer } from '../../../../app/entities/Customer'
import { ICustomerVehicle } from '../../../../app/entities/CustomerVehicle'
import { IInstallationPoint } from '../../../../app/entities/InstallationPoint'
import { IOrder } from '../../../../app/entities/Order'
import { Tire } from '../../../../app/entities/Tire'
import { TiresCombination } from '../../../../app/entities/TiresCombination'

export interface ITiresCombinationWithPartialTire
  extends Omit<TiresCombination, 'frontTire | rearTire'> {
  frontTire: Partial<Tire> | any
  rearTire: Partial<Tire> | any
}

export interface ICustomerVehicleWithPartialBrand
  extends Omit<ICustomerVehicle, 'brand'> {
  brand: Partial<IBrand> | any
}

const FORWARD_BUTTON_LABELS = {
  CONFIRM: 'Confirm',
  DONE: 'Done',
  NEXT: 'Next',
  SAVE: 'Save',
}

const initialCustomerInfoProps: Partial<ICustomer> = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
}

const initialCustomerVehicleProps: Partial<ICustomerVehicleWithPartialBrand> = {
  brand: {
    name: undefined,
    objectId: undefined,
  },
  model: '',
  year: '',
  variant: '',
}

const initialInstallationPointProps: Partial<IInstallationPoint> = {
  city: '',
  state: '',
  zipCode: '',
  address: '',
  aptOrSuite: '',
  drivingDetails: undefined,
}

const initialOrderProps: Partial<IOrder> = {
  salesRepresentativeNumber: '',
  tireSource: '',
  orderSource: '',
  note: '',
}

const initialTiresCombinationProps: Partial<ITiresCombinationWithPartialTire> =
  {
    frontCount: 2,
    rearCount: 2,
    frontTire: {
      aspectRatio: '',
      width: '',
      diameter: '',
      psi: '',
      torque: '',
      manufacturer: '',
      model: '',
    },
    rearTire: {
      aspectRatio: '',
      width: '',
      diameter: '',
      psi: '',
      torque: '',
      manufacturer: '',
      model: '',
    },
  }

enum SCHEDULER_MODES {
  // used when the an Order has been created from a Tire Rack order
  // and will be completed via CS Portal
  FINISH = 'finish', //this is not being used because we do not have TireRack Orders yet. This functionality might be needed when we implement DTMI Integrated Scheduler. Will leave.
  EDIT = 'edit', //when an order is being edited from within the 'Edit Order' module
  SCHEDULER = 'scheduler', //when an order is being scheduled from the main google search bar
}
export type ISchedulerModes = keyof typeof SCHEDULER_MODES

const SCHEDULER_STEPS = {
  APPOINTMENT_SLOTS: 'Appointment Slots',
  CONFIRM_APPOINTMENT: 'Confirm Appointment Information',
  CUSTOMER_INFO: 'Customer Information',
  EDIT_ADDRESS: 'Edit Address',
  ORDER_DETAILS: 'Order Details',
  TIRE_SIZE_AND_QUANTITY: 'Tire Size & Quantity',
}

export {
  FORWARD_BUTTON_LABELS,
  initialCustomerInfoProps,
  initialCustomerVehicleProps,
  initialInstallationPointProps,
  initialOrderProps,
  initialTiresCombinationProps,
  SCHEDULER_MODES,
  SCHEDULER_STEPS,
}
