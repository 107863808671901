import React from 'react'
import { IEvent } from '../AppointmentCalendar'
import styles from './styles.module.css'
import { EventProps } from 'react-big-calendar'

const CalendarEvent: React.FC<EventProps<IEvent>> = ({ event }) => {
  const { onClick, title: {primary, secondary} } = event

  return (
    <div className={styles.eventContainer}
      onClick={onClick ? onClick : () => { }}
    >
      <div className={`${styles.block} ${styles.primary}`}>{primary}</div>
      {
        secondary && (<div className={`${styles.block}`}>{secondary}</div>)
      }
    </div>
  )
}

export default CalendarEvent
