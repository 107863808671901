import moment from 'moment'
import { useContext } from 'react'
import OrderSummary from '../order-summary/OrderSummary'
import styles from './styles.module.css'
import { OrderContext } from '../../../../../global/context/order-context/OrderContext'

interface IConfirmationAppointment {
  disposalCount: number
  isRework: boolean
  isScheduled: boolean
}

function ConfirmAppointment({
  disposalCount,
  isRework,
  isScheduled,
}: IConfirmationAppointment) {
  const { orderData, customerVehicleData: customerVehicle } =
    useContext(OrderContext)
  const { customer, installationPoint, routeInstructions } = orderData
  const selectedTime = moment.utc(routeInstructions?.[0]?.startTime).valueOf()

  return (
    <div className={styles.confirmAppointmentContainer}>
      <div className={styles.appointmentContainer}>
        <div className={styles.appointmentTimeContainer}>
          <div className={styles.appointmentTimeContainerTitle}>
            Appointment
          </div>
          <div className={styles.confirmAppointmentSmallText}>
            {moment.utc(selectedTime).format('MMMM Do YYYY, h:mm a')}
          </div>
        </div>
        <div className={styles.appointmentTimeContainer}>
          <div className={styles.appointmentTimeContainerTitle}>
            Installation Address
          </div>
          <div className={styles.confirmAppointmentSmallText}>
            {installationPoint?.address}
          </div>
          <div
            className={styles.confirmAppointmentSmallText}
          >{`${installationPoint?.city}, ${installationPoint?.state} ${installationPoint?.zipCode}`}</div>
        </div>
        <div className={styles.appointmentTimeContainer}>
          <div className={styles.appointmentTimeContainerTitle}>Vehicle</div>
          <div className={styles.confirmAppointmentSmallText}>
            {customerVehicle?.brand?.name}
          </div>
          <div className={styles.confirmAppointmentSmallText}>
            {customerVehicle?.model}
          </div>
          <div className={styles.confirmAppointmentSmallText}>
            {customerVehicle?.year}
          </div>
          <div className={styles.confirmAppointmentSmallText}>
            {customerVehicle?.variant}
          </div>
        </div>
        <div className={styles.appointmentTimeContainer}>
          <div className={styles.appointmentTimeContainerTitle}>Contact</div>
          <div
            className={styles.confirmAppointmentSmallText}
          >{`${customer?.firstName} ${customer?.lastName}`}</div>
          <div
            className={styles.confirmAppointmentSmallText}
          >{`${customer?.email}`}</div>
          <div
            className={styles.confirmAppointmentSmallText}
          >{`${customer?.phoneNumber}`}</div>
        </div>
      </div>
      <div className={styles.orderSummaryContainer}>
        <OrderSummary
          isRework={isRework}
          overrideTireDisposalCount={disposalCount}
        />
        {isScheduled && (
          <div className={styles.confirmationMessage}>
            The appointment has been scheduled 🥳
          </div>
        )}
      </div>
    </div>
  )
}

export default ConfirmAppointment
