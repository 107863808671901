import { IInstallationPoint } from '../../../../../../app/entities/InstallationPoint'
import { ILocation } from '../../../../../../app/entities/Location'
import { CONFIRMATION_LEVEL_KEYS } from '../../../../global/constants/google-maps-address'
import { IConfirmationLevel } from '../../../../global/types/google-maps-address'
import { fetchData } from '../../../../global/utils/fetch'

export async function logAddressValidationData(
  installationPoint: Partial<IInstallationPoint>,
  location?: ILocation,
  confirmationLevel?: IConfirmationLevel,
) {
  const addressWithApt = installationPoint.aptOrSuite
    ? `${installationPoint.address}, ${installationPoint.aptOrSuite}`
    : `${installationPoint.address}`
  const logAddressValidationEntry = {
    streetAddress: addressWithApt,
    city: installationPoint.city,
    state: installationPoint.state,
    zipCode: installationPoint.zipCode,
    confirmationStatus:
      JSON.stringify(confirmationLevel) ?? CONFIRMATION_LEVEL_KEYS.CONFIRMED,
    locationName: location?.name,
  }
  try {
    await fetchData({
      method: 'POST',
      url: `${process.env.REACT_APP_ORIGIN}/address-validation-records`,
      body: logAddressValidationEntry,
    })
  } catch (err) {
    console.error(err)
  }
}
