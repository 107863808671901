import { CONFIRMATION_LEVEL_KEYS } from '../../../../../global/constants/google-maps-address'

type IStatus = {
  [key in CONFIRMATION_LEVEL_KEYS]: {
    backgroundColor: string
  }
}
export const STATUSES: IStatus = {
  [CONFIRMATION_LEVEL_KEYS.CONFIRMATION_LEVEL_UNSPECIFIED]: {
    backgroundColor: 'none',
  },
  [CONFIRMATION_LEVEL_KEYS.CONFIRMED]: {
    backgroundColor: 'none',
  },
  [CONFIRMATION_LEVEL_KEYS.UNCONFIRMED_BUT_PLAUSIBLE]: {
    backgroundColor: 'rgba(255, 255, 0, 0.25)',
  },
  [CONFIRMATION_LEVEL_KEYS.UNCONFIRMED_AND_SUSPICIOUS]: {
    backgroundColor: 'rgba(255, 0, 0, 0.25)',
  },
}
