import { refreshToken } from './refreshToken'

export interface IFetchDataProps<T = any> {
  url: string
  method: string
  params?: { [key: string]: string }
  body?: T
}

export async function fetchData<R, T = any>(
  props: IFetchDataProps<T>,
): Promise<R> {
  const idToken = await refreshToken()

  let url = new URL(props.url)

  if (props.params) {
    Object.keys(props.params).forEach((key) =>
      url.searchParams.append(key, props.params![key]),
    )
  }

  try {
    const resp = await fetch(url.toString(), {
      method: props.method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: idToken ? `Bearer ${idToken}` : '',
      },
      body: JSON.stringify(props.body),
    })
    if (resp.status === 204) return [] as any
    const data = await resp.json()

    if (data?.error || !resp.ok) {
      throw new Error(
        data.error?.message ||
          data.error?.stack ||
          data.error?.error ||
          data.error,
      )
    }
    return data as R
  } catch (error: any) {
    const errorMessage = `${error}`.includes('Error')
      ? `${`${error}`.replace('Error: ', '')}`
      : `Error making API call: ${error}`
    throw new Error(errorMessage)
  }
}
