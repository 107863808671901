import { ReactNode } from 'react'
import styles from '../styles.module.css'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import useFetch from '../../../../../../../global/hooks/useFetchHook'
import CircularProgress from '@mui/material/CircularProgress'
import { getBrands } from '../../api'
import { IBrand } from '../../../../../../../../../app/entities/Brand'
import { ICustomerVehicle } from '../../../../../../../../../app/entities/CustomerVehicle'

const selectProps = {
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: 500,
        width: 250,
      },
    },
  },
}

interface IBrandSelectProps {
  customerVehicle: Partial<ICustomerVehicle>
  onChange: Function
  label: string
}

export default function MakeInformationSelect({
  customerVehicle,
  onChange,
  label,
}: IBrandSelectProps) {
  let menuItems = [] as ReactNode

  let { data, isLoading } = useFetch<IBrand[]>(getBrands())

  if (data) {
    menuItems = data.map(({objectId, name}: any) => {
      return (
        <MenuItem
          key={objectId}
          value={objectId}>
          {name}
        </MenuItem>
      )
    })
  }

  if (isLoading) {
    return <CircularProgress size={20} />
  }

  return (
    <TextField
      className={styles.textfieldStyles}
      label={label}
      select
      SelectProps={selectProps}
      value={customerVehicle.brand?.objectId || ''}
      onChange={(e) => {
        const selectedValue = e.target.value
        const selectedMake = data?.find((brand) => brand.objectId === selectedValue)
        selectedMake && onChange(selectedMake)
      }}>
      {menuItems}
    </TextField>
  )
}
