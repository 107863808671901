import React from 'react'
import styles from './styles.module.css'
import UserModalMain from '../../user-modal/UserModalMain'
import PrimaryButton from '../../../global/components/buttons/primary-button/PrimaryButton'

interface INewUserProps {
  setRefetchIndex: Function
  refetchIndex: number
}

function NewUser(props: INewUserProps) {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClosed = () => {
    setOpen(false)
  }

  return (
    <div className={styles.makeAppContainer}>
      <div className={styles.inputsContainer}>
        <PrimaryButton buttonName='Create User' onClick={handleOpen} />
      </div>
      {open && (
        <UserModalMain
          isOpen={open}
          handleClosed={handleClosed}
          setRefetchIndex={props.setRefetchIndex}
          refetchIndex={props.refetchIndex}
        />
      )}
    </div>
  )
}

export default NewUser
