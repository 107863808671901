import CircularProgress from '@mui/material/CircularProgress'
import { useContext, useEffect, useState } from 'react'
import SelectQuantities from './select-quantities/SelectQuantities'
import { SelectTireSize } from './select-size/SelectTireSize'
import styles from './styles.module.css'
import { Select } from '../../../../../global/components/select/Select'
import VehicleDetails from './details/VehicleDetails'
import { OrderContext } from '../../../../../global/context/order-context/OrderContext'

interface ITireSizeQuantityProps {
  disposalCount: number
  setDisposalCount: Function
  setIsForwardDisabled: Function
}

export interface ITireSizeError {
  width: boolean
  diameter: boolean
  aspectRatio: boolean
  psi: boolean
  torque: boolean
}

function TireSizeQuantity({
  disposalCount,
  setDisposalCount,
  setIsForwardDisabled,
}: ITireSizeQuantityProps) {
  const { orderData } = useContext(OrderContext)
  const { orderVehicles } = orderData
  const { tiresCombination } = orderVehicles[0]

  const [isLoading] = useState(false)
  const [tireSizeError, setTireSizeError] = useState<ITireSizeError>({
    width: false,
    diameter: false,
    aspectRatio: false,
    psi: false,
    torque: false,
  })

  function checkTireFormFields(object: any): boolean {
    let passes = true

    for (const key in object) {
      let errorUpdate = { [key]: false }
      const element = object[key]
      if (typeof element === 'object') {
        passes = checkTireFormFields(element)
      } else if (
        ['width', 'diameter', 'aspectRatio', 'manufacturer', 'model'].includes(
          key,
        ) &&
        element === ''
      ) {
        passes = false
      } else if (key === 'width' && parseInt(element) > 295) {
        //if greater than 295 show warning
        errorUpdate = { width: true }
      } else if (key === 'diameter' && parseInt(element) > 22) {
        errorUpdate = { diameter: true }
        passes = false
      } else if (
        key === 'psi' &&
        (!element || parseInt(element) < 20 || parseInt(element) > 120)
      ) {
        errorUpdate = { psi: true }
        passes = false
      } else if (
        key === 'torque' &&
        (!element || parseInt(element) < 50 || parseInt(element) > 200)
      ) {
        errorUpdate = { torque: true }
        passes = false
      }

      setTireSizeError((prevState) => ({
        ...prevState,
        ...errorUpdate,
      }))

      if (!passes) {
        break
      }
    }

    return passes
  }

  useEffect(() => {
    const doAllFormFieldsPass = checkTireFormFields(tiresCombination)
    setIsForwardDisabled(!doAllFormFieldsPass)
  }, [tiresCombination])

  return (
    <div className={styles.formContainer}>
      {isLoading && (
        <div className={styles.overlay}>
          <div className={styles.progressContainer}>
            <CircularProgress size={80} />
          </div>
        </div>
      )}
      <SelectTireSize />
      <div className={styles.tireQuantityContainer}>
        <SelectQuantities />
        <div className={styles.disposalContainer}>
          <div className={`font--bold ${styles.title}`}>Disposal</div>
          <Select
            name='tireDisposal'
            onChange={(e: any) => setDisposalCount(e.target.value)}
            defaultValue={disposalCount || 0}
            label='Tire Disposal Count'
            width='156px'
            data={Array(9)
              .fill(null)
              .map((_, i) => ({ name: i + '', objectId: i }))}
          />
        </div>
        <div className={styles.errorContainer}>
          {tireSizeError.diameter && (
            <div className={styles.errorMessage}>
              Sorry but the wheel diameter of the chosen tire is too large for
              us to service.
            </div>
          )}
          {tireSizeError.width && (
            <div className={styles.errorMessage}>
              Some aftermarket wheels for tires of this size may not be able to
              be serviced. Original equipment wheels will not be a problem. We
              are not able to service tires of this size on reverse mount
              aftermarket wheels. More info on reverse mount wheels can be found{' '}
              <a
                href='https://www.tirerack.com/upgrade-garage/what-are-reversemount-backmount-wheels'
                target='_blank'
                rel='noreferrer'
              >
                here
              </a>
              .
            </div>
          )}
        </div>
      </div>
      <VehicleDetails tireSizeError={tireSizeError} />
    </div>
  )
}

export default TireSizeQuantity
