import TextField from '@mui/material/TextField'
import styles from './styles.module.css'
import {
  ITiresCombination,
  TiresCombination,
} from '../../../../../../../../app/entities/TiresCombination'
import { ITire, Tire } from '../../../../../../../../app/entities/Tire'
import { ITireSizeError } from '../TireSizeQuantity'
import {
  MAX_PSI,
  MAX_TORQUE,
  MIN_PSI,
  MIN_TORQUE,
} from '../../../../../../global/constants/tire'
import { useContext } from 'react'
import { OrderContext } from '../../../../../../global/context/order-context/OrderContext'

interface IVehicleDetailsProps {
  tireSizeError: ITireSizeError
}

const TIRE_FIELD_NAMES = {
  FRONT_TIRE: 'frontTire',
  MANUFACTURER: 'manufacturer',
  MODEL: 'model',
  REAR_TIRE: 'rearTire',
  TORQUE: 'torque',
}

function VehicleDetails({ tireSizeError }: IVehicleDetailsProps) {
  const { orderData, setTiresCombination } = useContext(OrderContext)
  const { orderVehicles } = orderData
  const { tiresCombination } = orderVehicles[0]

  function createNewTire(
    tireName: string,
    tiresCombination?: TiresCombination,
  ): ITire {
    // this madness below is only to make typescript happy.
    // the gist is that we need to set the psi value of the tiresCombination object
    // but we don't want to overwrite the other values in the object so we spread
    // either the frontTire or rearTire object and then overwrite the psi value
    return tiresCombination
      ? (tiresCombination[tireName as keyof ITiresCombination] as ITire)
      : ({} as ITire)
  }

  function onTireValueChange(
    tireNames: string[],
    field: string,
    value: string,
  ) {
    const updatedTiresCombination = { ...tiresCombination }

    const shouldUpdateFrontTire = tireNames.includes(
      TIRE_FIELD_NAMES.FRONT_TIRE,
    )
    const shouldUpdateRearTire = tireNames.includes(TIRE_FIELD_NAMES.REAR_TIRE)
    if (shouldUpdateFrontTire) {
      updatedTiresCombination.frontTire = {
        ...createNewTire(TIRE_FIELD_NAMES.FRONT_TIRE, tiresCombination),
        [field]: value,
      } as Tire
    }
    if (shouldUpdateRearTire) {
      updatedTiresCombination.rearTire = {
        ...createNewTire(TIRE_FIELD_NAMES.REAR_TIRE, tiresCombination),
        [field]: value,
      } as Tire
    }

    setTiresCombination(updatedTiresCombination as ITiresCombination)
  }

  return (
    <div className={styles.vehicleDetailsContainer}>
      <div className={`${styles.detailsTitle} font--bold`}>Details</div>
      <div className={styles.inputsContainer}>
        <div className={styles.vehicleDetailsButtonsContainer}>
          <div className={styles.psiTorqueContainer}>
            <TextField
              type='number'
              name={TIRE_FIELD_NAMES.FRONT_TIRE}
              defaultValue={tiresCombination?.frontTire?.psi || ''}
              label='Front PSI'
              onChange={(e) => {
                const target = e.target as HTMLInputElement
                const value = target.valueAsNumber
                onTireValueChange(
                  [target.name],
                  'psi',
                  isNaN(value) ? '0' : value.toString(),
                )
              }}
              InputLabelProps={{
                shrink: tiresCombination?.frontTire?.psi ? true : false,
              }}
            />
            <TextField
              type='number'
              name={TIRE_FIELD_NAMES.REAR_TIRE}
              defaultValue={tiresCombination?.rearTire?.psi || ''}
              label='Rear PSI'
              onChange={(e) => {
                const target = e.target as HTMLInputElement
                const value = target.valueAsNumber
                onTireValueChange(
                  [target.name],
                  'psi',
                  isNaN(value) ? '0' : value.toString(),
                )
              }}
              InputLabelProps={{
                shrink: tiresCombination?.rearTire?.psi ? true : false,
              }}
            />
            <TextField
              type='number'
              name={TIRE_FIELD_NAMES.TORQUE}
              defaultValue={tiresCombination?.frontTire?.torque || ''}
              label='Torque'
              onChange={(e) => {
                const target = e.target as HTMLInputElement
                const value = target.valueAsNumber
                onTireValueChange(
                  [TIRE_FIELD_NAMES.FRONT_TIRE, TIRE_FIELD_NAMES.REAR_TIRE],
                  'torque',
                  isNaN(value) ? '0' : value.toString(),
                )
              }}
              InputLabelProps={{
                shrink: tiresCombination?.frontTire?.torque ? true : false,
              }}
            />
          </div>

          <TextField
            label='Tire Make'
            name={TIRE_FIELD_NAMES.MANUFACTURER}
            value={
              tiresCombination?.frontTire?.manufacturer ||
              tiresCombination?.rearTire?.manufacturer ||
              ''
            }
            fullWidth
            onChange={(e) =>
              onTireValueChange(
                [TIRE_FIELD_NAMES.FRONT_TIRE, TIRE_FIELD_NAMES.REAR_TIRE],
                TIRE_FIELD_NAMES.MANUFACTURER,
                e.target.value,
              )
            }
          />
          <TextField
            label='Tire Model'
            name={TIRE_FIELD_NAMES.MODEL}
            value={
              tiresCombination?.frontTire?.model ||
              tiresCombination?.rearTire?.model ||
              ''
            }
            fullWidth
            onChange={(e) =>
              onTireValueChange(
                [TIRE_FIELD_NAMES.FRONT_TIRE, TIRE_FIELD_NAMES.REAR_TIRE],
                TIRE_FIELD_NAMES.MODEL,
                e.target.value,
              )
            }
          />
        </div>
      </div>
      {tireSizeError.psi && (
        <div className={styles.errorMessage}>
          PSI must be between {MIN_PSI} and {MAX_PSI}.
        </div>
      )}
      {tireSizeError.torque && (
        <div className={styles.errorMessage}>
          Torque must be between {MIN_TORQUE} and {MAX_TORQUE}.
        </div>
      )}
    </div>
  )
}

export default VehicleDetails
