import Checkbox from '@mui/material/Checkbox'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { useContext, useState } from 'react'
import { IOrderVehicle } from '../../../../../../../../../app/entities/OrderVehicle'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import { ToastNotificationContext } from '../../../../../../../global/context/toast-context/ToastNotificationContext'
import { OrderContext } from '../../../../../../../global/context/order-context/OrderContext'
import styles from '../styles/styles.module.css'
import { fetchData } from '../../../../../../../global/utils/fetch'
import {
  updateOrderStatusToCanceledStatus,
  updateOrderVehicle,
} from '../../../../../api'
import { IOrder } from '../../../../../../../../../app/entities/Order'
//TODO plug in the cancellationNote api once created

interface ICancelOrderProps {
  handleClose: Function
}

export default function CancelOrder({ handleClose }: ICancelOrderProps) {
  const { queryOrderData, orderData } = useContext(OrderContext)
  const { objectId: orderObjectId } = orderData

  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [orderVehicle, setOrderVehicle] = useState<IOrderVehicle>({
    ...orderData.orderVehicles[0],
    cosReason1: 'Pre-appointment Cancellation',
    cosReason2: 'Customer Request',
  })

  const cancelledStatus =
    orderVehicle.cosReason1 === 'Pre-appointment Cancellation'
      ? 'cancelled'
      : 'cancelled_onsite'
  console.log('REASON:', orderVehicle.cosReason1)

  const handleCancelOrder = async () => {
    try {
      setIsLoading(true)
      await fetchData<IOrder>(
        updateOrderStatusToCanceledStatus(orderObjectId, cancelledStatus),
      )
      await fetchData<IOrderVehicle>(updateOrderVehicle(orderVehicle))
      // await canceledOrderNote(id, formData.note, formData.reason)
      await queryOrderData(orderObjectId ?? '')
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Order canceled!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Error canceling order!',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <div className={styles.inputsContainer}>
      <div className={styles.topInputsContainer}>
        <ReasonDropDown
          orderVehicle={orderVehicle}
          setOrderVehicle={setOrderVehicle}
        />
      </div>
      <div className={styles.topInputsContainer}>
        <TextField
          fullWidth
          label='Note'
          value={orderVehicle.cosNote || ''}
          multiline
          rows={4}
          onChange={(e) =>
            setOrderVehicle((prevState) => ({
              ...prevState,
              cosNote: e.target.value,
            }))
          }
        />
      </div>
      <div className={styles.checkBoxContainer}>
        <Checkbox onChange={() => setIsChecked(!isChecked)} />
        <div>Yes I want to cancel this order</div>
      </div>
      <div className={styles.buttonContainer}>
        <SecondaryButton buttonName='Cancel' onClick={() => handleClose()} />
        <PrimaryButton
          buttonName='Confirm Cancelation'
          disabled={!isChecked || orderVehicle.cosReason1 === null || isLoading}
          onClick={handleCancelOrder}
        />
      </div>
    </div>
  )
}

function composeMenuItemsFromReasons(reasons: string[]) {
  return reasons.map((reason: string, index: number) => {
    return (
      <MenuItem key={index} value={reason}>
        {reason}
      </MenuItem>
    )
  })
}
interface IReasonDropDownProps {
  orderVehicle: IOrderVehicle
  setOrderVehicle: Function
}
const ReasonDropDown = ({
  orderVehicle,
  setOrderVehicle,
}: IReasonDropDownProps) => {
  const { cosReason1, cosReason2, cosReason3 } = orderVehicle

  const reasonsTree:
    | Record<string, true | Record<string, true | Record<string, true>>>
    | any = {
    'Pre-appointment Cancellation': {
      'Customer Request': true,
      'Tech Unavailable/Unscheduled': true,
      'Van Maintenance/Repair': true,
      'Order did not arrive in time': true,
    },
    'Tire Conditions': {
      'Mixed performance categories': true,
      'Incorrect tire size': true,
      'Tread depth mismatch': {
        '1 tire install': true,
        '2 tire install': true,
        '3 tire install': true,
      },
      'Ozone Cracking/Dry Rot': true,
      'Bubble on sidewall of tire': true,
      'Ripped Bead': true,
      'Bent Bead': true,
      'Bead will not seat/Inflate tire': true,
      'Can’t break bead': true,
    },
    'Wheel Conditions': {
      'Cracked or bent wheel': true,
      'Incorrect wheel size': true,
      'Wheel stuck on hub': true,
      'Improper Welds': true,
    },
    'Lug Conditions': {
      'Swollen lug nuts': true,
      'Missing lug nut': true,
      'Missing stud': true,
      'Cross threaded stud': true,
      'Rusted stud': true,
      Overtorqued: true,
      'Incorrect Lug Seat': true,
      'Missing wheel lock key': true,
    },
    'Equipment Conditions': {
      'Balancer failure': true,
      'Air compressor failure': true,
      'Mounter failure': true,
      'Lost power/electrical issue': true,
      'Van inoperable/down': true,
      'Equipment unavailable': true,
    },
    'Customer Conditions': {
      'Customer Unavailable': true,
      'Gravel location': true,
      'Too much slope at customer location': true,
      'Parking structure': true,
      'Street access only': true,
    },
    'Other Conditions': true,
  }

  const cosReason1Options = composeMenuItemsFromReasons(
    Object.keys(reasonsTree),
  )

  const cosReasons2 =
    cosReason1 && reasonsTree[cosReason1] !== true
      ? Object.keys(reasonsTree[cosReason1])
      : []

  const cosReason2Options = composeMenuItemsFromReasons(cosReasons2)

  const cosReasons3 =
    cosReason2 && reasonsTree[cosReason1][cosReason2] !== true
      ? Object.keys(reasonsTree[cosReason1][cosReason2])
      : []

  const cosReason3Options = composeMenuItemsFromReasons(cosReasons3)

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <TextField
        value={cosReason1 || ''}
        fullWidth
        onChange={(e) =>
          setOrderVehicle({
            ...orderVehicle,
            cosReason1: e.target.value,
            cosReason2: '',
            cosReason3: '',
          })
        }
        label='Primary Reason'
        select
      >
        {cosReason1Options}
      </TextField>
      {cosReason2Options.length > 0 && (
        <TextField
          value={cosReason2 || ''}
          fullWidth
          onChange={(e) =>
            setOrderVehicle({
              ...orderVehicle,
              cosReason2: e.target.value,
              cosReason3: '',
            })
          }
          label='Secondary Reason'
          select
        >
          {cosReason2Options}
        </TextField>
      )}
      {cosReason3Options.length > 0 && (
        <TextField
          value={cosReason3 || ''}
          fullWidth
          onChange={(e) =>
            setOrderVehicle({ ...orderVehicle, cosReason3: e.target.value })
          }
          label='Tertiary Reason'
          select
        >
          {cosReason3Options}
        </TextField>
      )}
    </div>
  )
}
