import { IInstallationPointPartial } from '../../../../../../app/entities/InstallationPoint'
import { ILocation } from '../../../../../../app/entities/Location'
import createInstallationPointFromGeocode from '../../../../global/utils/installation-point/create/from-geocode'

async function createInstallationPointFromChosenAddress(
  chosenAddress: google.maps.GeocoderResult | null | undefined,
  location: ILocation | undefined,
): Promise<IInstallationPointPartial> {
  if (chosenAddress) {
    const installationPointValuesFromGoogle =
      await createInstallationPointFromGeocode(
        chosenAddress,
        location?.objectId || '',
      )

    return installationPointValuesFromGoogle
  }

  return {}
}

export default createInstallationPointFromChosenAddress
