import { ChangeEvent, useContext, useEffect, useState } from 'react'
import styles from './styles.module.css'
import { TextField } from '@mui/material'
import { IUserPartial } from '../../../../app/entities/User'
import { isValidEmail } from '../../global/utils/formValidation'
import LocationDropDown from '../../global/components/location-drop-down/LocationDropDown'
import SpokeLocationDropDown from '../../global/components/spoke-location-drop-down/SpokeLocationDropDown'
import { fetchData } from '../../global/utils/fetch'
import { IRole, Role } from '../../../../app/entities/Role'
import { getRoles } from '../../global/utils/roles/api'
import { ToastNotificationContext } from '../../global/context/toast-context/ToastNotificationContext'
import RoleDropDown from '../common/RoleDropDown'
import { getDoesRoleRequireLocation } from '../../global/utils/roles/api'
interface IUserCreateProps {
  userData: IUserPartial
  setUserData: Function
  formError: boolean
  setFormError: Function
}

const ROLE_DROPDOWN_ID = 'roleName'

export default function UserCreate({
  userData,
  setUserData,
  formError,
  setFormError,
}: IUserCreateProps) {
  const { name, email, role } = userData
  const [rolesArray, setRolesArray] = useState<IRole[]>([])
  const [locationValueOverride, setLocationValueOverride] = useState<
    string | null
  >(null)
  const [roleRequiresLocation, setRoleRequiresLocation] =
    useState<boolean>(false)

  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)

  async function fetchDoesRoleRequireLocation() {
    const doesRoleRequireLocation = role
      ? await fetchData<boolean>(getDoesRoleRequireLocation(role.objectId))
      : false

    setRoleRequiresLocation(doesRoleRequireLocation)
  }

  async function fetchRoleInformation() {
    const res = await fetchData<any>(getRoles(false))
    const { count, items: roles } = res
    setRolesArray(roles)
  }

  useEffect(() => {
    fetchRoleInformation()
  }, [])

  useEffect(() => {
    fetchDoesRoleRequireLocation()
  }, [role])

  function handleChangeForm(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target
    let updatedData = { ...userData }

    const userChangedRole = name === ROLE_DROPDOWN_ID
    // handle Role changes differently as the Role dropdown only
    // contains the role name, but the user object requires the Role object
    if (userChangedRole) {
      // avoid setting locations for Admin and Super Admin
      updatedData['locations'] = []
      updatedData['spokeLocation'] = undefined
      setLocationValueOverride(null)

      const updatedRole = rolesArray.find(({ name }) => name === value)
      if (!updatedRole) {
        setToastStatus({
          ...toastStatus,
          isOpen: true,
          message: 'Could not find role! Please contact support.',
          severity: 'error',
        })
        return
      }
      updatedData['role'] = updatedRole as Role
    } else {
      // handle other form changes
      updatedData = { ...updatedData, [name]: value }
    }

    if (name === 'email' && !isValidEmail(value)) {
      setFormError(true)
    } else {
      setFormError(false)
    }

    setUserData(updatedData)
  }

  function onLocationChange(locationObjectId: string) {
    setUserData({
      ...userData,
      locations: [{ objectId: locationObjectId }],
      spokeLocation: undefined,
    })
  }
  function onSpokeLocationChange(spokeLocation: string) {
    setUserData({
      ...userData,
      spokeLocation: spokeLocation,
    })
  }

  return (
    <div className={styles.formContainer}>
      <TextField
        value={name}
        onChange={handleChangeForm}
        label='Name'
        name='name'
        fullWidth
      />
      <TextField
        value={email}
        onChange={handleChangeForm}
        label='Email'
        name='email'
        error={formError}
        helperText={formError && 'Please enter a valid email address'}
        fullWidth
      />
      <RoleDropDown
        initialValue={role?.name || ''}
        onChange={handleChangeForm}
      />
      {roleRequiresLocation && (
        <>
          <LocationDropDown
            onLocationChange={onLocationChange}
            defaultValue={userData.locations?.[0]?.objectId}
            valueOverride={locationValueOverride}
          />
          {userData.locations?.[0]?.objectId && (
            <SpokeLocationDropDown
              locationId={userData.locations?.[0]?.objectId}
              onSpokeLocationChange={onSpokeLocationChange}
              defaultValue={userData?.spokeLocation}
            />
          )}
        </>
      )}
    </div>
  )
}
