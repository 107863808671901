export const getDemandGenerationTableData = (weeksToShow: number, csv?: boolean) => {
  let url = `${process.env.REACT_APP_ORIGIN}/reports/demandGeneration?weeksToShow=${weeksToShow}`;
  if (csv) {
    url += `&csv=${csv}`;
  }
  return {
    method: "GET",
    url
  }
}
