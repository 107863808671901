export const GEOCODE_PLACE_TYPES = {
  administrative_area_level_1: 'administrative_area_level_1',
  administrative_area_level_2: 'administrative_area_level_2',
  administrative_area_level_3: 'administrative_area_level_3',
  administrative_area_level_4: 'administrative_area_level_4',
  administrative_area_level_5: 'administrative_area_level_5',
  administrative_area_level_6: 'administrative_area_level_6',
  administrative_area_level_7: 'administrative_area_level_7',
  airport: 'airport',
  bus_station: 'bus_station',
  archipelago: 'archipelago',
  colloquial_area: 'colloquial_area',
  continent: 'continent',
  country: 'country',
  establishment: 'establishment',
  finance: 'finance',
  floor: 'floor',
  food: 'food',
  general_contractor: 'general_contractor',
  geocode: 'geocode',
  health: 'health',
  intersection: 'intersection',
  landmark: 'landmark',
  locality: 'locality',
  natural_feature: 'natural_feature',
  neighborhood: 'neighborhood',
  park: 'park',
  parking: 'parking',
  place_of_worship: 'place_of_worship',
  plus_code: 'plus_code',
  point_of_interest: 'point_of_interest',
  political: 'political',
  post_box: 'post_box',
  postal_code: 'postal_code',
  postal_code_prefix: 'postal_code_prefix',
  postal_code_suffix: 'postal_code_suffix',
  postal_town: 'postal_town',
  premise: 'premise',
  room: 'room',
  route: 'route',
  street_address: 'street_address',
  street_number: 'street_number',
  sublocality: 'sublocality',
  sublocality_level_1: 'sublocality_level_1',
  sublocality_level_2: 'sublocality_level_2',
  sublocality_level_3: 'sublocality_level_3',
  sublocality_level_4: 'sublocality_level_4',
  sublocality_level_5: 'sublocality_level_5',
  subpremise: 'subpremise',
  town_square: 'town_square',
  train_station: 'train_station',
  transit_station: 'transit_station',
}

//The following arrays represent the possible componenets that a geocode address from Google could be broken into
//the city might be geocoded in an abnormal way, so this makes sure that we can get it.
//We will search through the returned address components until we find the first option that returns true.
export const GEOCODE_STATE = [GEOCODE_PLACE_TYPES.administrative_area_level_1]

export const GEOCODE_CITY = [
  GEOCODE_PLACE_TYPES.locality,
  GEOCODE_PLACE_TYPES.sublocality,
  GEOCODE_PLACE_TYPES.sublocality_level_1,
  GEOCODE_PLACE_TYPES.sublocality_level_2,
  GEOCODE_PLACE_TYPES.sublocality_level_3,
  GEOCODE_PLACE_TYPES.sublocality_level_4,
  GEOCODE_PLACE_TYPES.sublocality_level_5,
  GEOCODE_PLACE_TYPES.neighborhood,
  GEOCODE_PLACE_TYPES.postal_town,
]

export const GEOCODE_ZIP_CODE = [GEOCODE_PLACE_TYPES.postal_code]

export const GEOCODE_STREET_ADDRESS = [GEOCODE_PLACE_TYPES.street_address]

export const GEOCODE_STREET_NAME = [GEOCODE_PLACE_TYPES.route]

export const GEOCODE_STREET_NUMBER = [GEOCODE_PLACE_TYPES.street_number]

export const GEOCODE_NON_STANDARD_ADDRESS = [
  GEOCODE_PLACE_TYPES.premise,
  GEOCODE_PLACE_TYPES.subpremise,
  GEOCODE_PLACE_TYPES.airport,
  GEOCODE_PLACE_TYPES.colloquial_area,
  GEOCODE_PLACE_TYPES.intersection,
  GEOCODE_PLACE_TYPES.landmark,
  GEOCODE_PLACE_TYPES.parking,
  GEOCODE_PLACE_TYPES.park,
]

export enum CONFIRMATION_LEVEL_KEYS {
  CONFIRMATION_LEVEL_UNSPECIFIED = 'CONFIRMATION_LEVEL_UNSPECIFIED',
  CONFIRMED = 'CONFIRMED',
  UNCONFIRMED_BUT_PLAUSIBLE = 'UNCONFIRMED_BUT_PLAUSIBLE',
  UNCONFIRMED_AND_SUSPICIOUS = 'UNCONFIRMED_AND_SUSPICIOUS',
}

export const initialConfirmationLevel = {
  address: CONFIRMATION_LEVEL_KEYS.CONFIRMATION_LEVEL_UNSPECIFIED,
  city: CONFIRMATION_LEVEL_KEYS.CONFIRMATION_LEVEL_UNSPECIFIED,
  state: CONFIRMATION_LEVEL_KEYS.CONFIRMATION_LEVEL_UNSPECIFIED,
  zipCode: CONFIRMATION_LEVEL_KEYS.CONFIRMATION_LEVEL_UNSPECIFIED,
}
