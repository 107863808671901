import moment, { MomentInput } from 'moment'

export const convertMsToYMD = (ms: number) => {
  const dateObj = new Date(ms)
  const year = dateObj.getUTCFullYear()
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0')
  const day = String(dateObj.getUTCDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}
export const convertMsDateToString = (ms: number) => {
  const dateObj = new Date(ms)
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0')
  const day = dateObj.getDate().toString().padStart(2, '0')
  const year = dateObj.getFullYear()
  const formattedDate = `${year}-${month}-${day}`
  return formattedDate
}

export function getWeekNumber(dateInMs: number) {
  const date = new Date(dateInMs)
  const januaryFirst = new Date(date.getFullYear(), 0, 1)
  const firstMonday = new Date(
    januaryFirst.getFullYear(),
    0,
    1 + ((8 - januaryFirst.getDay()) % 7),
  )
  const diffDays = Math.round(
    (date.getTime() - firstMonday.getTime()) / (1000 * 60 * 60 * 24),
  )
  const weekNumber = Math.floor(diffDays / 7) + 1

  return weekNumber
}

function getWeekDays(locale = 'en-US') {
  var baseDate = new Date(Date.UTC(2017, 0, 2)) // just a Monday

  return Array.from({ length: 7 }, () => {
    const weekday = baseDate.toLocaleDateString(locale, { weekday: 'long' })
    baseDate.setDate(baseDate.getDate() + 1)
    return weekday
  })
}

export function getDayName(date: number | Date | string, locale = 'en-US') {
  const utcDay = new Date(date).getUTCDay()
  return getWeekDays(locale)[utcDay]
}
export const reformatDate = (dateString: string) => {
  //reformats date in MM/DD/YY to YYYY-MM-DD format for date picker input in tech unique day settings table
  const [month, day, year] = dateString.split('/')
  const newDate = `20${year}-${month}-${day}`
  return newDate
}
export const getCurrentDate = () => {
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1
  const day = currentDate.getDate()
  return `${year}-${month.toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}`
}

export const castToUtc = (date: string | Date | number) => {
  const dateObj = new Date(date)
  return new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60000)
}

export function to24HourTime(date: Date) {
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  return `${hours}:${minutes}`
}
export function to12HourTime(date: Date) {
  let hours = date.getHours()
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const ampm = hours >= 12 ? 'PM' : 'AM'

  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'

  return `${hours}:${minutes} ${ampm}`
}
export function formatTo12HourTime(dateString: MomentInput): string {
  return moment(dateString).format('h:mm A')
}

export const convertIsoToYMD = (isoDate: MomentInput): string => {
  return moment(isoDate).format('YYYY-MM-DD')
}
