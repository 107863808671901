import { useContext, useEffect, useState } from 'react'
import UserEdit from './UserEdit'
import UserCreate from './UserCreate'
import styles from './styles.module.css'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import SecondaryButton from '../../global/components/buttons/secondary-button/SecondaryButton'
import PrimaryButton from '../../global/components/buttons/primary-button/PrimaryButton'
import { IUserPartial } from '../../../../app/entities/User'
import PrimaryCheckbox from '../../global/components/checkbox/Checkbox'
import { fetchData } from '../../global/utils/fetch'
import {
  checkIfRoleRequiresLocation,
  createNewUser,
  deleteUser,
  updateUser,
} from '../api'
import { ToastNotificationContext } from '../../global/context/toast-context/ToastNotificationContext'
import { UserContext } from '../../base/components/base-container/BaseContainer'

interface IUserModalMainProps {
  email?: string
  isOpen: boolean
  handleClosed: Function
  setRefetchIndex: Function
  refetchIndex: number
}
export default function UserModalMain({
  email,
  isOpen,
  handleClosed,
  setRefetchIndex,
  refetchIndex,
}: IUserModalMainProps) {
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const { user } = useContext(UserContext)

  const [isChecked, setIsChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formError, setFormError] = useState(false)
  const [userData, setUserData] = useState<IUserPartial>({
    objectId: '',
    name: '',
    email: '',
    locations: [],
  })
  function handleCloseModal() {
    setUserData({
      objectId: '',
      name: '',
      email: '',
    })
    setFormError(false)
    setIsLoading(false)
    setIsChecked(false)
    handleClosed()
  }
  async function handleCreateUser() {
    const body = { ...userData }
    delete body.objectId
    try {
      setIsLoading(true)
      await fetchData(createNewUser(body))
      handleCloseModal()
      setRefetchIndex()
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'User successfully created!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'There has been an error attempting to create user.',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }
  async function handleUpdateUser() {
    try {
      setIsLoading(true)
      await fetchData(updateUser(userData))
      handleCloseModal()
      setRefetchIndex()
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'User info successfully updated!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'There has been an error updating user.',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }
  async function handleDeleteUser() {
    try {
      setIsLoading(true)
      await fetchData(deleteUser(userData.objectId!))
      handleCloseModal()
      setRefetchIndex()
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'User successfully deleted!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'There has been an error attempting to delete user.',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const [doesUserRequireLocation, setDoesUserRequireLocation] =
    useState<boolean>(false)
  async function checkUserRequiresLocation() {
    setDoesUserRequireLocation(
      await fetchData<boolean>(
        checkIfRoleRequiresLocation(userData.role?.objectId ?? ''),
      ),
    )
  }
  useEffect(() => {
    checkUserRequiresLocation()
  }, [userData.role])

  // No spoke location can be valid if the role doesn't require it
  const [isSpokeLocationValid, setIsSpokeLocationValid] =
    useState<boolean>(false)
  async function checkIsSpokeLocationValid() {
    const spokeLocationSelected = Boolean(userData.spokeLocation)

    setIsSpokeLocationValid(
      (doesUserRequireLocation && spokeLocationSelected) ||
        !doesUserRequireLocation,
    )
  }
  useEffect(() => {
    checkIsSpokeLocationValid()
  }, [userData.locations, userData.spokeLocation, doesUserRequireLocation])

  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(false)
  async function checkIfConfirmShouldBeDisabled() {
    setIsConfirmDisabled(
      isLoading ||
        !userData.email ||
        !userData.role ||
        !userData.name ||
        formError ||
        (doesUserRequireLocation && userData.locations?.length === 0) ||
        !isSpokeLocationValid,
    )
  }
  useEffect(() => {
    checkIfConfirmShouldBeDisabled()
  }, [
    isLoading,
    userData.email,
    userData.role,
    userData.name,
    userData.spokeLocation,
    doesUserRequireLocation,
    formError,
    isSpokeLocationValid,
  ])

  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box className={styles.userModal}>
          <div className={`${styles.userModalTitle} font--bold`}>
            {email ? 'Update User' : 'Create User'}
          </div>
          <div className={styles.contentContainer}>
            {email ? (
              <UserEdit
                email={email}
                userData={userData}
                setUserData={setUserData}
                formError={formError}
                setFormError={setFormError}
              />
            ) : (
              <UserCreate
                userData={userData}
                setUserData={setUserData}
                formError={formError}
                setFormError={setFormError}
              />
            )}
          </div>
          {email && (
            <div className={styles.checkBoxContainer}>
              <PrimaryCheckbox
                checked={isChecked}
                onChange={(e) => {
                  setIsChecked(e.target.checked)
                }}
              />
              Check this box to delete user
            </div>
          )}
          <div className={styles.footerButtons}>
            <div style={{ display: 'flex', gap: '8px' }}>
              <SecondaryButton
                buttonName='Cancel'
                onClick={() => handleCloseModal()}
              />
            </div>
            <div>
              {isChecked ? (
                <PrimaryButton
                  buttonName='Delete'
                  disabled={isLoading}
                  onClick={handleDeleteUser}
                />
              ) : email ? (
                <PrimaryButton
                  buttonName='Save'
                  disabled={isConfirmDisabled}
                  onClick={handleUpdateUser}
                />
              ) : (
                <PrimaryButton
                  buttonName='Create'
                  disabled={isConfirmDisabled}
                  onClick={handleCreateUser}
                />
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
