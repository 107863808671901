import moment from 'moment'
import { IWeekCalendar } from '../../../../../../../app/entities/WeekCalendar'
import styles from './styles.module.css'
import { Checkbox } from '@mui/material'

interface IWeekCalendarProps {
  weekCalendar: Partial<IWeekCalendar>
  setWeekCalendar: Function
  isReadOnly: boolean
}

export function WeekCalendar({
  weekCalendar,
  setWeekCalendar,
  isReadOnly,
}: IWeekCalendarProps) {
  const weekDayCheckboxes = moment.weekdays().map((day, index) => {
    return (
      <div key={index} className={styles.weekCalendarDay}>
        <div className={styles.weekCalendarDayName}>{day.substring(0, 3)}</div>
        <Checkbox
          checked={!!weekCalendar[day.toLowerCase() as keyof IWeekCalendar]}
          disabled={isReadOnly}
          style={{ color: '#D70000' }}
          onChange={(e) => {
            setWeekCalendar({
              ...weekCalendar,
              [day.toLowerCase()]: e.target.checked,
            })
          }}
        />
      </div>
    )
  })

  return (
    <div className={styles.weekCalendarMain}>
      <div className={`${styles.weekCalendarTitle} font--bold`}>
        Working Days:
      </div>
      <div className={styles.weekCalendarContainer}>{weekDayCheckboxes}</div>
    </div>
  )
}
