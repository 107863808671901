const ROLES = {
  STORE_REPRESENTATIVE: 'Store Representative',
  STORE_MANAGER: 'Store Manager',
  REGIONAL_MANAGER: 'Regional Manager',
  ADMIN: 'Admin',
  SUPER_ADMIN: 'Super Admin',
  CALL_CENTER_REPRESENTATIVE: 'Call Center Representative',
}

export { ROLES }
