import { Auth } from 'aws-amplify'

export const refreshToken = async () => {
  try {
    const currentSession = await Auth.currentSession()
    const idToken = currentSession.getIdToken().getJwtToken()
    return idToken
  } catch (err) {
    console.error(`Error refreshing token:`, err)
    return ''
  }
}
