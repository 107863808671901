import { useContext, useEffect, useState } from 'react'
import styles from './styles.module.css'
import { TireSizeButton } from './TireSizeButton'
import CustomTireSize from '../custom-size/CustomTireSize'
import CircularProgress from '@mui/material/CircularProgress'
import CustomSizeButton from './CustomSizeButton'
import useFetch from '../../../../../../global/hooks/useFetchHook'
import { getTireSize } from '../api'
import {
  ITiresCombination,
  TiresCombination,
} from '../../../../../../../../app/entities/TiresCombination'
import { Tire } from '../../../../../../../../app/entities/Tire'
import { OrderContext } from '../../../../../../global/context/order-context/OrderContext'

export function SelectTireSize() {
  const { orderData, setTiresCombination } = useContext(OrderContext)
  const { orderVehicles } = orderData
  const { customerVehicle, tiresCombination } = orderVehicles[0]
  const { year, model, variant } = customerVehicle || {}

  const [selectedTireSizeButtonIndex, setSelectedTireSizeButtonIndex] =
    useState<number | undefined>(0)

  function formatTireSize(item: any, index: any) {
    const { frontTire, rearTire } = item
    return {
      inches: `${frontTire.diameter}"`,
      frontSize: `${frontTire.width} : ${frontTire.aspectRatio} R${frontTire.diameter}`,
      rearSize: `${rearTire.width} : ${rearTire.aspectRatio} R${rearTire.diameter}`,
      id: index,
      item: item,
    }
  }

  const tireInfo = JSON.stringify({
    brand: { name: customerVehicle?.brand?.name },
    year,
    model,
    variant,
    version: variant,
  })

  const {
    data: tireSizeOptions,
    isLoading,
    error,
  } = useFetch<ITiresCombination[]>(getTireSize(tireInfo))

  const isTireSizeChosen =
    tiresCombination?.frontTire?.width || tiresCombination?.rearTire?.width

  useEffect(() => {
    // if there is no tire size options, wait for them to load
    if (!tireSizeOptions || tireSizeOptions.length === 0) {
      return
    }
    // set default tire size
    else if (!isTireSizeChosen) {
      const tireSizeOption = tireSizeOptions[0]
      setTiresCombination({
        frontTire: {
          aspectRatio: tireSizeOption.frontTire?.aspectRatio ?? NaN,
          width: tireSizeOption.frontTire?.width ?? '',
          diameter: tireSizeOption.frontTire?.diameter ?? NaN,
        } as Tire,
        rearTire: {
          aspectRatio: tireSizeOption.rearTire?.aspectRatio ?? NaN,
          width: tireSizeOption.rearTire?.width ?? '',
          diameter: tireSizeOption.rearTire?.diameter ?? NaN,
        } as Tire,
      } as TiresCombination)
    } else {
      let finalIndex = undefined
      tireSizeOptions.forEach(
        (tireSizeOption: ITiresCombination, index: number) => {
          const { frontTire: frontTireOption, rearTire: rearTireOption } =
            tireSizeOption
          const { frontTire: chosenFrontTire, rearTire: chosenRearTire } =
            tiresCombination

          const doesTireSizeOptionMatchSelectedTireSize =
            frontTireOption?.width === chosenFrontTire?.width &&
            rearTireOption?.width === chosenRearTire?.width &&
            String(frontTireOption?.aspectRatio) ===
              String(chosenFrontTire?.aspectRatio) &&
            String(rearTireOption?.aspectRatio) ===
              String(chosenRearTire?.aspectRatio) &&
            String(frontTireOption?.diameter) ===
              String(chosenFrontTire?.diameter) &&
            String(rearTireOption?.diameter) ===
              String(chosenRearTire?.diameter)

          if (doesTireSizeOptionMatchSelectedTireSize) {
            finalIndex = index
          }
        },
      )

      setSelectedTireSizeButtonIndex(finalIndex)
    }
  }, [tireSizeOptions])

  return (
    <div className={styles.selectTireSizeContainer}>
      <div className={`${styles.selectTireSizeTitle} font--bold`}>
        Select Tire Size
      </div>
      {isLoading || error ? (
        <CircularProgress />
      ) : (
        <>
          <div className={styles.tireSizeButtonContainer}>
            {tireSizeOptions &&
              tireSizeOptions.map((item: any, index: any) => {
                const formatted = formatTireSize(item, index)
                return (
                  <TireSizeButton
                    {...formatted}
                    item={item}
                    selectedTireSizeButtonIndex={selectedTireSizeButtonIndex}
                    onClick={setSelectedTireSizeButtonIndex}
                    key={index}
                  />
                )
              })}
            <CustomSizeButton
              selectedTireSizeButtonIndex={selectedTireSizeButtonIndex}
              id={undefined}
              onClick={setSelectedTireSizeButtonIndex}
            />
          </div>
          {selectedTireSizeButtonIndex === undefined && <CustomTireSize />}
        </>
      )}
    </div>
  )
}
