import { Dispatch, SetStateAction, useContext, useEffect } from 'react'
import {
  isTenDigitPhoneNumber,
  isValidEmail,
} from '../../../../../global/utils/formValidation'
import ContactForm from './contact/ContactForm'
import InstallationAddressForm from './installation-address/InstallationAddressForm'
import styles from './styles.module.css'
import VehicleInformationForm from './vehicle-information/VehicleInformationForm'
import { OrderContext } from '../../../../../global/context/order-context/OrderContext'

export interface Address {
  countryCode?: string
  state: string
  zipCode: string
  city: string
  streetAddress1: string
}

function checkFormFields(formData: any) {
  let passes = true

  for (const key in formData) {
    const element = formData[key]
    if (
      (key !== 'additionalInfo' &&
        key !== 'aptOrSuite' &&
        key !== 'brandId' &&
        element === '') ||
      (key === 'email' && !isValidEmail(element)) ||
      (key === 'phoneNumber' && !isTenDigitPhoneNumber(element))
    ) {
      passes = false
    }
  }

  return passes
}

interface ICustomerInformationProps {
  chosenAddress?: google.maps.GeocoderResult | null | undefined
  mode: string
  setChosenAddress: Dispatch<SetStateAction<google.maps.GeocoderResult | null>>
  setIsForwardDisabled: Function
}

function CustomerInformation({
  chosenAddress,
  mode,
  setChosenAddress,
  setIsForwardDisabled,
}: ICustomerInformationProps) {
  const { orderData } = useContext(OrderContext)
  const { customer, installationPoint, orderVehicles } = orderData
  const customerVehicle = orderVehicles[0]?.customerVehicle

  useEffect(() => {
    const isFormFilledOut = checkFormFields({
      ...customer,
      ...installationPoint,
      ...customerVehicle,
    })

    setIsForwardDisabled(!isFormFilledOut)
  }, [customer, installationPoint, customerVehicle])

  return (
    <div className={styles.formContainer}>
      <ContactForm />
      <InstallationAddressForm
        chosenAddress={chosenAddress}
        mode={mode}
        setChosenAddress={setChosenAddress}
      />
      <VehicleInformationForm />
    </div>
  )
}

export default CustomerInformation
