import React, { useContext } from 'react'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import HolidayModal from '../../holiday-modal/container/HolidayModal'
import { UserContext } from '../../../../base/components/base-container/BaseContainer'
import { PERMISSIONS } from '../../../../global/constants/permissions'
import userHasPermission from '../../../../global/utils/user/has-permission'

const { CATEGORY, ENTRIES } = PERMISSIONS.HOLIDAYS

export default function NewHoliday(props: any) {
  const { user } = useContext(UserContext)
  const { setRefreshCounter } = props.refresh
  const [open, setOpen] = React.useState(false)

  const canSeeCreateButton = userHasPermission(user).allow(
    CATEGORY,
    ENTRIES.CREATE_HOLIDAY.NAME,
  )

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClosed = () => {
    setOpen(false)
  }

  return canSeeCreateButton ? (
    <div>
      <PrimaryButton buttonName='Create' onClick={handleOpen} />
      {open && (
        <HolidayModal
          isOpen={open}
          handleClosed={handleClosed}
          refresh={setRefreshCounter}
        />
      )}
    </div>
  ) : (
    <></>
  )
}
