import { useAuthenticator } from '@aws-amplify/ui-react'
import { createContext, useEffect, useMemo, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { IUser } from '../../../../../app/entities/User'
import { RequireAuth } from '../../../global/components/auth/RequireAuth'
import { fetchData } from '../../../global/utils/fetch'
import linksData from '../../../global/tsx/links'
import NavLinkPropsWithElement from '../../../global/types/NavLinkPropsWithElement'
import JmulletTools from '../../../jmullet-tools/components/JmulletTools'
import { Login } from '../../../login/Login'
import ModalContent from '../../../work-orders/components/work-order-detail/components/main/ModalContent'
import NavDrawer from '../nav-drawer/NavDrawer'
import { getUserPermissions } from './api'
import styles from './styles.module.css'
import { IRole } from '../../../../../app/entities/Role'
import UserManagementMain from '../../../user-management/main/UserManagementMain'
import hasPermission from '../../../global/utils/user/has-permission'
import { PERMISSIONS } from '../../../global/constants/permissions'
import LogRocket from 'logrocket'
import { OrderContextProvider } from '../../../global/context/order-context/OrderContext'
import {
  resetActiveTab,
  updateTabBasedUponCountOfOrdersForLocation,
} from '../../../global/utils/update-tab'
import { ROLES } from '../../../global/constants/user-roles'
import { Location } from '../../../../../app/entities/Location'
import { VanContextProvider } from '../../../global/context/van-context/VanContext'

interface IUserContext {
  user: IUser | null
  refetchUser: () => void
}

export const UserContext = createContext<IUserContext>({
  user: null,
  refetchUser: () => {},
})

function BaseContainer() {
  const { user } = useAuthenticator((context) => [context.user])
  const [userRole, setUserRole] = useState<IRole>({} as IRole)
  const [userData, setUserData] = useState<IUser | null>(null)

  const links: Array<NavLinkPropsWithElement> = useMemo(
    () =>
      linksData.filter((elem) => {
        const { permissionInfo } = elem
        const { category, name } = permissionInfo || {}

        const allowPermissionFound =
          !category ||
          !name ||
          hasPermission({
            role: { permissions: userRole.permissions || [] },
          } as IUser).allow(category, name)

        return allowPermissionFound
      }),
    [userRole.permissions],
  )

  const { pathname } = useLocation()
  const routesNotDisplayingNavDrawer = [
    /^\/login(\/.*)?$/i,
    /^\/customer-tip-screen\/.+$/,
    /^\/customer-update-cc\/.+$/,
    /^\/schedule-appointment\/.+$/,
  ]
  const shouldDisplayNavDrawer = !routesNotDisplayingNavDrawer.some((route) =>
    route.test(pathname),
  )

  const refetchUser = () => {
    if (
      user.challengeName === 'NEW_PASSWORD_REQUIRED' &&
      !(user as any)?.signInUserSession?.idToken
    ) {
      return
    }
    const email =
      (user as any)?.signInUserSession?.idToken?.payload?.email ?? ''

    fetchData<IUser>(getUserPermissions(email)).then((userInfo) => {
      setUserData(userInfo)
      setUserRole(userInfo.role || ({} as IRole))

      LogRocket.identify(userInfo.objectId, {
        name: userInfo.name,
        email: userInfo.email,

        role: userInfo.role?.name || 'No Role',
      })
    })
  }

  useEffect(() => {
    //fetch user role & save to local storage
    if (user) {
      refetchUser()
    }
  }, [user])

  let intervalId: NodeJS.Timeout | undefined

  //for when the page becomes inactive
  //this code did not successfully end when called from outside
  window.addEventListener('blur', () => {
    const rolesWithNotifications = [
      ROLES.STORE_MANAGER,
      ROLES.STORE_REPRESENTATIVE,
    ]
    const userToGetNotifications = rolesWithNotifications.includes(
      userRole.name,
    )
    const locations = (userData?.locations ?? {}) as Location[]
    let startCount = 0
    if (userToGetNotifications && locations.length > 0) {
      const locationObjectId = locations[0].objectId
      async function getStartingCount() {
        startCount = await fetchData<number>({
          method: 'GET',
          url: `${process.env.REACT_APP_ORIGIN}/orders/getCountOfOrdersByLocation?selectedLocationId=${locationObjectId}`,
        })
      }
      getStartingCount()
      console.log(startCount)
      intervalId = setInterval(() => {
        updateTabBasedUponCountOfOrdersForLocation(locationObjectId, startCount)
      }, 60000) // Check every 1 minute
    }
    return () => clearInterval(intervalId) // Clean up on unmount
  })

  //for when the page becomes active
  window.addEventListener('focus', () => resetActiveTab(intervalId))

  return (
    <OrderContextProvider>
      <VanContextProvider>
        <UserContext.Provider value={{ user: userData, refetchUser }}>
          <div className={styles.baseContainerWrapper}>
            {shouldDisplayNavDrawer && <NavDrawer links={links}></NavDrawer>}
            <Routes>
              <Route
                path={'/jmullet-tools'}
                element={
                  <RequireAuth>
                    <JmulletTools />
                  </RequireAuth>
                }
              />
              {links.map((link, idx) => (
                <Route
                  path={String(link.to)}
                  element={link.element}
                  key={String(link.title) + idx}
                >
                  {link.nestedRoutes &&
                    link.nestedRoutes.map((nestedRoute, idx) => {
                      // this ensures that the nested routes don't complain about missing keys
                      // without the need to explicitly set a key on each nested route
                      return <Route {...nestedRoute.props} key={idx} />
                    })}
                </Route>
              ))}
              {hasPermission({
                role: { permissions: userRole.permissions || [] },
              } as IUser).allow(
                PERMISSIONS.NAV_BAR.CATEGORY,
                PERMISSIONS.NAV_BAR.ENTRIES.VIEW_USER_MANAGEMENT.NAME,
              ) && (
                <Route
                  path='/user-management/roles'
                  element={
                    <RequireAuth>
                      <UserManagementMain tabType={'roles'} />
                    </RequireAuth>
                  }
                >
                  <Route path=':id' element={<ModalContent />} />
                </Route>
              )}
              <Route path={'/login/*'} element={<Login />} />
            </Routes>
          </div>
        </UserContext.Provider>
      </VanContextProvider>
    </OrderContextProvider>
  )
}

export default BaseContainer
