import {
  AvailabilitySlot,
  DayAvailability,
} from '../../../../../../../app/types/available_calendar'
import { Slot } from './Slot'
import styles from './styles.module.css'
import getSelectedTime from './helpers/get-selected-time'
import { useContext } from 'react'
import { OrderContext } from '../../../../../global/context/order-context/OrderContext'

interface IDayProps {
  day: DayAvailability
  isLoading: boolean
  setSelected: Function
}

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export default function Day({ day, isLoading, setSelected }: IDayProps) {
  const { selectedTimes } = useContext(OrderContext)
  const date = new Date(day.date)
  const formattedDate = `${months[date.getUTCMonth()]} ${date.getUTCDate()}`
  const weekday = `${days[date.getUTCDay()]}`

  function onTimeClick(time: number) {
    setSelected([time])
  }

  return (
    <div className={styles.day}>
      <div className={styles.dayHeader}>
        <div className={styles.dayHeaderMonthText}>{formattedDate}</div>
        <div className={styles.dayHeaderDayTextContainer}>
          <div className={styles.dayHeaderDayTextWeekday}>{`${weekday}`}</div>
        </div>
      </div>
      <div className={styles.slotContainer}>
        {day.slots.map((slot: AvailabilitySlot, idx) => (
          <Slot
            currentSelected={selectedTimes}
            isLoading={isLoading}
            onClick={() =>
              onTimeClick(getSelectedTime(slot.timeSlot?.fromTime))
            }
            key={idx}
            {...slot}
          />
        ))}
      </div>
    </div>
  )
}
