import styles from './styles.module.css'
import { ILocation } from '../../../../../../../app/entities/Location'
import {
  isTenDigitPhoneNumber,
  isValidEmail,
} from '../../../../../global/utils/formValidation'
import { Chip } from '@mui/material'
import { useState } from 'react'

interface IAlertsManagerProps {
  location: Partial<ILocation>
  setLocation: Function
  isReadOnly: boolean
}

export interface IAlertsManagerSubsectionProps {
  location: Partial<ILocation>
  setLocation: Function
  isReadOnly: boolean
}

export default function AlertsManager({
  location,
  setLocation,
  isReadOnly,
}: IAlertsManagerProps) {
  return (
    <div className={styles.alertsManagerMain}>
      <div className={styles.alertsManagerHeader}>
        <div className={`${styles.alertsManagerTitle} font--bold`}>Alerts</div>
        <div className={styles.alertsManagerDescription}>
          {/* The addresses and numbers below will recieve an email or text, */}
          {/* respectively, whenever a new order comes in. */}
          The cellphone numbers below will receive a text message whenever a new
          order is scheduled.
        </div>
      </div>
      <div className={styles.alertsManagerSubsectionContainer}>
        <CellPhoneAlertsSubsection
          location={location}
          setLocation={setLocation}
          isReadOnly={isReadOnly}
        />
        {/* <EmailAlertsSubsection
          location={location}
          setLocation={setLocation}
          isReadOnly={isReadOnly}
        /> */}
      </div>
    </div>
  )
}

//due to the lengthiness of incorporating a new domain into twilio, this project was altered to only send text message notifications
function EmailAlertsSubsection({
  location,
  setLocation,
  isReadOnly,
}: IAlertsManagerSubsectionProps) {
  const [inputBackgroundColor, setInputBackgroundColor] = useState('#f5f5f5')
  const [emailInputValue, setEmailInputValue] = useState('')

  const emails = location.newOrderAlertEmails
    ? location.newOrderAlertEmails.split(',')
    : []

  const inputChip = (
    <Chip
      key='input-chip'
      style={{
        border: '1px solid #2a7187',
        cursor: isReadOnly ? undefined : 'text',
        backgroundColor: inputBackgroundColor,
        boxShadow: '0 0 .25rem 0 rgba(0,0,0,.25) inset',
      }}
      label={
        <input
          placeholder='Add Email'
          style={{
            all: 'unset',
            width: '10rem',
            userSelect: 'none',
          }}
          value={emailInputValue}
          disabled={isReadOnly}
          autoComplete='off'
          // allows for pasting multiple zips at once assuming
          // it is a list of zips separated by newlines
          onChange={(e) => {
            setInputBackgroundColor('#f5f5f5')
            setEmailInputValue(e.target.value)
          }}
          onKeyDown={handleKeyDown}
        />
      }
    />
  )

  const emailChips = emails.map((email) => {
    return (
      <Chip
        key={email}
        label={email}
        disabled={isReadOnly}
        style={{
          backgroundColor: '#2a7187',
          color: 'white',
          fontWeight: 'bold',
        }}
        onDelete={() => {
          const remainingEmails = emails.filter((e) => e !== email)
          setLocation({
            ...location,
            newOrderAlertEmails: remainingEmails.join(','),
          })
        }}
      />
    )
  })

  function handleKeyDown(e: any) {
    if (e.key === 'Enter') {
      const newEmail = e.currentTarget.value

      const isValid = isValidEmail(newEmail)
      if (!isValid) {
        setInputBackgroundColor('#fcebeb')
      } else {
        setLocation({
          ...location,
          newOrderAlertEmails: location.newOrderAlertEmails
            ? `${newEmail},${location.newOrderAlertEmails}`
            : newEmail,
        })
        setEmailInputValue('')
      }
    }
  }

  return (
    <>
      <div className={styles.emailAlertsSubsectionContainer}>
        {inputChip}
        {emailChips}
      </div>
    </>
  )
}

function CellPhoneAlertsSubsection({
  location,
  setLocation,
  isReadOnly,
}: IAlertsManagerSubsectionProps) {
  const [inputBackgroundColor, setInputBackgroundColor] = useState('#f5f5f5')
  const [cellphoneInputValue, setCellphoneInputValue] = useState('')

  const cellphones = location.newOrderAlertCellphones
    ? location.newOrderAlertCellphones.split(',')
    : []

  const inputChip = (
    <Chip
      key='input-chip'
      style={{
        border: '1px solid #2a7187',
        cursor: isReadOnly ? undefined : 'text',
        backgroundColor: inputBackgroundColor,
        boxShadow: '0 0 .25rem 0 rgba(0,0,0,.25) inset',
      }}
      label={
        <input
          placeholder='Add Cellphone'
          style={{
            all: 'unset',
            width: '10rem',
            userSelect: 'none',
          }}
          value={cellphoneInputValue}
          disabled={isReadOnly}
          autoComplete='off'
          // allows for pasting multiple zips at once assuming
          // it is a list of zips separated by newlines
          onChange={(e) => {
            setInputBackgroundColor('#f5f5f5')
            setCellphoneInputValue(e.target.value)
          }}
          onKeyDown={handleKeyDown}
        />
      }
    />
  )

  const cellphoneChips = cellphones.map((cellphone) => {
    return (
      <Chip
        key={cellphone}
        label={cellphone}
        disabled={isReadOnly}
        style={{
          backgroundColor: '#2a7187',
          color: 'white',
          fontWeight: 'bold',
        }}
        onDelete={() => {
          const remainingCellphones = cellphones.filter((c) => c !== cellphone)
          setLocation({
            ...location,
            newOrderAlertCellphones: remainingCellphones.join(','),
          })
        }}
      />
    )
  })

  function handleKeyDown(e: any) {
    if (e.key === 'Enter') {
      const newCellphone = e.currentTarget.value

      const isValid = isTenDigitPhoneNumber(newCellphone)
      if (!isValid) {
        setInputBackgroundColor('#fcebeb')
      } else {
        setLocation({
          ...location,
          newOrderAlertCellphones: location.newOrderAlertCellphones
            ? `${newCellphone},${location.newOrderAlertCellphones}`
            : newCellphone,
        })
        setCellphoneInputValue('')
      }
    }
  }

  return (
    <>
      <div className={styles.cellPhoneAlertsSubsectionContainer}>
        {inputChip}
        {cellphoneChips}
      </div>
    </>
  )
}
