import React, { ChangeEventHandler, ReactNode } from 'react'
import styles from '../styles.module.css'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import CircularProgress from '@mui/material/CircularProgress'
import useFetch from '../../../../../../../global/hooks/useFetchHook'
import { getBrandYears } from '../../api'
import { ICustomerVehicle } from '../../../../../../../../../app/entities/CustomerVehicle'

const selectProps = {
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: 500,
        width: 250,
      },
    },
  },
}

export interface IVehicleInformationSelectProps {
  customerVehicle: Partial<ICustomerVehicle>
  onChange: Function
  label: string
}

export default function YearInformationSelect({
  customerVehicle,
  onChange,
  label,
}: IVehicleInformationSelectProps) {
  let menuItems = [] as ReactNode

  let { data, isLoading } = useFetch<string[]>(getBrandYears(customerVehicle.brand?.name || ''))

  if (data) {
    menuItems = data.map((item: string) => {
      return (
        <MenuItem
          key={item}
          value={item}>
          {item}
        </MenuItem>
      )
    })
  }

  if (isLoading) {
    return <CircularProgress size={20} />
  }

  return (
    <TextField
      className={styles.textfieldStyles}
      label={label}
      value={customerVehicle.year}
      select
      SelectProps={selectProps}
      onChange={(event) => onChange(event.target.value)}>
      {menuItems}
    </TextField>
  )
}
