import { IVan } from '../../../../../app/entities/Van'

export const initialVanValues: IVan = {
  created: 0,
  updated: 0,
  dotEndDate: null as unknown as Date,
  inServiceDate: null as unknown as Date,
  lastDvir: null as unknown as Date,
  lastMvir: null as unknown as Date,
  lastWvir: null as unknown as Date,
  licensePlate: '',
  livery: '',
  make: '',
  model: '',
  name: '',
  objectId: '',
  outOfServiceDate: null as unknown as Date,
  operatingStatusStartDate: null as unknown as Date,
  registrationEndDate: null as unknown as Date,
  vin: '',
  workDayFrom: '',
  workDayTo: '',
  weekCalendars: [],
  daySettings: [],
  year: undefined,
  location: undefined,
  order: [],
  images: [],
  vir: [],
}
