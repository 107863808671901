import { useContext, useEffect, useState } from 'react'
import { Column } from 'react-table'
import { IOrderService } from '../../../../../../../../app/entities/OrderService'
import Table from '../../../../../../global/components/table/Table'
import TableTitle from '../../../../../../global/components/table/helpers/table-title/TableTitle'
import TableTopper from '../../../../../../global/components/table/helpers/table-topper/TableTopper'
import { fetchData } from '../../../../../../global/utils/fetch'
import { getOrderServicesById } from '../../../../api'
import { SCHEDULER_STEPS } from '../../../../../../global/constants/scheduler'
import { OrderContext } from '../../../../../../global/context/order-context/OrderContext'

interface ILineItemTableStateProps {
  data: IOrderService[]
  isLoading: boolean
}

interface ILineItemsTableProps {
  isReadOnly: boolean
  openOrderModal: (modalName: string) => void
}

export default function LineItemsTable({
  isReadOnly,
  openOrderModal,
}: ILineItemsTableProps) {
  const { isOrderInEditableStatus, orderData } = useContext(OrderContext)
  const orderObjectId = orderData.objectId
  const isOrderEditable = isOrderInEditableStatus && !isReadOnly

  const [isOpen, setIsOpen] = useState(true)
  const [refreshKey, setRefreshKey] = useState(0)
  const [tableState, setTableState] = useState<ILineItemTableStateProps>({
    data: [],
    isLoading: false,
  })
  // const handleOpenNewLineItem = () => {
  //   setOrderServiceObjectId('')
  //   setModalOpen(true)
  // }
  // const handleClosed = () => {
  //   setOrderServiceObjectId('')
  //   setModalOpen(false)
  // }
  function onRecordClickHandler(row: any) {
    openOrderModal(SCHEDULER_STEPS.TIRE_SIZE_AND_QUANTITY)
  }

  useEffect(() => {
    async function fetchTableData() {
      setTableState((prevState) => ({
        ...prevState,
        isLoading: true,
      }))
      const data = await fetchData<IOrderService[]>(
        getOrderServicesById(orderObjectId),
      )
      setTableState((prevState) => ({
        ...prevState,
        data: data,
        isLoading: false,
      }))
    }
    fetchTableData()
  }, [refreshKey, orderObjectId])

  const columns: Column[] = [
    {
      Header: 'Service',
      accessor: 'service.name',
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
    },
    {
      Header: 'Order Vehicle Id',
      accessor: 'orderVehicle.orderVehicleId',
    },
    {
      Header: 'Make & Model',
      Cell: ({ row: { original } }) => {
        const { orderVehicle } = original as any

        if (orderVehicle) {
          const { customerVehicle } = orderVehicle
          return (
            <div>
              <div>{customerVehicle?.brand?.name}</div>
              <div>{customerVehicle?.model}</div>
              <div>{customerVehicle?.version}</div>
              <div>{customerVehicle?.year}</div>
            </div>
          )
        } else {
          return <div>No customer Vehicle Info Available</div>
        }
      },
    },
  ]
  // const disableButtonLogic = data.orderVehicles[0] ? false : true
  return (
    <div style={{ width: '100%' }}>
      {/* <LineItemsModal
        isOpen={modalOpen}
        handleClosed={handleClosed}
        triggerRefresh={() => setRefreshKey(refreshKey + 1)}
        editLineItemData={orderServiceObjectId}
      /> */}
      <TableTopper isOpen={isOpen} onToggleClick={() => setIsOpen(!isOpen)}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <TableTitle>Line Items</TableTitle>
          {/* <button
            disabled={disableButtonLogic}
            onClick={() => handleOpenNewLineItem()}
            style={{ all: 'unset', cursor: 'pointer', color: disableButtonLogic ? 'grey' : '#d70000' }}>
            Add New Line Item
          </button> */}
        </div>
      </TableTopper>
      <Table
        columns={columns}
        data={tableState.data as any}
        isLoading={tableState.isLoading}
        onRowClick={isOrderEditable ? onRecordClickHandler : undefined}
        isOpen={isOpen}
        isDisplayMode={true}
      />
    </div>
  )
}
