import { useContext } from 'react'
import { ITiresCombination } from '../../../../../../../../app/entities/TiresCombination'
import {
  Select,
  SelectListObject,
} from '../../../../../../global/components/select/Select'
import styles from './styles.module.css'
import { OrderContext } from '../../../../../../global/context/order-context/OrderContext'

const tireCountOptions: Array<SelectListObject> = [
  {
    name: '0',
    objectId: 0,
  },
  {
    name: '1',
    objectId: 1,
  },
  {
    name: '2',
    objectId: 2,
  },
  {
    name: '3',
    objectId: 3,
  },
  {
    name: '4',
    objectId: 4,
  },
]

export default function SelectQuantities() {
  const { orderData, setTiresCombination } = useContext(OrderContext)
  const { orderVehicles } = orderData
  const { tiresCombination } = orderVehicles[0]

  function onCountChange(e: any) {
    setTiresCombination({
      [e.target.name]: e.target.value,
    } as ITiresCombination)
  }

  return (
    <div className={styles.selectQuantityContainer}>
      <div className={`${styles.selectTireQuantityTitle} font--bold`}>
        Select Tire Quantity
      </div>
      <div className={styles.frontRearSelectContainer}>
        <div className={styles.quantSelection}>
          <div className={styles.quantSelectionTitle}>Front</div>
          <Select
            name='frontCount'
            onChange={onCountChange}
            defaultValue={tiresCombination?.frontCount ?? undefined}
            label='Quantity'
            width='156px'
            data={tireCountOptions}
          />
        </div>
        <div className={styles.quantSelection}>
          <div className={styles.quantSelectionTitle}>Rear</div>
          <Select
            name='rearCount'
            onChange={onCountChange}
            defaultValue={tiresCombination?.rearCount ?? undefined}
            label='Quantity'
            width='156px'
            data={tireCountOptions}
          />
        </div>
      </div>
    </div>
  )
}
