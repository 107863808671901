import { useContext } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styles from './styles.module.css'
import ModalContent from './ModalContent'
import { OrderContext } from '../../../../../global/context/order-context/OrderContext'
import CircularProgress from '@mui/material/CircularProgress'

interface IScheduleModalProps {
  isOpen: boolean
  handleClosed: Function
}

export default function WorkOrderDetailModal({
  isOpen,
  handleClosed,
}: IScheduleModalProps) {
  const { isOrderDataLoading: isLoading, orderData: data } =
    useContext(OrderContext)

  return (
    <div>
      <Modal open={isOpen} onClose={() => handleClosed()}>
        <Box className={styles.workOrderDetailModal}>
          {isLoading && (
            <div className={styles.progressContainer}>
              <CircularProgress />
            </div>
          )}
          {!isLoading && data && <ModalContent />}
        </Box>
      </Modal>
    </div>
  )
}
