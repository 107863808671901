import { useEffect } from 'react'

import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'

import style from './Login.module.css'

import { useNavigate, useLocation } from 'react-router'

export function Login() {
  const { route } = useAuthenticator((context) => [context.route])
  const location = useLocation()
  const navigate = useNavigate()
  let from = location.state?.from || '/'
  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true })
    }
  }, [route, navigate, from])
  return (
    <div className='auth-wrapper'>
      <Authenticator
        components={{
          ResetPassword: {
            Header() {
              return (
                <>
                  <div className={style.header}>Reset Password</div>
                  <div className={style.helperContainer}>
                    <div>
                      <b>
                        Your store or manager email address is your username.
                      </b>
                    </div>
                    <div>
                      Examples:
                      <ul className={style.examples}>
                        <li>zzz_99@discounttire.com</li>
                        <li>zzz_99mgr@discounttire.com</li>
                      </ul>
                    </div>
                  </div>
                </>
              )
            },
          },
        }}
        socialProviders={['google']}
      ></Authenticator>
    </div>
  )
}
