import TextField from '@mui/material/TextField'
import { useContext, useState } from 'react'
import { IInstallationPoint } from '../../../../../../../../../app/entities/InstallationPoint'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import { ToastNotificationContext } from '../../../../../../../global/context/toast-context/ToastNotificationContext'
import { fetchData } from '../../../../../../../global/utils/fetch'
import { getErrors } from '../../../../../../../global/utils/formValidation'
import { OrderContext } from '../../../../../../../global/context/order-context/OrderContext'
import styles from '../styles/styles.module.css'
import { changeAddress } from './api'

interface IProps {
  handleClose: Function
}

const formOptions = {
  address: {
    label: 'Address',
    autoComplete: 'address-line1',
    required: true,
    validator: (val: any) => (val ? '' : 'Please include an address'),
  },
  aptOrSuite: {
    label: 'Apt/Suite',
    autoComplete: 'address-line2',
    validator: () => '',
  },
  city: {
    label: 'City',
    autoComplete: 'address-level2',
    required: true,
    validator: (val: any) => (val ? '' : 'Please include a city'),
  },
  state: {
    label: 'State',
    autoComplete: 'address-level1',
    required: true,
    validator: (val: any) => (val ? '' : 'Please include a state'),
  },
  zipCode: {
    label: 'Zip Code',
    autoComplete: 'postal-code',
    required: true,
    validator: (val: any) => (val ? '' : 'Please include a zip code'),
  },
} as { [key: string]: any }

export default function ChangeAddress({ handleClose }: IProps) {
  const {
    queryOrderData,
    orderData: { objectId: orderObjectId },
  } = useContext(OrderContext)
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)

  const [form, setForm] = useState({
    address: '',
    aptOrSuite: '',
    city: '',
    state: '',
    zipCode: '',
  } as { [key: string]: any })

  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  function onChange(e: any) {
    const { name, value } = e.target
    setForm({ ...form, [name]: value })
  }

  async function handleAddressSave() {
    const errors = getErrors(form, formOptions)

    if (errors.length) {
      return alert(errors.join('\n'))
    }

    try {
      setIsButtonDisabled(true)
      await fetchData<IInstallationPoint>(
        changeAddress({ ...form, orderObjectId }),
      )
      await queryOrderData(orderObjectId)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Successfully changed address!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'There was an error saving the address',
        severity: 'error',
      })
    } finally {
      setIsButtonDisabled(false)
    }
  }

  return (
    <div className={styles.inputsContainer}>
      {Object.keys(form).map((field) => (
        <div className={styles.topInputsContainer} key={field}>
          <TextField
            name={field}
            required={formOptions[field].required}
            autoComplete={formOptions[field].autoComplete}
            label={formOptions[field].label}
            onChange={onChange}
          />
        </div>
      ))}
      <div className={styles.buttonContainer}>
        <SecondaryButton buttonName='Cancel' onClick={() => handleClose()} />
        <PrimaryButton
          buttonName='Save'
          onClick={handleAddressSave}
          disabled={isButtonDisabled}
        />
      </div>
    </div>
  )
}
