import React from 'react'
import styles from './styles.module.css'
import ScheduleFilterContainer from './schedule-filter-container/ScheduleFilterContainer'
interface IHeaderContainerProps {
  setView: Function
  scheduleFilter: {
    date: string
    locationId: string
  }
  setScheduleFilter: Function
  onOpenLocationModal: Function
}

export default function HeaderContainer({
  setView,
  scheduleFilter,
  setScheduleFilter,
  onOpenLocationModal,
}: IHeaderContainerProps) {
  return (
    <div className={styles.filterContainer}>
      <ScheduleFilterContainer
        setScheduleFilter={setScheduleFilter}
        scheduleFilter={scheduleFilter}
        setView={setView}
        onOpenLocationModal={onOpenLocationModal}
      />
    </div>
  )
}
