import { InstallationPoint } from '../../../../../../app/entities/InstallationPoint'
import { ILocation } from '../../../../../../app/entities/Location'
import {
  GEOCODE_ZIP_CODE,
  GEOCODE_STATE,
  GEOCODE_CITY,
  GEOCODE_STREET_ADDRESS,
  GEOCODE_STREET_NUMBER,
  GEOCODE_STREET_NAME,
  GEOCODE_NON_STANDARD_ADDRESS,
} from '../../../constants/google-maps-address'
import { fetchData } from '../../fetch'
import { getDirections } from './api'

function findAddressComponentMatchingFirstType(
  addressComponent: google.maps.GeocoderAddressComponent[],
  geocodePlaceTypesArray: string[],
): google.maps.GeocoderAddressComponent | undefined {
  for (const type of geocodePlaceTypesArray) {
    for (const component of addressComponent) {
      if (component.types.includes(type)) {
        return component
      }
    }
  }
}

async function fetchDrivingDetails(
  chosenAddress: google.maps.GeocoderResult | null,
  locationObjectId: string,
): Promise<google.maps.DirectionsLeg | undefined> {
  const response = await fetchData<{
    status: google.maps.GeocoderStatus
    geocoded_waypoints: google.maps.DirectionsGeocodedWaypoint[]
    routes: google.maps.DirectionsRoute[]
  }>(getDirections(locationObjectId, chosenAddress?.place_id || ''))

  if (response.status !== 'OK') {
    return
  }

  const drivingDetails = response.routes[0].legs[0]
  drivingDetails.steps = []

  return drivingDetails
}

async function createInstallationPointFromGeocode(
  currentChosenAddress: google.maps.GeocoderResult,
  locationObjectId: string,
): Promise<Partial<InstallationPoint>> {
  const { address_components: addressComponents } = currentChosenAddress
  const zipCode =
    findAddressComponentMatchingFirstType(addressComponents, GEOCODE_ZIP_CODE)
      ?.long_name ?? ''
  const state =
    findAddressComponentMatchingFirstType(addressComponents, GEOCODE_STATE)
      ?.short_name ?? ''
  const city =
    findAddressComponentMatchingFirstType(addressComponents, GEOCODE_CITY)
      ?.long_name ?? ''
  let streetAddress =
    findAddressComponentMatchingFirstType(
      addressComponents,
      GEOCODE_STREET_ADDRESS,
    )?.long_name ?? ''
  const streetNumber =
    findAddressComponentMatchingFirstType(
      addressComponents,
      GEOCODE_STREET_NUMBER,
    )?.long_name ?? ''
  const streetName =
    findAddressComponentMatchingFirstType(
      addressComponents,
      GEOCODE_STREET_NAME,
    )?.long_name ?? ''
  const nonStandardAddress =
    findAddressComponentMatchingFirstType(
      addressComponents,
      GEOCODE_NON_STANDARD_ADDRESS,
    )?.long_name ?? ''

  if (streetAddress.length === 0) {
    if (streetNumber.length === 0 || streetName.length === 0) {
      //google maps can still find nonStandard addresses, but we would like to not use them if we can help it
      streetAddress = nonStandardAddress
    }
    streetAddress = `${streetNumber} ${streetName}`
  }

  const drivingDetails = await fetchDrivingDetails(
    currentChosenAddress,
    locationObjectId,
  )
  const installationPoint: Partial<InstallationPoint> = {
    zipCode: zipCode,
    state: state,
    city: city,
    address: streetAddress,
    drivingDetails,
  }
  return installationPoint
}

export default createInstallationPointFromGeocode
