import React, { ChangeEvent, useState } from "react"
import styles from "../styles.module.css"
import TextField from "@mui/material/TextField"
import PrimaryCheckbox from "../../../../global/components/checkbox/Checkbox"
import { IHolidayFormDataProps } from "../container/HolidayModal"

interface IHolidayModalFormProps {
	formData: IHolidayFormDataProps
	setFormData: Function
}
export default function HolidayModalForm({
	formData,
	setFormData,
}: IHolidayModalFormProps) {
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.name === "date") {
			setFormData((prevState: IHolidayFormDataProps) => ({
				...prevState,
				[e.target.name]: e.target.value,
				day: Date.parse(e.target.value),
			}))
		}
		setFormData((prevState: IHolidayFormDataProps) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}
	return (
		<div className={styles.holidayFormContainer}>
			<div className={styles.inputsContainer}>
				<TextField
					type="date"
					label="Date"
					name="date"
					value={formData.date}
					onChange={handleChange}
					InputLabelProps={{ shrink: true }}
					fullWidth
				/>
				<TextField
					label="name"
					name="name"
					value={formData.name}
					onChange={handleChange}
					fullWidth
					InputLabelProps={{ shrink: true }}
				/>
				<div className={styles.occursYearlyContainer}>
					<PrimaryCheckbox
						checked={formData.occursYearly}
						onChange={(e) =>
							setFormData((prevState: IHolidayFormDataProps) => ({
								...prevState,
								occursYearly: e.target.checked,
							}))
						}
					/>
					Occurs Yearly
				</div>
				{formData.objectId && (
					<div className={styles.deleteContainer}>
						<PrimaryCheckbox
							checked={formData.deleteIsChecked}
							onChange={(e) =>
								setFormData((prevState: IHolidayFormDataProps) => ({
									...prevState,
									deleteIsChecked: e.target.checked,
								}))
							}
						/>
						Check this box to delete Holiday
					</div>
				)}
			</div>
		</div>
	)
}
