import React, { MouseEventHandler } from 'react'
import styles from './styles.module.css'

interface SecondaryButtonProps {
  buttonName: string
  onClick: MouseEventHandler
  disabled?: boolean
  style?: React.CSSProperties
}

export default function SecondaryButton({
  buttonName,
  onClick,
  disabled,
  style,
}: SecondaryButtonProps) {

  if (disabled) {
    return (
      <button className={styles.secondaryButtonDisabled} style={style || {}}>
        <span className={styles.secondaryButtonDisabledText}>{buttonName}</span>
      </button>
    )
  }

  return (
    <button onClick={onClick} className={styles.secondaryButton} style={style || {}}>
      <span className={styles.secondaryButtonText}>{buttonName}</span>
    </button>
  )
}
