import { useContext, useEffect, useState } from 'react'
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react'
import logo from '../../../assets/images/discount-tire-logo.png'
import styles from './styles.module.css'
import { getWeekNumber } from './api'
import useFetch from '../../../global/hooks/useFetchHook'
import ToastNotification from '../../../global/components/toast-alert/ToastNotification'
import { ToastNotificationContext } from '../../../global/context/toast-context/ToastNotificationContext'
import { UserContext } from '../base-container/BaseContainer'
import CenteredModal from '../../../global/components/centered-modal/CenteredModal'
import PrimaryButton from '../../../global/components/buttons/primary-button/PrimaryButton'

interface LinksProps {
  links: Array<NavLinkProps>
  setOpenFeedbackModal: Function
}

function Links({ links, setOpenFeedbackModal }: LinksProps) {
  const { signOut } = useAuthenticator((context) => [context.signOut])

  const logOut = () => {
    signOut()
  }

  const { pathname } = useLocation()

  const [selected, setSelected] = useState(pathname)

  function openFeedbackModal() {
    setOpenFeedbackModal(true)
  }

  return (
    <div className={styles.linksHolder}>
      {links.map((link: NavLinkProps, idx: number) => (
        <NavLink
          className={`${styles.link} ${
            link.to === selected ? `${styles.chosen}` : ''
          }`}
          onClick={() => setSelected(link.to.toString())}
          key={idx}
          to={link.to}
        >
          {link.title}
        </NavLink>
      ))}
      <div onClick={logOut} className={styles.link}>
        Log Out
      </div>
      <div
        onClick={openFeedbackModal}
        className={`${styles.link} ${styles.feedback}`}
      >
        Feedback
      </div>
    </div>
  )
}

function FeedbackModal({
  open,
  setOpenFeedbackModal,
}: {
  open: boolean
  setOpenFeedbackModal: Function
}) {
  return (
    <CenteredModal
      open={open}
      footer={
        <div className={styles.feedbackButtonsHolder}>
          <PrimaryButton
            buttonName='Close'
            onClick={() => setOpenFeedbackModal(false)}
          />
          <div>
            Having issues loading the form?{' '}
            <a
              className={styles.feedbackButtonsHolderLink}
              href='https://docs.google.com/forms/d/e/1FAIpQLSehrHU77rIiK7UdGU2E9bnPuta6qb19bSZpz378bqT5qH39PQ/viewform?embedded=true'
              target='_blank'
              rel='noopener noreferrer'
            >
              Open Form in new tab
            </a>
          </div>
        </div>
      }
    >
      <iframe
        src='https://docs.google.com/forms/d/e/1FAIpQLSehrHU77rIiK7UdGU2E9bnPuta6qb19bSZpz378bqT5qH39PQ/viewform?embedded=true'
        width='640'
        height='640'
      >
        Loading…
      </iframe>
    </CenteredModal>
  )
}

interface NavDrawerProps {
  links: Array<NavLinkProps>
}

function NavDrawer(props: NavDrawerProps) {
  const { toastStatus, handleClose } = useContext(ToastNotificationContext)

  const [weekNumber, setWeekNumber] = useState(0)
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false)

  const { user } = useContext(UserContext)

  const { links } = props
  const { data } = useFetch<any>(getWeekNumber())

  useEffect(() => {
    if (data) {
      setWeekNumber(data.weekNumber)
    }
  }, [data])

  return (
    <>
      <ToastNotification
        severity={toastStatus.severity}
        open={toastStatus.isOpen}
        handleClose={handleClose}
        message={toastStatus.message}
      />
      <div className='navDrawerMain'>
        <div className={styles.navDrawerContainer}>
          <div className={styles.header}>
            <div className={styles.logoHolder}>
              <img src={logo} className={styles.logo} alt='logo' />
            </div>
            <div className={styles.headerInfo}>
              <div className={styles.csPortal}>CS Portal</div>
              {data && (
                <div className={styles.weekNumber}>Week {weekNumber}</div>
              )}
            </div>
          </div>
          <div className={styles.linksContainer}>
            <Links links={links} setOpenFeedbackModal={setOpenFeedbackModal} />
          </div>
          {user && (
            <div className={styles.userContainer}>
              <div className={styles.userName}>{user?.name || ''}</div>
              <div className={styles.userInfo}>{user?.email || ''}</div>
              <div className={styles.userInfo}>{user?.role?.name || ''}</div>
            </div>
          )}
        </div>
      </div>
      <FeedbackModal
        open={openFeedbackModal}
        setOpenFeedbackModal={setOpenFeedbackModal}
      />
    </>
  )
}

export default NavDrawer
