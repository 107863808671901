import { useContext, useEffect, useState } from 'react'
import { Column, Row } from 'react-table'
import Table from '../../global/components/table/Table'
import BasicFilter from '../../global/components/table/helpers/BasicFilter'
import { handleColumnFilterUpdate } from '../../global/components/table/helpers/multiColumnFilters'
import retrieveData from '../../global/components/table/helpers/retrieveData'
import TableTitle from '../../global/components/table/helpers/table-title/TableTitle'
import TableTopper from '../../global/components/table/helpers/table-topper/TableTopper'
import UserModalMain from '../user-modal/UserModalMain'
import { UserContext } from '../../base/components/base-container/BaseContainer'
import { IUser } from '../../../../app/entities/User'
interface IUsersTableProps {
  setRefetchIndex: Function
  refetchIndex: number
}
function UsersTable(props: IUsersTableProps) {
  const { refetchIndex } = props
  const [userModalOpen, setUserModalOpen] = useState(false)
  const [userEmail, setUserEmail] = useState('')
  const [tableState, setTableState] = useState({
    data: [],
    isLoading: false,
    offSet: 0,
    pageSize: 100,
    count: 0,
  })
  const [columnFilters, setColumnFilters] = useState({})

  const endpoint = `${process.env.REACT_APP_ORIGIN}/users?`
  function onColumnFilterUpdate(filtersUpdate: object) {
    handleColumnFilterUpdate(filtersUpdate, columnFilters, setColumnFilters)
  }
  const retrieveTableData = () => {
    retrieveData(setTableState, {
      endpoint,
      pageSize: tableState.pageSize,
      offSet: tableState.offSet,
      columnFilters,
      getRole: true,
    })
  }
  useEffect(() => {
    retrieveTableData()
  }, [refetchIndex, tableState.pageSize, tableState.offSet])

  const columns: Column[] = [
    {
      Header: 'Name',
      accessor: 'name',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ name: val }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Email',
      accessor: 'email',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ email: val }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Role',
      accessor: 'role.name',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ role: val }),
        onEnter: retrieveTableData,
      }),
    },
    {
      Header: 'Location',
      accessor: 'locations',
      Cell: ({ row: { original: user } }: any) => {
        const locationName =
          user?.locations.length > 0 ? user.locations[0].name : '-'
        return locationName
      },
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ locations: { name: val } }),
        onEnter: retrieveTableData,
      }),
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <UserModalMain
        isOpen={userModalOpen}
        email={userEmail}
        setRefetchIndex={props.setRefetchIndex}
        refetchIndex={props.refetchIndex}
        handleClosed={() => {
          setUserEmail('')
          setUserModalOpen(false)
        }}
      />
      <TableTopper>
        <TableTitle>Users</TableTitle>
      </TableTopper>
      <Table
        columns={columns}
        data={tableState.data ?? []}
        onRowClick={(row: Row) => {
          const { original } = row as any
          setUserEmail(original.email)
          setUserModalOpen(true)
        }}
        isLoading={tableState.isLoading}
        isPaginated
        pagination={{ setTableState, tableState }}
      />
    </div>
  )
}

export default UsersTable
