import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Modal from '@mui/material/Modal'
import { useEffect, useState } from 'react'
import { DeleteResult } from 'typeorm'
import { IHoliday } from '../../../../../../app/entities/Holiday'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../global/components/buttons/secondary-button/SecondaryButton'
import { convertMsToYMD } from '../../../../global/utils/dates'
import { fetchData } from '../../../../global/utils/fetch'
import { createHoliday, deleteHoliday, updateHoliday } from '../../../api'
import HolidayModalForm from '../form/HolidayModalForm'
import styles from '../styles.module.css'
interface IPromoCodeModalProps {
  isOpen: boolean
  handleClosed: Function
  refresh: Function
  holidayRowData?: any
}
export interface IHolidayFormDataProps {
  day: number
  date: string
  name: string
  occursYearly: boolean
  objectId: string
  deleteIsChecked: boolean
}
export default function HolidayModal(props: IPromoCodeModalProps) {
  const { holidayRowData } = props
  const [formStatuses, setFormStatuses] = useState({
    isLoading: false,
    checked: false,
    errorMessage: '',
  })
  const [formData, setFormData] = useState<IHolidayFormDataProps>({
    day: 0,
    date: '',
    name: '',
    occursYearly: false,
    objectId: '',
    deleteIsChecked: false,
  })
  const setLoader = (bool: boolean) => {
    return setFormStatuses((prevState) => ({
      ...prevState,
      isLoading: bool,
    }))
  }

  const handleClose = () => {
    setFormStatuses({
      isLoading: false,
      checked: false,
      errorMessage: '',
    })
    setFormData({
      day: 0,
      date: '',
      name: '',
      occursYearly: false,
      objectId: '',
      deleteIsChecked: false,
    })
    props.handleClosed()
  }
  useEffect(() => {
    if (holidayRowData && Object.keys(holidayRowData).length > 0) {
      setFormData((prevState) => ({
        ...prevState,
        day: holidayRowData.day,
        date: convertMsToYMD(holidayRowData.day),
        name: holidayRowData.name,
        occursYearly: holidayRowData.occursYearly,
        objectId: holidayRowData.objectId,
      }))
    }
  }, [holidayRowData])

  const handleCreateHoliday = async () => {
    const body = {
      day: new Date(formData.day).toISOString(),
      name: formData.name,
      occursYearly: formData.occursYearly,
    }
    try {
      setLoader(true)
      await fetchData<IHoliday>(createHoliday(body))
      setLoader(false)
      props.refresh((prevState: any) => prevState + 1)
      handleClose()
    } catch (error) {
      console.log(error)
      setLoader(false)
      setFormStatuses((prevState) => ({
        ...prevState,
        errorMessage: 'There has been an error creating this Holiday',
      }))
    }
  }
  const handleDeleteHoliday = async () => {
    try {
      setLoader(true)
      await fetchData<DeleteResult>(deleteHoliday(formData.objectId))
      setLoader(false)
      props.refresh((prevState: any) => prevState + 1)
      handleClose()
    } catch (error) {
      console.log(error)
      setLoader(false)
      setFormStatuses((prevState) => ({
        ...prevState,
        errorMessage: 'There has been an error deleting this Holiday',
      }))
    }
  }

  const handleUpdateHoliday = async () => {
    const body = {
      day: formData.day,
      name: formData.name,
      occursYearly: formData.occursYearly,
      objectId: formData.objectId,
    }
    try {
      setLoader(true)
      await fetchData<IHoliday>(updateHoliday(body))
      setLoader(false)
      props.refresh((prevState: any) => prevState + 1)
      handleClose()
    } catch (error) {
      console.log(error)
      setLoader(false)
      setFormStatuses((prevState) => ({
        ...prevState,
        errorMessage: 'There has been an error updating this Holiday',
      }))
    }
  }
  return (
    <div>
      <Modal open={props.isOpen}>
        <Box className={styles.holidayModal}>
          <div className={`${styles.holidayModalPageTitle} font--bold`}>
            {holidayRowData ? 'Edit Holiday' : 'Create New Holiday'}
          </div>
          <HolidayModalForm formData={formData} setFormData={setFormData} />
          {formStatuses.isLoading && (
            <div className={styles.progressContainer}>
              <CircularProgress />
            </div>
          )}
          {formStatuses.errorMessage !== '' && (
            <div className={styles.errorMessage}>
              {formStatuses.errorMessage}
            </div>
          )}
          {holidayRowData ? (
            <div className={styles.buttonContainer}>
              <SecondaryButton buttonName='Cancel' onClick={handleClose} />
              {formData.deleteIsChecked ? (
                <PrimaryButton
                  buttonName='Delete'
                  disabled={formStatuses.isLoading}
                  onClick={handleDeleteHoliday}
                />
              ) : (
                <PrimaryButton
                  buttonName='Save'
                  disabled={formStatuses.isLoading}
                  onClick={handleUpdateHoliday}
                />
              )}
            </div>
          ) : (
            <div className={styles.buttonContainer}>
              <SecondaryButton buttonName='Cancel' onClick={handleClose} />
              <PrimaryButton
                buttonName='Create'
                disabled={formStatuses.isLoading}
                onClick={handleCreateHoliday}
              />
            </div>
          )}
        </Box>
      </Modal>
    </div>
  )
}
