import React, { useContext } from 'react'
import { ToolbarProps } from 'react-big-calendar'
import LocationDropDown from '../../../../global/components/location-drop-down/LocationDropDown'
import styles from './styles.module.css'
import arrowSvg from '../../../../assets/images/arrow.svg'
import PrimaryButton from '../../../../global/components/buttons/primary-button/PrimaryButton'
import { UserContext } from '../../../../base/components/base-container/BaseContainer'
import LocationAddEditButtonsHolder from '../../location-add-edit-buttons-holder/LocationAddEditButtonsHolder'

interface ICustomToolBarProps extends ToolbarProps {
  scheduleFilter: {
    date: string
    locationId: string
  }
  setScheduleFilter: Function
  onOpenLocationModal: Function
}
const CustomToolbar: React.FC<ICustomToolBarProps> = ({
  date,
  onNavigate,
  label,
  scheduleFilter,
  setScheduleFilter,
  onOpenLocationModal,
}) => {

  const handleLocationChange = (str: string) => {
    localStorage.setItem('selectedLocationObjectId', str)
    setScheduleFilter({ ...scheduleFilter, locationId: str })
  }

  return (
    <div
      className={styles.toolbarContainer}
      style={{ justifyContent: scheduleFilter.locationId ? 'space-between' : 'left' }}
    >
      <div style={{display: 'flex', gap: '1rem'}}>
        <LocationDropDown
          onLocationChange={(val: string) => {
            handleLocationChange(val)
          }}
          defaultValue={scheduleFilter.locationId}
        />
        <LocationAddEditButtonsHolder
          onOpenLocationModal={onOpenLocationModal}
          selectedLocationObjectId={scheduleFilter.locationId}
        />
      </div>
      {scheduleFilter.locationId ?
        (
          <>
            <div className={styles.monthAndYear}>{label}</div>
            <div className={styles.toolBarButtonGroup}>
              <button
                className={`${styles.calendarNavButton} ${styles.previous}`}
                onClick={() => onNavigate('PREV')}>
                <img
                  src={arrowSvg}
                  height={20}
                  width={20}
                />
              </button>
              <PrimaryButton
                buttonName='Current Month'
                onClick={() => onNavigate('TODAY')}
              />
              {/* <span className={styles.current}>CURRENT</span> */}
              <button
                className={`${styles.calendarNavButton} ${styles.next}`}
                onClick={() => onNavigate('NEXT')}>
                <img
                  src={arrowSvg}
                  height={20}
                  width={20}
                />
              </button>
            </div>
          </>
        ) : (
          <div className={styles.chooseLocationContainer}>
            <div className={styles.chooseLocationText}>Welcome! To get started, choose a location to view schedule.</div>
          </div>
        )
      }
    </div>
  )
}

export default CustomToolbar
