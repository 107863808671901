import Checkbox from '@mui/material/Checkbox'
import { useContext, useState } from 'react'
import { IOrder } from '../../../../../../../../../app/entities/Order'
import PrimaryButton from '../../../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../../../global/components/buttons/secondary-button/SecondaryButton'
import { ToastNotificationContext } from '../../../../../../../global/context/toast-context/ToastNotificationContext'
import { fetchData } from '../../../../../../../global/utils/fetch'
import { OrderContext } from '../../../../../../../global/context/order-context/OrderContext'
import styles from '../styles/styles.module.css'
import { completeWorkOrder } from './api'
interface ICompleteWorkOrderModal {
  handleClose: Function
}
export default function CompleteWorkOrder(props: ICompleteWorkOrderModal) {
  const { orderData, queryOrderData } = useContext(OrderContext)

  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const [isChecked, setIsChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleCompleteOrder = async () => {
    const orderId = orderData.objectId
    try {
      setIsLoading(true)
      await fetchData<IOrder>(completeWorkOrder(orderId))
      await queryOrderData(orderId)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Order completed!',
        severity: 'success',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Error trying to complete order!',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <div className={styles.inputsContainer}>
      <div className={styles.checkBoxContainer}>
        <Checkbox onChange={() => setIsChecked(!isChecked)} />
        <div>Yes I want to complete this work order</div>
      </div>

      <div className={styles.buttonContainer}>
        <SecondaryButton
          buttonName='Cancel'
          onClick={() => props.handleClose()}
        />
        <PrimaryButton
          buttonName='Complete'
          disabled={!isChecked || isLoading}
          onClick={handleCompleteOrder}
        />
      </div>
    </div>
  )
}
