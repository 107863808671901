import { useContext } from 'react'
import styles from './styles.module.css'
import { Tire } from '../../../../../../../../app/entities/Tire'
import { TiresCombination } from '../../../../../../../../app/entities/TiresCombination'
import { OrderContext } from '../../../../../../global/context/order-context/OrderContext'

export interface TireSizeButtonProps {
  inches: string
  frontSize: string
  rearSize: string
  id: number
  onClick: Function
  selectedTireSizeButtonIndex: number | undefined
  item: any
}

export function TireSizeButton({
  inches,
  frontSize,
  rearSize,
  id,
  onClick,
  selectedTireSizeButtonIndex,
  item,
}: TireSizeButtonProps) {
  const { setTiresCombination } = useContext(OrderContext)

  let borderStyles = {}

  if (id === selectedTireSizeButtonIndex) {
    borderStyles = styles.tireSizeButtonRadioBorder1
  } else {
    borderStyles = styles.tireSizeButtonRadioBorder2
  }

  function click() {
    onClick(id)

    setTiresCombination({
      frontTire: {
        aspectRatio: item.frontTire?.aspectRatio ?? NaN,
        width: item.frontTire?.width ?? '',
        diameter: item.frontTire?.diameter ?? NaN,
      } as Tire,
      rearTire: {
        aspectRatio: item.rearTire?.aspectRatio ?? NaN,
        width: item.rearTire?.width ?? '',
        diameter: item.rearTire?.diameter ?? NaN,
      } as Tire,
    } as TiresCombination)
  }

  return (
    <div onClick={click} className={styles.tireSizeButton}>
      <div className={`${styles.tireSizeButtonRadio} ${borderStyles}`}></div>
      <div className={styles.tireSizeButtonInches}>{inches}</div>
      <div className={styles.tireSizeButtonTextContainer}>
        <div className={styles.tireSizeButtonTextInnerContainer}>
          <div className={styles.tireSizeButtonTextType}>Front:</div>
          <div className={styles.tireSizeButtonTextValue}>{frontSize}</div>
        </div>
        <div className={styles.tireSizeButtonTextInnerContainer}>
          <div className={styles.tireSizeButtonTextType}>Rear:</div>
          <div className={styles.tireSizeButtonTextValue}>{rearSize}</div>
        </div>
      </div>
    </div>
  )
}
