import { useAuthenticator } from '@aws-amplify/ui-react'
import { useContext, useEffect, useState } from 'react'
import { Column, Row } from 'react-table'
import { IVanPartial } from '../../../../app/entities/Van'
import Table from '../../global/components/table/Table'
import BasicFilter from '../../global/components/table/helpers/BasicFilter'
import NoFilter from '../../global/components/table/helpers/NoFilter'
import { handleColumnFilterUpdate } from '../../global/components/table/helpers/multiColumnFilters'
import retrieveData from '../../global/components/table/helpers/retrieveData'
import { ILocationFilters } from '../../work-orders/components/main/WorkOrdersMain'
import { IRefreshCounterProps } from '../main/VansMain'
import EditVanModal from '../modal/edit-van-modal/main/EditVanModal'
import hasPermission from '../../global/utils/user/has-permission'
import { PERMISSIONS } from '../../global/constants/permissions'
import { UserContext } from '../../base/components/base-container/BaseContainer'
import { VanContext } from '../../global/context/van-context/VanContext'
import { convertIsoToYMD } from '../../global/utils/dates'

interface IVansTableProps {
  locationFilters: ILocationFilters
  refresh: IRefreshCounterProps
}

function VansTable(props: IVansTableProps) {
  const { user } = useContext(UserContext)
  const { clearVanData, queryVanData } = useContext(VanContext)

  const {
    locationFilters: { locationFilter },
    refresh: { refreshCounter, setRefreshCounter },
  } = props
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [tableState, setTableState] = useState({
    data: [] as IVanPartial[],
    isLoading: false,
    pageSize: 100,
    offSet: 0,
    count: 0,
  })
  const [columnFilters, setColumnFilters] = useState({})

  const isVansEditDisabled = !hasPermission(user).allow(
    PERMISSIONS.VANS.CATEGORY,
    PERMISSIONS.VANS.ENTRIES.EDIT_VAN_DETAILS.NAME,
  )

  async function handleOpen(id: string) {
    setIsModalOpen(true)
    queryVanData(id)
  }

  function handleClosed() {
    setIsModalOpen(false)
    clearVanData()
  }

  const getAllVansEndpoint = `${process.env.REACT_APP_ORIGIN}/vans?`
  useEffect(() => {
    retrieveData(setTableState, {
      endpoint: getAllVansEndpoint,
      pageSize: tableState.pageSize,
      offSet: tableState.offSet,
      locationId: locationFilter,
      columnFilters,
    })
  }, [
    locationFilter,
    columnFilters,
    tableState.pageSize,
    tableState.offSet,
    refreshCounter,
  ])

  function onColumnFilterUpdate(filtersUpdate: object) {
    handleColumnFilterUpdate(filtersUpdate, columnFilters, setColumnFilters)
  }

  const columns: Column<any>[] = [
    {
      Header: 'Van Name',
      accessor: 'name',
      Filter: BasicFilter({
        onEnter: (val: string) => onColumnFilterUpdate({ name: val }),
      }),
    },
    {
      Header: 'Location',
      accessor: (data: IVanPartial) => {
        return data?.location?.name
      },
      Filter: BasicFilter({
        onEnter: (val: string) =>
          onColumnFilterUpdate({ location: { name: val } }),
      }),
    },
    {
      Header: 'Inventory Status',
      accessor: (data: IVanPartial) => data.inventoryStatus,
      Filter: NoFilter,
    },
    {
      Header: 'Operating Status',
      accessor: (data: IVanPartial) => data.operatingStatus,
      Filter: NoFilter,
    },
    {
      Header: 'Operating Status Start Date',
      accessor: (data: IVanPartial) => {
        const date = data.operatingStatusStartDate
        const cleanedTime = date ? convertIsoToYMD(date) : ''
        return date && cleanedTime
      },
      Filter: NoFilter,
    },
    {
      Header: 'Defects',
      accessor: (data: IVanPartial) => {
        return (data?.vir ?? [])[0]?.defects ? 'Yes' : 'No'
      },
      Filter: NoFilter,
    },
    {
      Header: 'Last Daily',
      accessor: (data: IVanPartial) => {
        const date = data.lastDvir
        const cleanedTime = date ? convertIsoToYMD(date) : ''
        return date && cleanedTime
      },
      Filter: NoFilter,
    },
    {
      Header: 'Last Weekly',
      accessor: (data: IVanPartial) => {
        const date = data.lastWvir
        const cleanedTime = date ? convertIsoToYMD(date) : ''
        return date && cleanedTime
      },
      Filter: NoFilter,
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <EditVanModal
        isOpen={isModalOpen}
        handleClosed={handleClosed}
        refresh={{ refreshCounter, setRefreshCounter }}
      />
      <Table
        columns={columns}
        data={tableState.data ?? []}
        isLoading={tableState.isLoading}
        onRowClick={
          isVansEditDisabled
            ? () => {}
            : (row: Row) => {
                const original: any = row.original
                const selectedVan = tableState.data.find(
                  (data: IVanPartial) => data.objectId === original.objectId,
                )
                if (selectedVan) {
                  handleOpen(original.objectId)
                }
              }
        }
        isPaginated
        pagination={{ setTableState, tableState }}
        isTableTopperPresent={false}
      />
    </div>
  )
}

export default VansTable
