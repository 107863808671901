import MenuItem from '@mui/material/MenuItem/MenuItem'
import TextField from '@mui/material/TextField/TextField'
import React from 'react'

interface IWeeksDropDownComponentProps {
  setWeeks: Function
  weeks: number
}
const WeeksDropdown = (props: IWeeksDropDownComponentProps) => {
  const { setWeeks, weeks } = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWeeks(event.target.value)
  }

  return (
    <TextField
      select
      label='Weeks to Display'
      value={weeks}
      onChange={handleChange}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: '500px',
            },
          },
        },
      }}
      style={{ width: '125px' }}>
      {Array.from({ length: 52 }, (_, i) => i + 1).map((number) => (
        <MenuItem
          key={number}
          value={number}>
          {number}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default WeeksDropdown
