import { Box, Modal } from '@mui/material'
import styles from './CenteredModal.module.css'

export default function CenteredModal({
  open,
  children,
  footer,
}: {
  open: boolean
  children: React.ReactNode
  footer?: React.ReactNode
}) {
  return (
    <Modal open={open}>
      <Box className={styles.box}>
        <div>{children}</div>
        {footer && <div className={styles.footer}>{footer}</div>}
      </Box>
    </Modal>
  )
}
