import { useState, useEffect } from 'react'
import { refreshToken } from '../utils/refreshToken'

interface IApiProps {
  method: string
  url: string
  body?: any
}

interface IApiState<R> {
  data: R | null
  isLoading: boolean
  error: any | null
}

export default function useFetch<R>({ method, url, body }: IApiProps) {
  const [response, setResponse] = useState<IApiState<R>>({
    data: null,
    isLoading: true,
    error: null,
  })
  const [refetchIndex, setRefetchIndex] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      const idToken = await refreshToken()
      setResponse({ ...response, isLoading: true })
      try {
        const resp = await fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            Authorization: idToken ? `Bearer ${idToken}` : '',
          },
          body: JSON.stringify(body),
        })
        if (resp.status === 204) {
          setResponse((prevState) => ({
            ...prevState,
            data: [] as unknown as R,
            isLoading: false,
          }))
          return
        }
        const data = (await resp.json()) as R
        if (!resp.ok) {
          throw new Error(`Request failed with status ${resp.status}`)
        }
        setResponse({ ...response, data, isLoading: false })
      } catch (error: any) {
        setResponse({ ...response, error, isLoading: false })
      }
    }
    fetchData()
  }, [url, method, body, refetchIndex])

  const refetch = () => {
    setRefetchIndex(refetchIndex + 1)
  }

  return { ...response, refetch }
}
