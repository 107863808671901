const TaxRuleCodes = {
  INSTALL      : 4,
  REPAIR       : 3,
  EXEMPT       : 2,
  TIRE_DISPOSAL: 1,
  OMIT         : 0,
}

const ProductTaxCodes = {
  REPAIR : '19007',
  INSTALL: '10040',
  EXEMPT : '99999'
}

export {
  ProductTaxCodes,
  TaxRuleCodes
}