import { IRolePartial } from '../../../app/entities/Role'
import { IUserPartial } from '../../../app/entities/User'

export function getSingleUserData(email: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/users/byEmail/${email}`,
  }
}
export function createNewUser(user: IUserPartial) {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/users`,
    body: user,
  }
}
export function updateUser(user: IUserPartial) {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/users`,
    body: user,
  }
}
export function deleteUser(userId: string) {
  return {
    method: 'DELETE',
    url: `${process.env.REACT_APP_ORIGIN}/users/${userId}`,
  }
}

export function getRoleByName(roleName: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/roles/by-name/${roleName}`,
  }
}
export function createRole(role: IRolePartial) {
  return {
    method: 'POST',
    url: `${process.env.REACT_APP_ORIGIN}/roles`,
    body: role,
  }
}
export function updateRole(role: IRolePartial) {
  return {
    method: 'PUT',
    url: `${process.env.REACT_APP_ORIGIN}/roles`,
    body: role,
  }
}
export function deleteRole(roleObjectId: string) {
  return {
    method: 'DELETE',
    url: `${process.env.REACT_APP_ORIGIN}/roles/${roleObjectId}`,
  }
}
export function checkIfRoleRequiresLocation(roleObjectId: string) {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/roles/${roleObjectId}/requires-location`,
  }
}
export function listPermissions() {
  return {
    method: 'GET',
    url: `${process.env.REACT_APP_ORIGIN}/permissions`,
  }
}
